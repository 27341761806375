// import React, { Fragment, useEffect, useContext, useState } from "react";
// import OrderSuccessMessage from "./OrderSuccessMessage";
// import { HomeContext } from "./";
// import { sliderImages } from "../../admin/dashboardAdmin/Action";
// import { nextSlide } from "./Mixins";

// const apiURL = process.env.REACT_APP_API_URL;

// const Slider = (props) => {
//   const { data, dispatch } = useContext(HomeContext);
//   const [slide, setSlide] = useState(0);

//   useEffect(() => {
//     sliderImages(dispatch);
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, []);

//   // Auto slide image after 4 seconds
//   useEffect(() => {
//     const interval = setInterval(() => {
//       nextSlide(data.sliderImages.length, slide, setSlide);
//     }, 4000);
//     return () => clearInterval(interval);
//   }, [slide, data.sliderImages.length]);

//   return (
//     <Fragment>
//       {/* Give main div 40% when on mobile screen */}

//       <div className="relative bg-gray-100">
//         {data.sliderImages.length > 0 ? (
//           <img
//             className="w-full"
//             src={`${apiURL}/uploads/customize/${data.sliderImages[slide].slideImage}`}
//             alt="sliderImage"
//           />
//         ) : (
//           ""
//         )}

//         {data?.sliderImages?.length > 0 ? (
//           <>
//             {/* <svg
//               onClick={(e) =>
//                 prevSlide(data.sliderImages.length, slide, setSlide)
//               }
//               className={`z-10 absolute top-0 left-0 mt-64 flex justify-end items-center box-border flex justify-center w-12 h-12 text-gray-700  cursor-pointer hover:text-yellow-700`}
//               fill="none"
//               stroke="currentColor"
//               viewBox="0 0 24 24"
//               xmlns="http://www.w3.org/2000/svg"
//             >
//               <path
//                 strokeLinecap="round"
//                 strokeLinejoin="round"
//                 strokeWidth={2}
//                 d="M15 19l-7-7 7-7"
//               />
//             </svg>
//             <svg
//               onClick={(e) =>
//                 nextSlide(data.sliderImages.length, slide, setSlide)
//               }
//               className={`z-10 absolute top-0 right-0 mt-64 flex justify-start items-center box-border flex justify-center w-12 h-12 text-gray-700 cursor-pointer hover:text-yellow-700`}
//               fill="none"
//               stroke="currentColor"
//               viewBox="0 0 24 24"
//               xmlns="http://www.w3.org/2000/svg"
//             >
//               <path
//                 strokeLinecap="round"
//                 strokeLinejoin="round"
//                 strokeWidth={2}
//                 d="M9 5l7 7-7 7"
//               />
//             </svg> */}
//             {/* <div className="absolute inset-0 flex items-center justify-center">
//               <a
//                 href="#shop"
//                 style={{ background: "#303031" }}
//                 className="cursor-pointer box-border text-2xl text-white px-4 py-2 rounded"
//               >
//                 Shop Now
//               </a>
//             </div> */}
//           </>
//         ) : null}
//       </div>
//       <OrderSuccessMessage />
//     </Fragment>
//   );
// };

// export default Slider;






// import React, { Fragment, useEffect, useContext, useState } from "react";
// import OrderSuccessMessage from "./OrderSuccessMessage";
// import { HomeContext } from "./";
// import { sliderImages } from "../../admin/dashboardAdmin/Action";
// import { nextSlide } from "./Mixins";

// const apiURL = process.env.REACT_APP_API_URL;

// const Slider = (props) => {
//   const { data, dispatch } = useContext(HomeContext);
//   const [slide, setSlide] = useState(0);
//   const [fadeClass, setFadeClass] = useState("fade-in");

//   useEffect(() => {
//     sliderImages(dispatch);
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, []);

//   // Auto slide image after 4 seconds
//   useEffect(() => {
//     const interval = setInterval(() => {
//       setFadeClass("fade-out"); // Start fade out
//       setTimeout(() => {
//         nextSlide(data.sliderImages.length, slide, setSlide);
//         setFadeClass("fade-in"); // Fade in the new slide
//       }, 500); // Match the fade-out duration (0.5s)
//     }, 4000);
//     return () => clearInterval(interval);
//   }, [slide, data.sliderImages.length]);

//   return (
//     <Fragment>
//       {/* Give main div 40% when on mobile screen */}

//       <div className="relative bg-gray-100">
//         {data.sliderImages.length > 0 ? (
//           <img
//             className={`w-full ${fadeClass}`}
//             src={`${apiURL}/uploads/customize/${data.sliderImages[slide].slideImage}`}
//             alt="sliderImage"
//           />
//         ) : (
//           ""
//         )}

//         {data?.sliderImages?.length > 0 ? (
//           <>
//           </>
//         ) : null}
//       </div>
//       <OrderSuccessMessage />
//     </Fragment>
//   );
// };

// export default Slider;




import React, { Fragment, useEffect, useContext, useState } from "react";
import OrderSuccessMessage from "./OrderSuccessMessage";
import { HomeContext } from ".";
import { sliderImages } from "../../admin/dashboardAdmin/Action";
import { nextSlide } from "./Mixins";

const apiURL = process.env.REACT_APP_API_URL;

const Slider = (props) => {
  const { data, dispatch } = useContext(HomeContext);
  const [slide, setSlide] = useState(0);
  const [fadeClass, setFadeClass] = useState("fade-in");

  useEffect(() => {
    sliderImages(dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let isMounted = true; // To check if the component is mounted

    const interval = setInterval(() => {
      if (isMounted) { // Only update if component is still mounted
        setFadeClass("fade-out"); // Start fade out
        setTimeout(() => {
          if (isMounted) { // Only update if component is still mounted
            nextSlide(data.sliderImages.length, slide, setSlide);
            setFadeClass("fade-in"); // Fade in the new slide
          }
        }, 500); // Match the fade-out duration (0.5s)
      }
    }, 4000);

    return () => {
      isMounted = false; // Cleanup: set isMounted to false when the component unmounts
      clearInterval(interval); // Clear the interval when the component unmounts
    };
  }, [slide, data.sliderImages.length]);

  return (
    <Fragment>
      <div className="relative bg-gray-100">
        {data.sliderImages.length > 0 ? (
          <img
            className={`w-full ${fadeClass}`}
            src={`${apiURL}/uploads/customize/${data.sliderImages[slide].slideImage}`}
            alt="sliderImage"
          />
        ) : (
          ""
        )}

        {data?.sliderImages?.length > 0 ? <></> : null}
      </div>
      <OrderSuccessMessage />
    </Fragment>
  );
};

export default Slider;