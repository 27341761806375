// import React, { Fragment, useCallback, useContext, useEffect } from "react";
// import { getAllSizes, deleteSize } from "./FetchApi";
// import { SizeContext } from "./index";
// import moment from "moment";

// const AllSizes = () => {
//     const { data, dispatch } = useContext(SizeContext);
//     const { sizes, loading } = data;
//     const fetchData = useCallback(async () => {
//         dispatch({ type: "loading", payload: true });
//         try {
//             let responseData = await getAllSizes();
//             if (responseData && responseData.sizes) {
//                 dispatch({
//                     type: "fetchSizeAndChangeState",
//                     payload: responseData.sizes,
//                 });
//             }
//         } catch (error) {
//             console.error("Error fetching sizes:", error);
//         } finally {
//             dispatch({ type: "loading", payload: false });
//         }
//     }, [dispatch]);

//     useEffect(() => {
//         fetchData();
//     }, [fetchData]);

//     const deleteSizeReq = async (sId) => {
//         let deleteS = await deleteSize(sId);
//         if (deleteS.error) {
//             console.log(deleteS.error);
//         } else if (deleteS.success) {
//             fetchData();
//         }
//     };

//     const editSize = (sId, name, status) => {
//         dispatch({
//             type: "editSizeModalOpen",
//             sId: sId,
//             name: name,
//             status: status,
//         });
//     };

//     if (loading) {
//         return (
//             <div className="flex items-center justify-center p-8">
//                 <svg className="w-12 h-12 animate-spin text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
//                     <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
//                 </svg>
//             </div>
//         );
//     }

//     return (
//         <Fragment>
//             <div className="col-span-1 overflow-auto bg-white shadow-lg p-4">
//                 <table className="table-auto border w-full my-2">
//                     <thead>
//                         <tr>
//                             <th className="px-4 py-2 border">Size</th>
//                             <th className="px-4 py-2 border">Status</th>
//                             <th className="px-4 py-2 border">Created at</th>
//                             <th className="px-4 py-2 border">Updated at</th>
//                             <th className="px-4 py-2 border">Actions</th>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         {sizes && sizes.length > 0 ? (
//                             sizes.map((item, key) => (
//                                 <SizeTable
//                                     size={item}
//                                     editSize={(sId, name, status) => editSize(sId, name, status)}
//                                     deleteSize={(sId) => deleteSizeReq(sId)}
//                                     key={key}
//                                 />
//                             ))
//                         ) : (
//                             <tr>
//                                 <td colSpan="5" className="text-xl text-center font-semibold py-8">No size found</td>
//                             </tr>
//                         )}
//                     </tbody>
//                 </table>
//                 <div className="text-sm text-gray-600 mt-2">Total {sizes && sizes.length} sizes found</div>
//             </div>
//         </Fragment>
//     );
// };

// const SizeTable = ({ size, deleteSize, editSize }) => {
//     return (
//         <Fragment>
//             <tr>
//                 <td className="p-2 text-left">{size.sName}</td>
//                 <td className="p-2 text-center">
//                     <span className={size.sStatus === "Active" ? "bg-green-200 rounded-full text-center text-xs px-2 font-semibold" : "bg-red-200 rounded-full text-center text-xs px-2 font-semibold"}>
//                         {size.sStatus}
//                     </span>
//                 </td>
//                 <td className="p-2 text-center">{moment(size.createdAt).format("lll")}</td>
//                 <td className="p-2 text-center">{moment(size.updatedAt).format("lll")}</td>
//                 <td className="p-2 flex items-center justify-center">
//                     <span onClick={() => editSize(size._id, size.sName, size.sStatus)} className="cursor-pointer hover:bg-gray-200 rounded-lg p-2 mx-1">
//                         <svg className="w-6 h-6 fill-current text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
//                             <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
//                             <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
//                         </svg>
//                     </span>
//                     <span onClick={() => deleteSize(size._id)} className="cursor-pointer hover:bg-gray-200 rounded-lg p-2 mx-1">
//                         <svg className="w-6 h-6 fill-current text-red-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
//                             <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
//                         </svg>
//                     </span>
//                 </td>
//             </tr>
//         </Fragment>
//     );
// };

// export default AllSizes;


import React, { Fragment, useCallback, useContext, useEffect } from "react";
import { getAllSizes, deleteSize } from "./FetchApi";
import { SizeContext } from "./index";
import moment from "moment";

const AllSizes = () => {
    const { data, dispatch } = useContext(SizeContext);
    const { sizes, loading } = data;

    const fetchData = useCallback(async () => {
        dispatch({ type: "loading", payload: true });
        try {
            let responseData = await getAllSizes();
            if (responseData && responseData.sizes) {
                dispatch({
                    type: "fetchSizeAndChangeState",
                    payload: responseData.sizes,
                });
            }
        } catch (error) {
            console.error("Error fetching sizes:", error);
        } finally {
            dispatch({ type: "loading", payload: false });
        }
    }, [dispatch]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const deleteSizeReq = async (sId) => {
        let deleteS = await deleteSize(sId);
        if (deleteS.error) {
            console.log(deleteS.error);
        } else if (deleteS.success) {
            fetchData();
        }
    };

    const editSize = (sId, name, status) => {
        dispatch({
            type: "editSizeModalOpen",
            sId: sId,
            name: name,
            status: status,
        });
    };

    if (loading) {
        return (
            <div className="flex items-center justify-center p-8">
                <svg className="w-12 h-12 animate-spin text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                </svg>
            </div>
        );
    }

    return (
        <Fragment>
            <div className="col-span-1 overflow-auto bg-white shadow-lg p-4">
                <table className="table-auto border w-full my-2">
                    <thead>
                        <tr>
                            <th className="px-4 py-2 border">Size</th>
                            <th className="px-4 py-2 border">Status</th>
                            <th className="px-4 py-2 border">Created at</th>
                            <th className="px-4 py-2 border">Updated at</th>
                            <th className="px-4 py-2 border">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sizes && sizes.length > 0 ? (
                            sizes.map((item, key) => (
                                <SizeTable
                                    size={item}
                                    editSize={(sId, name, status) => editSize(sId, name, status)}
                                    deleteSize={(sId) => deleteSizeReq(sId)}
                                    key={key}
                                />
                            ))
                        ) : (
                            <tr>
                                <td colSpan="5" className="text-xl text-center font-semibold py-8">No size found</td>
                            </tr>
                        )}
                    </tbody>
                </table>
                <div className="text-sm text-gray-600 mt-2">Total {sizes && sizes.length} sizes found</div>
            </div>
        </Fragment>
    );
};

const SizeTable = ({ size, deleteSize, editSize }) => {
    // Determine the background color based on the status
    const statusColor = size.sStatus === "Active" ? "bg-green-900" : "bg-red-900";

    return (
        <Fragment>
            <tr>
                <td className="p-2 text-left">{size.sName}</td>
                <td className="p-2 text-center">
                    <span className={`${statusColor} rounded-full text-center text-xs px-2 font-semibold`}>
                        {size.sStatus}
                    </span>
                </td>
                <td className="p-2 text-center">{moment(size.createdAt).format("lll")}</td>
                <td className="p-2 text-center">{moment(size.updatedAt).format("lll")}</td>
                <td className="p-2 flex items-center justify-center">
                    <span onClick={() => editSize(size._id, size.sName, size.sStatus)} className="cursor-pointer hover:bg-gray-200 rounded-lg p-2 mx-1">
                        <svg className="w-6 h-6 fill-current text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                            <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                        </svg>
                    </span>
                    <span onClick={() => deleteSize(size._id)} className="cursor-pointer hover:bg-gray-200 rounded-lg p-2 mx-1">
                        <svg className="w-6 h-6 fill-current text-red-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
                        </svg>
                    </span>
                </td>
            </tr>
        </Fragment>
    );
};

export default AllSizes;