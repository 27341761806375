// import React, { useState } from "react";
// import axios from "axios";

// const Reset = () => {
//   const [email, setEmail] = useState("");
//   const [message, setMessage] = useState("");
//   const apiURL = process.env.REACT_APP_API_URL;

//   const handlePasswordResetRequest = async () => {
//     try {
//       const response = await axios.post(`${apiURL}/api/password-reset-request`, { email });
//       setMessage(response.data.status);
//     } catch (err) {
//       setMessage("Error: " + err.response.data.error);
//     }
//   };

//   return (
//     <div>
//       <h2>Forgot your password?</h2>
//       <p>Enter your email address and we'll send you a link to reset your password.</p>
//       <input
//         type="email"
//         value={email}
//         onChange={(e) => setEmail(e.target.value)}
//         placeholder="Enter your email"
//       />
//       <button onClick={handlePasswordResetRequest}>Send Reset Link</button>

//       {message && <p style={{color:'#ffd700'}}>{message}</p>}
//     </div>
//   );
// };

// export default Reset;




import React, { useState } from "react";
import axios from "axios";
import "./Reset.css"; // Import the CSS file for styles

const Reset = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const apiURL = process.env.REACT_APP_API_URL;

  const handlePasswordResetRequest = async () => {
    try {
      const response = await axios.post(`${apiURL}/api/password-reset-request`, { email });
      setMessage(response.data.status);
    } catch (err) {
      setMessage("Error: " + err.response.data.error);
    }
  };

  return (
    <div className="reset-container">
      <div className="reset-card">
        <h2>Forgot your password?</h2>
        <p>Enter your email address and we'll send you a link to reset your password.</p>
        <input
          className="input-field"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email"
        />
        <button className="reset-btn" onClick={handlePasswordResetRequest}>
          Send Reset Link
        </button>

        <button className="reset-btn mt-2">
          <a href="/">Go Back</a>
        </button>

        {message && <p className="message">{message}</p>}
      </div>
    </div>
  );
};

export default Reset;