import React, { Fragment, createContext, useReducer } from "react";
import AdminLayout from "../layout";
import SizeMenu from "./SizeMenu";
import AllSizes from "./AllSizes";
import { sizeState, sizeReducer } from "./SizeContext";

export const SizeContext = createContext();

const SizeComponent = () => {
  return (
    <div className="grid grid-cols-1 space-y-4 p-4">
      <SizeMenu />
      <AllSizes />
    </div>
  );
};

const Sizes = () => {
  const [data, dispatch] = useReducer(sizeReducer, sizeState);
  return (
    <Fragment>
      <SizeContext.Provider value={{ data, dispatch }}>
        <AdminLayout children={<SizeComponent />} />
      </SizeContext.Provider>
    </Fragment>
  );
};

export default Sizes;