import React, { Fragment, useCallback, useContext, useEffect } from "react";
import { getAllColors, deleteColor } from "./FetchApi";
import { ColorContext } from "./index";
import moment from "moment";

const AllColors = () => {
    const { data, dispatch } = useContext(ColorContext);
    const { colors, loading } = data;

    const fetchData = useCallback(async () => {
        dispatch({ type: "loading", payload: true });
        try {
            let responseData = await getAllColors();
            if (responseData && responseData.colors) {
                dispatch({
                    type: "fetchColorAndChangeState",
                    payload: responseData.colors,
                });
            }
        } catch (error) {
            console.error("Error fetching colors:", error);
        } finally {
            dispatch({ type: "loading", payload: false });
        }
    }, [dispatch]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const deleteColorReq = async (cId) => {
        let deleteC = await deleteColor(cId);
        if (deleteC.error) {
            console.log(deleteC.error);
        } else if (deleteC.success) {
            fetchData();
        }
    };

    const editColor = (cId, name, status) => {
        dispatch({
            type: "editColorModalOpen",
            cId: cId,
            name: name,
            status: status,
        });
    };

    if (loading) {
        return (
            <div className="flex items-center justify-center p-8">
                <svg className="w-12 h-12 animate-spin text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                </svg>
            </div>
        );
    }

    return (
        <Fragment>
            <div className="col-span-1 overflow-auto bg-white shadow-lg p-4">
                <table className="table-auto border w-full my-2">
                    <thead>
                        <tr>
                            <th className="px-4 py-2 border">Color</th>
                            <th className="px-4 py-2 border">Status</th>
                            <th className="px-4 py-2 border">Created at</th>
                            <th className="px-4 py-2 border">Updated at</th>
                            <th className="px-4 py-2 border">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {colors && colors.length > 0 ? (
                            colors.map((item, key) => (
                                <ColorTable
                                    color={item}
                                    editColor={(cId, name, status) => editColor(cId, name, status)}
                                    deleteColor={(cId) => deleteColorReq(cId)}
                                    key={key}
                                />
                            ))
                        ) : (
                            <tr>
                                <td colSpan="5" className="text-xl text-center font-semibold py-8">No Color found</td>
                            </tr>
                        )}
                    </tbody>
                </table>
                <div className="text-sm text-gray-600 mt-2">Total {colors && colors.length} colors found</div>
            </div>
        </Fragment>
    );
};

const ColorTable = ({ color, deleteColor, editColor }) => {
    // Determine the background color based on the status
    const statusColor = color.sStatus === "Active" ? "bg-green-900" : "bg-red-900";

    return (
        <Fragment>
            <tr>
                <td className="p-2 text-left">{color.cName}</td>
                <td className="p-2 text-center">
                    <span className={`${statusColor} rounded-full text-center text-xs px-2 font-semibold`}>
                        {color.sStatus}
                    </span>
                </td>
                <td className="p-2 text-center">{moment(color.createdAt).format("lll")}</td>
                <td className="p-2 text-center">{moment(color.updatedAt).format("lll")}</td>
                <td className="p-2 flex items-center justify-center">
                    <span onClick={() => editColor(color._id, color.cName, color.sStatus)} className="cursor-pointer hover:bg-gray-200 rounded-lg p-2 mx-1">
                        <svg className="w-6 h-6 fill-current text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                            <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                        </svg>
                    </span>
                    <span onClick={() => deleteColor(color._id)} className="cursor-pointer hover:bg-gray-200 rounded-lg p-2 mx-1">
                        <svg className="w-6 h-6 fill-current text-red-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
                        </svg>
                    </span>
                </td>
            </tr>
        </Fragment>
    );
};

export default AllColors;