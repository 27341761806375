import React from 'react';
import { Link } from 'react-router-dom';  // If you're using React Router for navigation
import Navber from './Navber';
import Footer from './Footer';

const Services = () => {
    return (
        <>
            <Navber />
            <div style={{ marginTop: '80px', textAlign: 'justify', textJustify: 'inter-word' }} className="min-h-screen bg-black-100 flex flex-col items-center p-6">
                <div className="max-w-4xl bg-white shadow-lg rounded-lg p-8 space-y-6">
                    <h1 className="text-3xl font-bold text-center text-gray-800">Our Services</h1>

                    <div className="text-gray-600 space-y-6">
                        <h2 className="text-xl font-semibold mt-4">B2B Wholesale Services</h2>
                        <ul className="list-disc list-inside space-y-4">
                            <li>
                                <strong><span role="img" aria-label="star">🌟</span> Bulk Orders: </strong> For businesses looking to stock up on our high-quality apparel, we offer wholesale pricing on minimum orders of 50 pieces (excluding shipping).
                            </li>
                            <li>
                                <strong><span role="img" aria-label="star">🌟</span> Material: </strong> We only deal in cotton and polyester.
                            </li>
                            <li>
                                <strong><span role="img" aria-label="star">🌟</span> How to Connect: </strong> Feel free to connect with us via mail at <a href="mailto:zenithlifestyle.csa@gmail.com" className="text-blue-500">zenithlifestyle.csa@gmail.com</a>. Our team will get back to you ASAP!
                            </li>
                        </ul>

                        <h2 className="text-xl font-semibold mt-4">B2C Personalized Services</h2>
                        <ul className="list-disc list-inside space-y-4">
                            <li>
                                <strong><span role="img" aria-label="star">🌟</span> Print-on-Demand: </strong> Express your unique style with our custom print-on-demand service. Choose from our extensive design library or create your own personalized artwork. We’ll print it directly onto your chosen garment.
                            </li>
                            <li>
                                <strong><span role="img" aria-label="star">🌟</span> Personalized T-shirts: </strong> Start your personalized t-shirt journey at just ₹799. Customize your design and let us bring your vision to life.
                            </li>
                            <li>
                                <strong><span role="img" aria-label="star">🌟</span> Customization: </strong> Whether you’re a business owner looking to stock your shelves or an individual seeking a one-of-a-kind piece, we have got you covered.
                            </li>
                        </ul>

                        {/* Contact Us Button */}
                        <div className="text-center mt-6">
                            <p className="text-gray-600 mb-4">To know more, click below to contact us:</p>
                            <Link to="/contact-us">
                                <button className="bg-blue-500 text-black px-6 py-3 rounded-md text-lg hover:bg-yellow-600 transition duration-300">
                                    Contact Us
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Services;