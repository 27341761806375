import React from 'react';
import Navber from './Navber';
import Footer from './Footer';

const Privacy = () => {
    return (
        <>
            <Navber />
            <div style={{ marginTop: '80px', textAlign: 'justify', textJustify: 'inter-word' }} className="min-h-screen bg-black-100 flex flex-col items-center p-6">
                <div className="max-w-4xl bg-white shadow-lg rounded-lg p-8 space-y-6">
                    <h1 className="text-3xl font-bold text-center text-gray-800">Privacy Policy</h1>

                    <div className="text-gray-600 space-y-6">
                        <h2 className="text-xl font-semibold mt-4">1. Privacy Policy for <span style={{ color: 'red' }}>Zenith Lifestyle:</span></h2>
                        <p>
                            When you visit the site, we collect certain information about your device, your interaction
                            with the site, and information necessary to process your purchases. We may also collect
                            additional information if you contact us for customer support. In this privacy policy, we refer
                            to any information that can uniquely identify an individual (including the information below)
                            as “personal information". See the list below for more information about what personal
                            information we collect and why.
                        </p>

                        <h2 className="text-xl font-semibold mt-4">2 Device information:</h2>
                        <ul className="list-disc list-inside text-gray-600 space-y-4">
                            <li>
                                <strong><span role="img" aria-label="star">🌟</span> Examples of personal information collected: </strong> version of web browser, IP address,
                                time zone, cookie information, what sites or products you view, search terms, and
                                how you interact with the site.
                            </li>
                            <li>
                                <strong><span role="img" aria-label="star">🌟</span> Purpose of collection: </strong> to load the site accurately for you and to perform analytics
                                on site usage to optimize our site.
                            </li>
                            <li>
                                <strong><span role="img" aria-label="star">🌟</span> Source of collection: </strong> Collected automatically when you access our Site using
                                cookies, log files, web beacons, tags, or pixels.
                            </li>
                        </ul>

                        <h2 className="text-xl font-semibold mt-4">3. Order information:</h2>
                        <ul className="list-disc list-inside text-gray-600 space-y-4">
                            <li>
                                <strong><span role="img" aria-label="star">🌟</span> Examples of personal information collected: </strong> name, billing address, shipping
                                address, payment information (transaction number received from merchants),
                                email address, and phone number.
                            </li>
                            <li>
                                <strong><span role="img" aria-label="star">🌟</span> Purpose of collection: </strong> to provide products or services to you to fulfill our contract;
                                to process your payment information; to arrange for shipping; to provide you with
                                invoices and/or order confirmations; to communicate with you; to screen our
                                orders for potential risk or fraud; and, when in line with the preferences you have
                                shared with us, to provide you with information or advertising relating to our
                                products or services.
                            </li>
                            <li>
                                <strong><span role="img" aria-label="star">🌟</span> Disclosure for a business purpose: </strong> shared with our processor, Shopify.
                            </li>
                        </ul>

                        <h2 className="text-xl font-semibold mt-4">4. Customer support information:</h2>
                        <ul className="list-disc list-inside text-gray-600 space-y-4">
                            <li>
                                <strong><span role="img" aria-label="star">🌟</span> Examples of personal information collected: </strong> name, billing address, shipping
                                address, email address, and phone number.
                            </li>
                            <li>
                                <strong><span role="img" aria-label="star">🌟</span> Purpose of collection: </strong> to provide customer support.
                            </li>
                            <li>
                                <strong><span role="img" aria-label="star">🌟</span> Source of collection: </strong> collected from you.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Privacy;