// import React, { Fragment, useEffect, useContext, useState } from "react";
// import { useHistory } from "react-router-dom";
// import { LayoutContext } from "../layout";
// import { subTotal, quantity, totalCost } from "../partials/Mixins";
// import axios from 'axios'
// import { cartListProduct } from "../partials/FetchApi";
// import { getBrainTreeToken, getPaymentProcess } from "./FetchApi";
// import { fetchData, fetchrazorPay, pay } from "./Action";

// const apiURL = process.env.REACT_APP_API_URL;

// export const CheckoutComponent = (props) => {
//   const history = useHistory();
//   const { data, dispatch } = useContext(LayoutContext);

//   const [state, setState] = useState({
//     address: "",
//     phone: "",
//     error: false,
//     success: false,
//     clientToken: null,
//     instance: {},
//   });

//   useEffect(() => {
//     fetchData(cartListProduct, dispatch);
//     fetchrazorPay(getBrainTreeToken, setState);

//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, []);

//   if (data.loading) {
//     return (
//       <div className="flex items-center justify-center h-screen">
//         <svg
//           className="w-12 h-12 animate-spin text-gray-600"
//           fill="none"
//           stroke="currentColor"
//           viewBox="0 0 24 24"
//           xmlns="http://www.w3.org/2000/svg"
//         >
//           <path
//             strokeLinecap="round"
//             strokeLinejoin="round"
//             strokeWidth="2"
//             d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
//           ></path>
//         </svg>
//         Please wait untill finish
//       </div>
//     );
//   }

//   const handleOpenRazorpay = (data) => {
//     const options = {
//       key: 'rzp_test_zPDhEMoZ6ivNaQ',
//       amount: Number(data.amount),
//       currency: data.currency,
//       order_id: data.id,
//       name: 'E-commerce',//
//       description: 'clink subscription',//
//       image: "https://clinktest.s3.ap-south-1.amazonaws.com/configurations/c_solid_icon.png",
//       prefill: { //We recommend using the prefill parameter to auto-fill customer's contact information especially their phone number
//         name: data?.notes?.name, //your customer's name
//         email: data?.notes?.email,
//         contact: data?.notes?.mobile //Provide the customer's phone number for better conversion rates 
//       },
//       notes: {
//         address: "N/A"
//       },
//       theme: {
//         // "color": "#3399cc"
//       },
//       handler: function (response) {
//         axios.post(`http://localhost:8000/paymenyVerify`, { response: response, data: data }, {
//           headers: {
//             'Content-Type': 'application/json',
//             'Authorization': 'your token'
//           }
//         })
//           .catch(err => {
//             console.log(err)
//           })
//       }

//     }
//     const rzp = new window.Razorpay(options)
//     rzp.open()
//   }
//   const  token=localStorage.getItem('tokn')
//   const checkOut = async (data) => {
//     axios.post(`http://localhost:8000/paymenyCheckout`, data, {
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': 'jwt'
//       }
//     }).then(res => {
//       // console.log(res.data.data)
//       handleOpenRazorpay(res.data.data);
//     })
//       .catch(err => {
//         console.log(err)
//       })
//   }

//   return (
//     <Fragment>
//       <section className="mx-4 mt-20 md:mx-12 md:mt-32 lg:mt-24">
//         <div className="text-2xl mx-2">Order</div>
//         {/* Product List */}
//         <div className="flex flex-col md:flex md:space-x-2 md:flex-row">
//           <div className="md:w-1/2">
//             <CheckoutProducts products={data.cartProduct} />
//           </div>
//           <div className="w-full order-first md:order-last md:w-1/2">
//             {state.clientToken !== null ? (
//               <Fragment>
//                 <div
//                   onBlur={(e) => setState({ ...state, error: false })}
//                   className="p-4 md:p-8"
//                 >
//                   {state.error ? (
//                     <div className="bg-red-200 py-2 px-4 rounded">
//                       {state.error}
//                     </div>
//                   ) : (
//                     ""
//                   )}
//                   <div className="flex flex-col py-2">
//                     <label htmlFor="address" className="pb-2">
//                       Dalivery Address
//                     </label>
//                     <input
//                       value={state.address}
//                       onChange={(e) =>
//                         setState({
//                           ...state,
//                           address: e.target.value,
//                           error: false,
//                         })
//                       }
//                       type="text"
//                       id="address"
//                       className="border px-4 py-2"
//                       placeholder="Address..."
//                     />
//                   </div>
//                   <div className="flex flex-col py-2 mb-2">
//                     <label htmlFor="phone" className="pb-2">
//                       Phone
//                     </label>
//                     <input
//                       value={state.phone}
//                       onChange={(e) =>
//                         setState({
//                           ...state,
//                           phone: e.target.value,
//                           error: false,
//                         })
//                       }
//                       type="number"
//                       id="phone"
//                       className="border px-4 py-2"
//                       placeholder="+880"
//                     />
//                   </div>
//                   <div
//                     onClick={(e) =>
//                       // pay(
//                       //   data,
//                       //   dispatch,
//                       //   state,
//                       //   setState,
//                       //   getPaymentProcess,
//                       //   totalCost,
//                       //   history
//                       // )
//                       checkOut({
//                         amount: 10,
//                         quantity: 1,
//                         address: '',
//                         productId: 'hhshs'
//                       })
//                     }
//                     className="w-full px-4 py-2 text-center text-white font-semibold cursor-pointer"
//                     style={{ background: "#303031" }}
//                   >
//                     Pay now
//                   </div>
//                 </div>
//               </Fragment>
//             ) : (
//               <div className="flex items-center justify-center py-12">
//                 <svg
//                   className="w-12 h-12 animate-spin text-gray-600"
//                   fill="none"
//                   stroke="currentColor"
//                   viewBox="0 0 24 24"
//                   xmlns="http://www.w3.org/2000/svg"
//                 >
//                   <path
//                     strokeLinecap="round"
//                     strokeLinejoin="round"
//                     strokeWidth="2"
//                     d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
//                   ></path>
//                 </svg>
//               </div>
//             )}
//           </div>
//         </div>
//       </section>
//     </Fragment>
//   );
// };

// const CheckoutProducts = ({ products }) => {
//   const history = useHistory();

//   return (
//     <Fragment>
//       <div className="grid grid-cols-2 md:grid-cols-1">
//         {products !== null && products.length > 0 ? (
//           products.map((product, index) => {
//             return (
//               <div
//                 key={index}
//                 className="col-span-1 m-2 md:py-6 md:border-t md:border-b md:my-2 md:mx-0 md:flex md:items-center md:justify-between"
//               >
//                 <div className="md:flex md:items-center md:space-x-4">
//                   <img
//                     onClick={(e) => history.push(`/products/${product._id}`)}
//                     className="cursor-pointer md:h-20 md:w-20 object-cover object-center"
//                     src={`${apiURL}/uploads/products/${product.pImages[0]}`}
//                     alt="wishListproduct"
//                   />
//                   <div className="text-lg md:ml-6 truncate">
//                     {product.pName}
//                   </div>
//                   <div className="md:ml-6 font-semibold text-gray-600 text-sm">
//                     Price : ₹{product.pPrice}{" "}
//                   </div>
//                   <div className="md:ml-6 font-semibold text-gray-600 text-sm">
//                     Quantitiy : {quantity(product._id)}
//                   </div>
//                   <div className="font-semibold text-gray-600 text-sm">
//                     Subtotal : ₹{subTotal(product._id, product.pPrice)}
//                   </div>
//                 </div>
//               </div>
//             );
//           })
//         ) : (
//           <div>No product found for checkout</div>
//         )}
//       </div>
//     </Fragment>
//   );
// };

// export default CheckoutProducts;




// import React, { Fragment, useEffect, useContext, useState } from "react";
// import { useHistory } from "react-router-dom";
// import { LayoutContext } from "../layout";
// import { subTotal, quantity, totalCost } from "../partials/Mixins";
// import axios from 'axios';
// import { cartListProduct } from "../partials/FetchApi";
// import { getBrainTreeToken } from "./FetchApi";
// import { fetchData, fetchrazorPay } from "./Action";
// import img_logo from './Zenith_Lifestyle_Logo.png';

// const apiURL = process.env.REACT_APP_API_URL;
// const token = window.localStorage.getItem("jwt");

// export const CheckoutComponent = (props) => {
//   const { data, dispatch } = useContext(LayoutContext);

//   const [state, setState] = useState({
//     address: "",
//     phone: "",
//     error: false,
//     success: false,
//     clientToken: null,
//     instance: {},
//   });

//   useEffect(() => {
//     fetchData(cartListProduct, dispatch);
//     fetchrazorPay(getBrainTreeToken, setState);
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, []);

//   if (data.loading) {
//     return (
//       <div className="flex items-center justify-center h-screen">
//         <svg
//           height="48"
//           width="48"
//           className="w-12 h-12 animate-spin text-gray-600"
//           fill="none"
//           stroke="currentColor"
//           viewBox="0 0 24 24"
//           xmlns="http://www.w3.org/2000/svg"
//         >
//           <path
//             strokeLinecap="round"
//             strokeLinejoin="round"
//             strokeWidth="2"
//             d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
//           ></path>
//         </svg>
//         Please wait until finish
//       </div>
//     );
//   }

//   const handleOpenRazorpay = (data) => {
//     const options = {
//       key: 'rzp_test_e5IrlqVLzOV0dG',
//       amount: data.amount,
//       currency: data.currency,
//       order_id: data.id,
//       name: 'Zenith Lifestyle',
//       description: 'Checkout',
//       image: img_logo,
//       handler: function (response) {
//         axios.post(`${apiURL}/paymentVerify`, {
//           razorpay_payment_id: response.razorpay_payment_id,
//           razorpay_order_id: response.razorpay_order_id,
//           razorpay_signature: response.razorpay_signature,
//           orderData: {
//             user: JSON.parse(localStorage.getItem("jwt")).user._id,
//             allProduct: JSON.parse(localStorage.getItem("cart")),
//             amount: totalCost(data.cartProduct),
//             address: state.address,
//             phone: state.phone,
//           }
//         },
//           {
//             headers: {
//               'Content-Type': 'application/json'
//             }
//           })
//           .then(() => {
//             alert("Payment successful")
//             let orderedProducts = JSON.parse(localStorage.getItem("cart"));

//             const orderedProductIds = orderedProducts.map(product => product._id);
//             let updatedCart = orderedProducts.filter(product => !orderedProductIds.includes(product._id));
//             localStorage.setItem("cart", JSON.stringify(updatedCart));
//             dispatch({ type: "cartProduct", payload: updatedCart });
//             window.location.href = './';
//           })
//           .catch(err => console.log("Payment verification failed:", err));
//       },
//     };
//     const rzp = new window.Razorpay(options);
//     rzp.open();
//   };

//   const checkOut = async (orderData) => {
//     if (!state.address) {
//       alert("Please provide your address.");
//       return;
//     }
//     if (!state.phone) {
//       alert("Please provide your phone number.");
//       return;
//     }
//     try {
//       const response = await axios.post(`${apiURL}/paymentCheckout`, orderData, {
//         headers: {
//           'Content-Type': 'application/json',
//           'Authorization': `Bearer ${token}`
//         }
//       });
//       handleOpenRazorpay(response.data.order);
//     } catch (err) {
//       console.log("Checkout failed:", err);
//     }
//   };

//   return (
//     <Fragment>
//       <section className="mx-4 mt-20 md:mx-12 md:mt-32 lg:mt-24">
//         <div className="text-2xl mx-2">Order</div>
//         <div className="flex flex-col md:flex md:space-x-2 md:flex-row">
//           <div className="md:w-1/2">
//             <CheckoutProducts products={data.cartProduct} />
//           </div>
//           <div className="w-full order-first md:order-last md:w-1/2">
//             {state.clientToken !== null ? (
//               <Fragment>
//                 <div
//                   onBlur={() => setState({ ...state, error: false })}
//                   className="p-4 md:p-8"
//                 >
//                   {state.error && <div className="bg-red-200 py-2 px-4 rounded">{state.error}</div>}
//                   <div className="flex flex-col py-2">
//                     <label htmlFor="address" className="pb-2">Delivery Address</label>
//                     <input
//                       value={state.address || ''}
//                       onChange={(e) =>
//                         setState({
//                           ...state,
//                           address: e.target.value,
//                           error: false,
//                         })
//                       }
//                       type="text"
//                       id="address"
//                       className="border px-4 py-2"
//                       placeholder="Address..."
//                       required
//                     />
//                   </div>
//                   <div className="flex flex-col py-2 mb-2">
//                     <label htmlFor="phone" className="pb-2">Phone</label>
//                     <input
//                       value={state.phone || ''}
//                       onChange={(e) =>
//                         setState({
//                           ...state,
//                           phone: e.target.value,
//                           error: false,
//                         })
//                       }
//                       type="number"
//                       id="phone"
//                       className="border px-4 py-2"
//                       placeholder="+91"
//                       required
//                     />
//                   </div>
//                   <div
//                     onClick={() => checkOut({
//                       amount: totalCost(data.cartProduct),
//                       address: state.address,
//                       phone: state.phone,
//                     })}
//                     className="w-full px-4 py-2 text-center text-white font-semibold cursor-pointer"
//                     style={{ background: "#303031" }}
//                   >
//                     Pay now
//                   </div>
//                 </div>
//               </Fragment>
//             ) : (
//               <div className="flex items-center justify-center py-12">
//                 <svg
//                   width="48"
//                   height="48"
//                   className="w-12 h-12 animate-spin text-gray-600"
//                   fill="none"
//                   stroke="currentColor"
//                   viewBox="0 0 24 24"
//                   xmlns="http://www.w3.org/2000/svg"
//                 >
//                   <path
//                     strokeLinecap="round"
//                     strokeLinejoin="round"
//                     strokeWidth="2"
//                     d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
//                   ></path>
//                 </svg>
//               </div>
//             )}
//           </div>
//         </div>
//       </section>
//     </Fragment>
//   );
// };

// const CheckoutProducts = ({ products }) => {
//   const history = useHistory();

//   return (
//     <Fragment>
//       <div className="grid grid-cols-2 md:grid-cols-1">
//         {products && products.length > 0 ? (
//           products.map((product, index) => (
//             <div
//               key={index}
//               className="col-span-1 m-2 md:py-6 md:border-t md:border-b md:my-2 md:mx-0 md:flex md:items-center md:justify-between"
//             >
//               <div className="md:flex md:items-center md:space-x-4">
//                 <img
//                   onClick={() => history.push(`/products/${product._id}`)}
//                   className="cursor-pointer md:h-20 md:w-20 object-cover object-center"
//                   src={`${apiURL}/uploads/products/${product.pImages[0]}`}
//                   alt="Product"
//                 />
//                 <div className="text-lg md:ml-6 truncate">{product.pName}</div>
//                 <div className="md:ml-6 font-semibold text-gray-600 text-sm">
//                   Price : ₹{product.pPrice}
//                 </div>
//                 <div className="md:ml-6 font-semibold text-gray-600 text-sm">
//                   Quantity : {quantity(product._id)}
//                 </div>
//                 <div className="font-semibold text-gray-600 text-sm">
//                   Subtotal : ₹{subTotal(product._id, product.pPrice)}
//                 </div>
//               </div>
//             </div>
//           ))
//         ) : (
//           <div>No product found for checkout</div>
//         )}
//       </div>
//     </Fragment>
//   );
// };

// export default CheckoutComponent;








import React, { Fragment, useEffect, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { LayoutContext } from "../layout";
import { subTotal, quantity, totalCost } from "../partials/Mixins";
import axios from 'axios';
import { cartListProduct } from "../partials/FetchApi";
import { getBrainTreeToken } from "./FetchApi";
import { fetchData, fetchrazorPay } from "./Action";
import img_logo from './Zenith_Lifestyle_Logo.png';

const apiURL = process.env.REACT_APP_API_URL;
const token = window.localStorage.getItem("jwt");

export const CheckoutComponent = (props) => {
  const { data, dispatch } = useContext(LayoutContext);

  const [state, setState] = useState({
    address: "",
    phone: "",
    deliveryInstruction: "",
    nearbyLandmark: "",
    error: false,
    success: false,
    clientToken: null,
    instance: {},
  });

  useEffect(() => {
    fetchData(cartListProduct, dispatch);
    fetchrazorPay(getBrainTreeToken, setState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (data.loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <svg
          height="48"
          width="48"
          className="w-12 h-12 animate-spin text-gray-600"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
          ></path>
        </svg>
        Please wait until finish
      </div>
    );
  }

  const handleOpenRazorpay = (data) => {
    const options = {
      key: 'rzp_test_INUiGF0znoVU3i',
      amount: data.amount,
      currency: data.currency,
      order_id: data.id,
      name: 'Zenith Lifestyle',
      description: 'Checkout',
      image: img_logo,
      handler: function (response) {
        axios.post(`${apiURL}/paymentVerify`, {
          razorpay_payment_id: response.razorpay_payment_id,
          razorpay_order_id: response.razorpay_order_id,
          razorpay_signature: response.razorpay_signature,
          orderData: {
            user: JSON.parse(localStorage.getItem("jwt")).user._id,
            allProduct: JSON.parse(localStorage.getItem("cart")),
            amount: totalCost(data.cartProduct),
            address: state.address,
            phone: state.phone,
            deliveryInstruction: state.deliveryInstruction,
            nearbyLandmark: state.nearbyLandmark,
          }
        },
          {
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(() => {
            alert("Payment successful")
            let orderedProducts = JSON.parse(localStorage.getItem("cart"));

            const orderedProductIds = orderedProducts.map(product => product._id);
            let updatedCart = orderedProducts.filter(product => !orderedProductIds.includes(product._id));
            localStorage.setItem("cart", JSON.stringify(updatedCart));
            dispatch({ type: "cartProduct", payload: updatedCart });
            window.location.href = './';
          })
          .catch(err => console.log("Payment verification failed:", err));
      },
    };
    const rzp = new window.Razorpay(options);
    rzp.open();
  };

  const checkOut = async (orderData) => {
    if (!state.address) {
      alert("Please provide your address.");
      return;
    }
    if (!state.phone) {
      alert("Please provide your phone number.");
      return;
    }
    if (!state.deliveryInstruction) {
      alert("Please provide Delivery Instruction.");
      return;
    }
    if (!state.nearbyLandmark) {
      alert("Please provide Nearby Landmark.");
      return;
    }
    try {
      const response = await axios.post(`${apiURL}/paymentCheckout`, orderData, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      handleOpenRazorpay(response.data.order);
    } catch (err) {
      console.log("Checkout failed:", err);
    }
  };

  return (
    <Fragment>
      <section className="mx-4 mt-20 md:mx-12 md:mt-32 lg:mt-24">
        <div className="text-2xl mx-2">Order</div>
        <div className="flex flex-col md:flex md:space-x-2 md:flex-row">
          <div className="md:w-1/2">
            <CheckoutProducts products={data.cartProduct} />
          </div>
          <div className="w-full order-first md:order-last md:w-1/2">
            {state.clientToken !== null ? (
              <Fragment>
                <div
                  onBlur={() => setState({ ...state, error: false })}
                  className="p-4 md:p-8"
                >
                  {state.error && <div className="bg-red-200 py-2 px-4 rounded">{state.error}</div>}
                  <div className="flex flex-col py-2">
                    <label htmlFor="address" className="pb-2">Delivery Address</label>
                    <input
                      value={state.address || ''}
                      onChange={(e) =>
                        setState({
                          ...state,
                          address: e.target.value,
                          error: false,
                        })
                      }
                      type="text"
                      id="address"
                      className="border px-4 py-2"
                      placeholder="Address..."
                      required
                    />
                  </div>
                  <div className="flex flex-col py-2 mb-2">
                    <label htmlFor="phone" className="pb-2">Phone</label>
                    <input
                      value={state.phone || ''}
                      onChange={(e) =>
                        setState({
                          ...state,
                          phone: e.target.value,
                          error: false,
                        })
                      }
                      type="number"
                      id="phone"
                      className="border px-4 py-2"
                      placeholder="+91"
                      required
                    />
                  </div>
                  <div className="flex flex-col py-2 mb-2">
                    <label htmlFor="deliveryInstruction" className="pb-2">Delivery Instruction</label>
                    <textarea
                      value={state.deliveryInstruction || ''}
                      onChange={(e) =>
                        setState({
                          ...state,
                          deliveryInstruction: e.target.value,
                          error: false,
                        })
                      }
                      id="deliveryInstruction"
                      className="border px-4 py-2"
                      placeholder="Any special delivery instructions..."
                    />
                  </div>
                  <div className="flex flex-col py-2 mb-2">
                    <label htmlFor="nearbyLandmark" className="pb-2">Nearby Landmark</label>
                    <input
                      value={state.nearbyLandmark || ''}
                      onChange={(e) =>
                        setState({
                          ...state,
                          nearbyLandmark: e.target.value,
                          error: false,
                        })
                      }
                      type="text"
                      id="nearbyLandmark"
                      className="border px-4 py-2"
                      placeholder="Nearby landmark..."
                    />
                  </div>
                  <div
                    onClick={() => checkOut({
                      amount: totalCost(data.cartProduct),
                      address: state.address,
                      phone: state.phone,
                      deliveryInstruction: state.deliveryInstruction,
                      nearbyLandmark: state.nearbyLandmark,
                    })}
                    className="w-full px-4 py-2 text-center text-white font-semibold cursor-pointer"
                    style={{ background: "#303031" }}
                  >
                    Pay now
                  </div>
                </div>
              </Fragment>
            ) : (
              <div className="flex items-center justify-center py-12">
                <svg
                  width="48"
                  height="48"
                  className="w-12 h-12 animate-spin text-gray-600"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                  ></path>
                </svg>
              </div>
            )}
          </div>
        </div>
      </section>
    </Fragment>
  );
};

const CheckoutProducts = ({ products }) => {
  const history = useHistory();

  return (
    <Fragment>
      <div className="grid grid-cols-2 md:grid-cols-1">
        {products && products.length > 0 ? (
          products.map((product, index) => (
            <div
              key={index}
              className="col-span-1 m-2 md:py-6 md:border-t md:border-b md:my-2 md:mx-0 md:flex md:items-center md:justify-between"
            >
              <div className="md:flex md:items-center md:space-x-4">
                <img
                  onClick={() => history.push(`/products/${product._id}`)}
                  className="cursor-pointer md:h-20 md:w-20 object-cover object-center"
                  src={`${apiURL}/uploads/products/${product.pImages[0]}`}
                  alt="Product"
                />
                <div className="text-lg md:ml-6 truncate">{product.pName}</div>
                <div className="md:ml-6 font-semibold text-gray-600 text-sm">
                  Price : ₹{product.pPrice}
                </div>
                <div className="md:ml-6 font-semibold text-gray-600 text-sm">
                  Quantity : {quantity(product._id)}
                </div>
                <div className="font-semibold text-gray-600 text-sm">
                  Subtotal : ₹{subTotal(product._id, product.pPrice)}
                </div>
              </div>
            </div>
          ))
        ) : (
          <div>No product found for checkout</div>
        )}
      </div>
    </Fragment>
  );
};

export default CheckoutComponent;