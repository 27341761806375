import axios from "axios"; // Ensure you have axios imported
const apiURL = process.env.REACT_APP_API_URL;

const BearerToken = () =>
    localStorage.getItem("jwt")
        ? JSON.parse(localStorage.getItem("jwt")).token
        : false;
const Headers = () => {
    return {
        headers: {
            token: `Bearer ${BearerToken()}`,
        },
    };
};

export const getAllSizes = async () => {
  try {
    let res = await axios.get(`${apiURL}/api/sizes/all-sizes`, Headers());
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const createSize = async ({ sName }) => {
  let data = { sName };
  try {
    let res = await axios.post(`${apiURL}/api/sizes/add-size`, data, Headers());
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const editSize = async (sId, { sName, sStatus }) => {
    try {
        const response = await axios.post(`${apiURL}/api/sizes/edit-size`, { sId, sName, sStatus }, Headers());
        return response.data; // Return the response data
    } catch (error) {
        console.error("Error in editSize:", error);
        throw error; // Rethrow the error to handle it in submitForm
    }
};

// New function for deleting a size
export const deleteSize = async (sId) => {
  try {
    let res = await axios.post(`${apiURL}/api/sizes/delete-size`, { sId }, Headers());
    return res.data;
  } catch (error) {
    console.log(error);
    return { error: "Failed to delete size" }; // Optional: return an error message
  }
};