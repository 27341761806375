// import React, { Fragment, useEffect, useState } from "react";
// import { useHistory, useParams } from "react-router-dom";
// import CategorySearch from "../partials/CategorySearch";
// import Layout from "../layout";
// import { productByCategoryHome } from "../../admin/products/FetchApi";
// import { isWishReq, unWishReq, isWish } from "./Mixins";

// const apiURL = process.env.REACT_APP_API_URL;

// const ProductByCategoryPage = () => {
//   const history = useHistory();
//   const { catId } = useParams();
//   const [products, setProducts] = useState([]);
//   const [filteredProducts, setFilteredProducts] = useState([]);
//   const [filterPrice, setFilterPrice] = useState(0);
//   const [searchQuery, setSearchQuery] = useState("");
//   const [wList, setWList] = useState(
//     JSON.parse(localStorage.getItem("wishList")) || []
//   );

//   useEffect(() => {
//     fetchData();
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [catId]);

//   useEffect(() => {
//     localStorage.setItem("wishList", JSON.stringify(wList));
//   }, [wList]);

//   useEffect(() => {
//     let result = products;

//     if (filterPrice > 0) {
//       // Apply price filter
//       result = result.filter(product => product.pPrice <= filterPrice);
//     }

//     if (searchQuery) {
//       // Apply search filter
//       result = result.filter(product =>
//         product.pName.toUpperCase().includes(searchQuery.toUpperCase())
//       );
//     }

//     setFilteredProducts(result);
//   }, [filterPrice, searchQuery, products]);

//   const fetchData = async () => {
//     try {
//       const responseData = await productByCategoryHome(catId);
//       if (responseData && responseData.Products) {
//         setProducts(responseData.Products);
//       }
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   return (
//     <Layout>
//       <Fragment>
//         <section className="mx-4 mt-24 md:mx-12 md:mt-32 lg:mt-24">
//           <div className="flex justify-between items-center pt-6">
//             <div className="text-sm flex space-x-3">
//               <span
//                 className="hover:text-yellow-700 cursor-pointer"
//                 onClick={() => history.push("/all-products")}
//               >
//                 Shop
//               </span>
//               <span className="text-yellow-700 cursor-default">{products[0]?.pCategory?.cName || ""}</span>
//             </div>
//             <div>
//               <svg
//                 className="w-3 h-3"
//                 fill="none"
//                 stroke="currentColor"
//                 viewBox="0 0 24 24"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                   strokeWidth={2}
//                   d="M13 5l7 7-7 7M5 5l7 7-7 7"
//                 />
//               </svg>
//             </div>
//           </div>
//           <CategorySearch onFilterChange={setFilterPrice} onSearchChange={setSearchQuery} />
//           <div>
//             {/* Render filtered products here */}
//             <section className="m-4 md:mx-8 md:my-4 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
//               {filteredProducts.length > 0 ? (
//                 filteredProducts.map(product => (
//                   <div key={product._id} className="relative col-span-1 m-2">
//                     <img
//                       onClick={() => history.push(`/products/${product._id}`)}
//                       className="w-full object-cover object-center cursor-pointer"
//                       src={`${apiURL}/uploads/products/${product.pImages[0]}`}
//                       alt={product.pName}
//                     />
//                     <div className="flex items-center justify-between mt-2">
//                       <div className="text-gray-600 font-light truncate">
//                         {product.pName}
//                       </div>
//                       <div className="flex items-center space-x-1">
//                         <span>
//                           <svg
//                             className="w-4 h-4 fill-current text-yellow-700"
//                             fill="none"
//                             stroke="currentColor"
//                             viewBox="0 0 24 24"
//                             xmlns="http://www.w3.org/2000/svg"
//                           >
//                             <path
//                               strokeLinecap="round"
//                               strokeLinejoin="round"
//                               strokeWidth={2}
//                               d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
//                             />
//                           </svg>
//                         </span>
//                         <span className="text-gray-700">
//                           {/* {product.pRatings ? product.pRatings.length : 0} */}
//                           {product.pRatingsReviews && product.pRatingsReviews.length > 0
//                             ? product.pRatingsReviews.length
//                             : 0}
//                         </span>
//                       </div>
//                     </div>
//                     <div>₹<span style={{color:'red'}}>{product.pPrice}</span></div>
//                     <div className="absolute top-0 right-0 mx-2 my-2 md:mx-4">
//                       <svg
//                         onClick={(e) => isWishReq(e, product._id, setWList)}
//                         className={`${isWish(product._id, wList) ? "hidden" : ""} w-5 h-5 md:w-6 md:h-6 cursor-pointer text-red-700 transition-all duration-300 ease-in`}
//                         fill="none"
//                         stroke="currentColor"
//                         viewBox="0 0 24 24"
//                         xmlns="http://www.w3.org/2000/svg"
//                       >
//                         <path
//                           strokeLinecap="round"
//                           strokeLinejoin="round"
//                           strokeWidth={2}
//                           d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
//                         />
//                       </svg>
//                       <svg
//                         onClick={(e) => unWishReq(e, product._id, setWList)}
//                         className={`${!isWish(product._id, wList) ? "hidden" : ""} w-5 h-5 md:w-6 md:h-6 cursor-pointer text-red-700 transition-all duration-300 ease-in`}
//                         fill="currentColor"
//                         viewBox="0 0 20 20"
//                         xmlns="http://www.w3.org/2000/svg"
//                       >
//                         <path
//                           fillRule="evenodd"
//                           d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
//                           clipRule="evenodd"
//                         />
//                       </svg>
//                     </div>
//                   </div>
//                 ))
//               ) : (
//                 <div className="col-span-2 md:col-span-3 lg:col-span-4 flex items-center justify-center py-24 text-2xl">
//                   No product found
//                 </div>
//               )}
//             </section>
//           </div>
//         </section>
//       </Fragment>
//     </Layout>
//   );
// };

// export default ProductByCategoryPage;


import React, { Fragment, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import CategorySearch from "../partials/CategorySearch";
import Layout from "../layout";
import { isWishReq, unWishReq, isWish } from "./Mixins";

const apiURL = process.env.REACT_APP_API_URL;

const ProductByCategoryPage = () => {
  const history = useHistory();
  const { catId } = useParams();
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [filterPrice, setFilterPrice] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [wList, setWList] = useState(
    JSON.parse(localStorage.getItem("wishList")) || []
  );
  const [currentPage, setCurrentPage] = useState(1);  // Track current page
  const [totalPages, setTotalPages] = useState(1);    // Track total pages

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catId, currentPage]);

  useEffect(() => {
    localStorage.setItem("wishList", JSON.stringify(wList));
  }, [wList]);

  useEffect(() => {
    let result = products;

    if (filterPrice > 0) {
      // Apply price filter
      result = result.filter(product => product.pPrice <= filterPrice);
    }

    if (searchQuery) {
      // Apply search filter
      result = result.filter(product =>
        product.pName.toUpperCase().includes(searchQuery.toUpperCase())
      );
    }

    setFilteredProducts(result);
  }, [filterPrice, searchQuery, products]);

  const fetchData = async () => {
    try {
      // Prepare the data to send in the request body
      const requestData = {
        catId: catId,
        page: currentPage,  // Pass the current page
        limit: 16,          // Set the limit (number of items per page)
      };

      // Call the API with the correct body payload
      const response = await fetch(`${apiURL}/api/product/product-by-category-home`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),  // Send the data in the request body
      });

      const responseData = await response.json();

      // Check if the response contains Products
      if (responseData && responseData.Products) {
        setProducts(responseData.Products);        // Set the fetched products
        setTotalPages(responseData.totalPages);    // Set totalPages from the response
      } else {
        console.error("No products returned from API");
      }
    } catch (error) {
      console.error("Error fetching products: ", error);
    }
  };

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);  // Set current page and fetch data
    }
  };

  return (
    <Layout>
      <Fragment>
        <section className="mx-4 mt-24 md:mx-12 md:mt-32 lg:mt-24">
          <div className="flex justify-between items-center pt-6">
            <div className="text-sm flex space-x-3">
              <span
                className="hover:text-yellow-700 cursor-pointer"
                onClick={() => history.push("/all-products")}
              >
                Shop
              </span>
              <span className="text-yellow-700 cursor-default">
                {products[0]?.pCategory?.cName || ""}
              </span>
            </div>
            <div>
              <svg
                className="w-3 h-3"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M13 5l7 7-7 7M5 5l7 7-7 7"
                />
              </svg>
            </div>
          </div>
          <CategorySearch onFilterChange={setFilterPrice} onSearchChange={setSearchQuery} />
          <div>
            {/* Render filtered products here */}
            <section className="m-4 md:mx-8 md:my-4 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
              {filteredProducts.length > 0 ? (
                filteredProducts.map(product => (
                  <div key={product._id} className="relative col-span-1 m-2">
                    <img
                      onClick={() => history.push(`/products/${product._id}`)}
                      className="w-full object-cover object-center cursor-pointer"
                      src={`${apiURL}/uploads/products/${product.pImages[0]}`}
                      alt={product.pName}
                    />
                    <div className="flex items-center justify-between mt-2">
                      <div className="text-gray-600 font-light truncate">
                        {product.pName}
                      </div>
                      <div className="flex items-center space-x-1">
                        <span>
                          <svg
                            className="w-4 h-4 fill-current text-yellow-700"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
                            />
                          </svg>
                        </span>
                        <span className="text-gray-700">
                          {product.pRatingsReviews ? product.pRatingsReviews.length : 0}
                        </span>
                      </div>
                    </div>
                    <div>₹<span style={{ color: 'red' }}>{product.pPrice}</span></div>
                    <div className="absolute top-0 right-0 mx-2 my-2 md:mx-4">
                      <svg
                        onClick={(e) => isWishReq(e, product._id, setWList)}
                        className={`${isWish(product._id, wList) ? "hidden" : ""} w-5 h-5 md:w-6 md:h-6 cursor-pointer text-red-700 transition-all duration-300 ease-in`}
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                        />
                      </svg>
                      <svg
                        onClick={(e) => unWishReq(e, product._id, setWList)}
                        className={`${!isWish(product._id, wList) ? "hidden" : ""} w-5 h-5 md:w-6 md:h-6 cursor-pointer text-red-700 transition-all duration-300 ease-in`}
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                  </div>
                ))
              ) : (
                <div className="col-span-2 md:col-span-3 lg:col-span-4 flex items-center justify-center py-24 text-2xl">
                  No product found
                </div>
              )}
            </section>

            {/* Pagination */}
            <div className="mt-6">
              <button
                style={{ backgroundColor: '#ffd700' }}
                className="p-2 rounded-md text-black"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </button>
              <span style={{color:'red'}} className="mx-2">
                Page {currentPage} of {totalPages}
              </span>
              <button
                style={{ backgroundColor: '#ffd700' }}
                className="p-2 rounded-md text-black"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </div>
          </div>
        </section>
      </Fragment>
    </Layout>
  );
};

export default ProductByCategoryPage;