// import React, { Fragment } from "react";

// const FilterCategory = () => {
//     return (
//         <Fragment>
//             <div className="flex justify-between font-medium">
//                 <div className="flex items-center space-x-1 cursor-pointer">
//                     <span className="text-md md:text-lg hover:text-yellow-700">
//                         Categories
//                     </span>
//                     <svg
//                         className="w-4 h-4 text-yellow-700"
//                         fill="none"
//                         stroke="currentColor"
//                         viewBox="0 0 24 24"
//                         xmlns="http://www.w3.org/2000/svg"
//                     >
//                         <path
//                             strokeLinecap="round"
//                             strokeLinejoin="round"
//                             strokeWidth="2"
//                             d="M19 9l-7 7-7-7"
//                         ></path>
//                     </svg>
//                 </div>
//                 <div className="flex space-x-2">
//                     <div className="flex items-center space-x-1 cursor-pointer">
//                         <span className="text-md md:text-lg">Filter</span>
//                         <span>
//                             <svg
//                                 className="w-4 h-4 text-gray-700 text-yellow-700"
//                                 fill="none"
//                                 stroke="currentColor"
//                                 viewBox="0 0 24 24"
//                                 xmlns="http://www.w3.org/2000/svg"
//                             >
//                                 <path
//                                     strokeLinecap="round"
//                                     strokeLinejoin="round"
//                                     strokeWidth="2"
//                                     d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
//                                 ></path>
//                             </svg>
//                         </span>
//                     </div>
//                     <span>/</span>
//                     <div className="flex items-center space-x-1 cursor-pointer">
//                         <span className="text-md md:text-lg">Search</span>
//                         <span>
//                             <svg
//                                 className="w-4 h-4 text-gray-700 text-yellow-700"
//                                 fill="none"
//                                 stroke="currentColor"
//                                 viewBox="0 0 24 24"
//                                 xmlns="http://www.w3.org/2000/svg"
//                             >
//                                 <path
//                                     strokeLinecap="round"
//                                     strokeLinejoin="round"
//                                     strokeWidth="2"
//                                     d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
//                                 ></path>
//                             </svg>
//                         </span>
//                     </div>
//                 </div>
//             </div>
//         </Fragment>
//     );
// };

// export default FilterCategory

// import React, { useState, useEffect } from "react";
// import { useHistory } from "react-router-dom";
// import { getAllCategoryHome } from "../../admin/categories/FetchApi";

// const apiURL = process.env.REACT_APP_API_URL;

// const FilterCategory = ({ onFilterChange, onSearchChange }) => {
//   const [categories, setCategories] = useState([]);
//   const [range, setRange] = useState(0);
//   const [search, setSearch] = useState("");
//   const [categoryDropdown, setCategoryDropdown] = useState(false);
//   const [filterDropdown, setFilterDropdown] = useState(false);
//   const [searchDropdown, setSearchDropdown] = useState(false);
//   const history = useHistory();

//   useEffect(() => {
//     fetchCategories();
//   }, []);

//   const fetchCategories = async () => {
//     try {
//       let responseData = await getAllCategoryHome();
//       if (responseData && responseData.Categories) {
//         setCategories(responseData.Categories);
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   const handleCategoryClick = (categoryId) => {
//     history.push(`/products/category/${categoryId}`);
//     setCategoryDropdown(false);
//   };

//   const handleFilterChange = (e) => {
//     const value = e.target.value;
//     setRange(value);
//     onFilterChange(value); // Pass the value to the parent component
//   };

//   const handleSearch = (e) => {
//     const value = e.target.value;
//     setSearch(value);
//     onSearchChange(value); // Pass the value to the parent component
//   };

//   const closeCategoryDropdown = () => setCategoryDropdown(false);
//   const closeFilterDropdown = () => setFilterDropdown(false);
//   const closeSearchDropdown = () => setSearchDropdown(false);

//   return (
//     <div className="my-4">
//       <div className="flex justify-between font-medium">
//         <div
//           onClick={() => setCategoryDropdown(!categoryDropdown)}
//           className="flex items-center space-x-1 cursor-pointer"
//         >
//           <span className="text-md md:text-lg hover:text-yellow-700">Categories</span>
//           <svg
//             className={`w-4 h-4 ${categoryDropdown ? "text-yellow-700" : "text-gray-700"}`}
//             fill="none"
//             stroke="currentColor"
//             viewBox="0 0 24 24"
//             xmlns="http://www.w3.org/2000/svg"
//           >
//             <path
//               strokeLinecap="round"
//               strokeLinejoin="round"
//               strokeWidth="2"
//               d={`M19 ${categoryDropdown ? "9l-7 7-7-7" : "6l12 12"} `}
//             ></path>
//           </svg>
//         </div>
//         <div className="flex space-x-2">
//           <div
//             onClick={() => setFilterDropdown(!filterDropdown)}
//             className="flex items-center space-x-1 cursor-pointer"
//           >
//             <span className="text-md md:text-lg">Filter</span>
//             <svg
//               className={`w-4 h-4 ${filterDropdown ? "text-yellow-700" : "text-gray-700"}`}
//               fill="none"
//               stroke="currentColor"
//               viewBox="0 0 24 24"
//               xmlns="http://www.w3.org/2000/svg"
//             >
//               <path
//                 strokeLinecap="round"
//                 strokeLinejoin="round"
//                 strokeWidth="2"
//                 d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
//               ></path>
//             </svg>
//           </div>
//           <span>/</span>
//           <div
//             onClick={() => setSearchDropdown(!searchDropdown)}
//             className="flex items-center space-x-1 cursor-pointer"
//           >
//             <span className="text-md md:text-lg">Search</span>
//             <svg
//               className={`w-4 h-4 ${searchDropdown ? "text-yellow-700" : "text-gray-700"}`}
//               fill="none"
//               stroke="currentColor"
//               viewBox="0 0 24 24"
//               xmlns="http://www.w3.org/2000/svg"
//             >
//               <path
//                 strokeLinecap="round"
//                 strokeLinejoin="round"
//                 strokeWidth="2"
//                 d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
//               ></path>
//             </svg>
//           </div>
//         </div>
//       </div>

//       {categoryDropdown && (
//         <div className="my-4 relative flex flex-col items-end">
//           <button
//             onClick={closeCategoryDropdown}
//             className="absolute top-2 right-2 text-gray-700 hover:text-gray-900"
//           >
//             <svg
//               className="w-4 h-4"
//               fill="none"
//               stroke="currentColor"
//               viewBox="0 0 24 24"
//               xmlns="http://www.w3.org/2000/svg"
//             >
//               <path
//                 strokeLinecap="round"
//                 strokeLinejoin="round"
//                 strokeWidth="2"
//                 d="M6 18L18 6M6 6l12 12"
//               ></path>
//             </svg>
//           </button>
//           <hr />
//           <div className="py-1 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-8">
//             {categories.length > 0 ? (
//               categories.map((category) => (
//                 <div
//                   key={category._id}
//                   onClick={() => handleCategoryClick(category._id)}
//                   className="col-span-1 m-2 flex flex-col items-center justify-center space-y-2 cursor-pointer"
//                 >
//                   <img
//                     src={`${apiURL}/uploads/categories/${category.cImage}`}
//                     alt={category.cName}
//                   />
//                   <div className="font-medium">{category.cName}</div>
//                 </div>
//               ))
//             ) : (
//               <div className="text-xl text-center my-4">No Category</div>
//             )}
//           </div>
//         </div>
//       )}

//       {filterDropdown && (
//         <div className="my-4 relative flex flex-col items-end">
//           <button
//             onClick={closeFilterDropdown}
//             className="absolute top-2 right-2 text-gray-700 hover:text-gray-900"
//           >
//             <svg
//               className="w-4 h-4"
//               fill="none"
//               stroke="currentColor"
//               viewBox="0 0 24 24"
//               xmlns="http://www.w3.org/2000/svg"
//             >
//               <path
//                 strokeLinecap="round"
//                 strokeLinejoin="round"
//                 strokeWidth="2"
//                 d="M6 18L18 6M6 6l12 12"
//               ></path>
//             </svg>
//           </button>
//           <hr />
//           <div className="w-full flex flex-col">
//             <div className="font-medium py-2">Filter by price</div>
//             <div className="flex justify-between items-center">
//               <div className="flex flex-col space-y-2 w-2/3 lg:w-2/4">
//                 <label htmlFor="priceRange" className="text-sm">
//                   Price from 0 to{" "}
//                   <span className="font-semibold text-yellow-700">{range}₹</span>{" "}
//                 </label>
//                 <input
//                   id="priceRange"
//                   type="range"
//                   min="0"
//                   max="10000"
//                   step="10"
//                   value={range}
//                   onChange={handleFilterChange}
//                   className="slider"
//                 />
//               </div>
//             </div>
//           </div>
//         </div>
//       )}

//       {searchDropdown && (
//         <div className="my-4 relative flex items-center">
//           <input
//             type="text"
//             value={search}
//             onChange={handleSearch}
//             className="px-4 text-xl py-4 focus:outline-none"
//             placeholder="Search products..."
//           />
//           <button
//             onClick={closeSearchDropdown}
//             className="absolute top-2 right-2 text-gray-700 hover:text-gray-900"
//           >
//             <svg
//               className="w-4 h-4"
//               fill="none"
//               stroke="currentColor"
//               viewBox="0 0 24 24"
//               xmlns="http://www.w3.org/2000/svg"
//             >
//               <path
//                 strokeLinecap="round"
//                 strokeLinejoin="round"
//                 strokeWidth="2"
//                 d="M6 18L18 6M6 6l12 12"
//               ></path>
//             </svg>
//           </button>
//         </div>
//       )}
//     </div>
//   );
// };

// export default FilterCategory;



import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { getAllCategoryHome } from "../../admin/categories/FetchApi";

const apiURL = process.env.REACT_APP_API_URL;

const FilterCategory = ({ onFilterChange, onSearchChange }) => {
  const [categories, setCategories] = useState([]);
  const [range, setRange] = useState(0);
  const [search, setSearch] = useState("");
  const [categoryDropdown, setCategoryDropdown] = useState(false);
  const [filterDropdown, setFilterDropdown] = useState(false);
  const [searchDropdown, setSearchDropdown] = useState(false);
  const history = useHistory();

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      let responseData = await getAllCategoryHome();
      if (responseData && responseData.Categories) {
        setCategories(responseData.Categories);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCategoryClick = (categoryId) => {
    history.push(`/products/category/${categoryId}`);
    setCategoryDropdown(false);
  };

  const handleFilterChange = (e) => {
    const value = e.target.value;
    setRange(value);
    onFilterChange(value); // Pass the value to the parent component
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearch(value);
    onSearchChange(value); // Pass the value to the parent component
  };

  const clearFilter = () => {
    setRange(0);
    onFilterChange(0); // Notify the parent component that the filter has been cleared
    window.location.reload(); // Navigate to /all-products
  };

  const closeCategoryDropdown = () => setCategoryDropdown(false);
  const closeFilterDropdown = () => setFilterDropdown(false);
  const closeSearchDropdown = () => setSearchDropdown(false);

  return (
    <div className="my-4">
      <div className="flex justify-between font-medium">
        <div
          onClick={() => setCategoryDropdown(!categoryDropdown)}
          className="flex items-center space-x-1 cursor-pointer"
        >
          <span className="text-md md:text-lg hover:text-yellow-700">Categories ➪</span>
          {/* <svg
            className={`w-4 h-4 ${categoryDropdown ? "text-yellow-700" : "text-gray-700"}`}
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d={`M19 ${categoryDropdown ? "9l-7 7-7-7" : "6l12 12"} `}
            ></path>
          </svg> */}
        </div>
        
        <div className="flex space-x-2">
          <div
            onClick={() => setFilterDropdown(!filterDropdown)}
            className="flex items-center space-x-1 cursor-pointer"
          >
            <span className="text-md md:text-lg">Filter</span>
            <svg
              className={`w-4 h-4 ${filterDropdown ? "text-yellow-700" : "text-gray-700"}`}
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
              ></path>
            </svg>
          </div>
          <span>/</span>
          <div
            onClick={() => setSearchDropdown(!searchDropdown)}
            className="flex items-center space-x-1 cursor-pointer"
          >
            <span className="text-md md:text-lg">Search</span>
            <svg
              className={`w-4 h-4 ${searchDropdown ? "text-yellow-700" : "text-gray-700"}`}
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              ></path>
            </svg>
          </div>
        </div>
      </div>

      {categoryDropdown && (
        <div className="my-4 relative flex flex-col items-end">
          <button
            onClick={closeCategoryDropdown}
            className="absolute top-2 right-2 text-gray-700 hover:text-gray-900"
          >
            <svg
              className="w-4 h-4"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          </button>
          <hr />
          <div className="py-1 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-8">
            {categories.length > 0 ? (
              categories.map((category) => (
                <div
                  key={category._id}
                  onClick={() => handleCategoryClick(category._id)}
                  className="col-span-1 m-2 flex flex-col items-center justify-center space-y-2 cursor-pointer"
                >
                  <img
                    src={`${apiURL}/uploads/categories/${category.cImage}`}
                    alt={category.cName}
                  />
                  <div className="font-medium">{category.cName}</div>
                </div>
              ))
            ) : (
              <div className="text-xl text-center my-4">No Category</div>
            )}
          </div>
        </div>
      )}

      {filterDropdown && (
        <div className="my-4 relative flex flex-col items-end">
          <button
            onClick={closeFilterDropdown}
            className="absolute top-2 right-2 text-gray-700 hover:text-gray-900"
          >
            <svg
              className="w-4 h-4"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          </button>
          <hr />
          <div className="w-full flex flex-col">
            <div className="font-medium py-2">Filter by price</div>
            <div className="flex justify-between items-center">
              <div className="flex flex-col space-y-2 w-2/3 lg:w-2/4">
                <label htmlFor="priceRange" className="text-sm">
                  Price from 0 to{" "}
                  <span className="font-semibold text-yellow-700">{range}₹</span>{" "}
                </label>
                <input
                  id="priceRange"
                  type="range"
                  min="0"
                  max="10000"
                  step="10"
                  value={range}
                  onChange={handleFilterChange}
                  className="slider"
                />
                <button
                  onClick={clearFilter}
                  className="mt-2 px-4 py-2 bg-gradient-to-r from-red-500 to-red-600 text-red-400 rounded-lg shadow-lg transition-transform transform hover:scale-105 hover:shadow-xl focus:outline-none focus:ring-2 focus:ring-red-300"
                >
                  Clear Filter
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {searchDropdown && (
        <div className="my-4 relative flex items-center">
          <input
            type="text"
            value={search}
            onChange={handleSearch}
            className="px-4 text-xl py-4 focus:outline-none"
            placeholder="Search products..."
          />
          <button
            onClick={closeSearchDropdown}
            className="absolute top-2 right-2 text-gray-700 hover:text-gray-900"
          >
            <svg
              className="w-4 h-4"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          </button>
        </div>
      )}
    </div>
  );
};

export default FilterCategory;