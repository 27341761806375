// import React, { Fragment } from "react";
// import moment from "moment";

// const Footer = (props) => {
//   return (
//     <Fragment>
//       <footer
//         style={{ background: "black", color: "#ffd700" }}
//         className="z-10 py-6 px-4 md:px-12 text-center"
//       >
//         {moment().format("YYYY")} © Zenith Lifestyle. All Rights Reserved.
//       </footer>
//     </Fragment>
//   );
// };

// export default Footer;


import React, { Fragment } from "react";
import moment from "moment";
import card_1 from './footerImages/card_2.webp';
import card_2 from './footerImages/card_3.webp';
import card_3 from './footerImages/OIP.webp';

const Footer = (props) => {
  return (
    <Fragment>
      <footer
        style={{ background: "black", color: "#ffd700" }}
        className="z-10 py-6 px-4 md:px-12 flex flex-col md:flex-row justify-between items-center"
      >
        <div className="flex flex-col mb-4">
          <h4 className="font-bold mb-2">Important Links:</h4>
          <div className="flex flex-col md:flex-row md:space-x-4 text-center">
            <a href="/about-us" className="hover:underline">About Us</a>
            <a href="/terms-and-conditions" className="hover:underline">Terms</a>
            <a href="/privacy-policy" className="hover:underline">Privacy Policy</a>
          </div>
        </div>

        <div className="mt-4 md:mt-0 text-center md:text-right">
          <p className="font-bold">We accept all kinds of payments:</p>
          <div className="flex justify-center md:justify-end mt-2">
            <img src={card_1} alt="Visa" className="h-8 mx-2" />
            <img src={card_2} alt="MasterCard" className="h-8 mx-2" />
            <img src={card_3} alt="PayPal" className="h-8 mx-2" />
          </div>
        </div>
      </footer>

      <div className="text-center py-4">
        <div>
          {moment().format("YYYY")} © Zenith Lifestyle. All Rights Reserved.
        </div>
      </div>
    </Fragment>
  );
};

export default Footer;