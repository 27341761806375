// import React, { useState } from "react";
// import axios from "axios";
// import { useParams } from "react-router-dom";

// const ResetPassword = () => {
//   const [password, setPassword] = useState("");
//   const [confirmPassword, setConfirmPassword] = useState("");
//   const [message, setMessage] = useState("");

//   const { token } = useParams(); // Retrieve the token from the URL

//   const handleResetPassword = async () => {
//     if (password !== confirmPassword) {
//       setMessage("Passwords do not match.");
//       return;
//     }

//     try {
//       const response = await axios.post(
//         `http://localhost:8000/api/reset-password/${token}`,
//         { password }
//       );
//       setMessage(response.data.status);
//     } catch (error) {
//       setMessage("Error: " + (error.response?.data?.status || "Server error"));
//     }
//   };

//   return (
//     <div>
//       <h2>Reset Your Password</h2>
//       <input
//         type="password"
//         value={password}
//         onChange={(e) => setPassword(e.target.value)}
//         placeholder="Enter new password"
//       />
//       <input
//         type="password"
//         value={confirmPassword}
//         onChange={(e) => setConfirmPassword(e.target.value)}
//         placeholder="Confirm new password"
//       />
//       <button onClick={handleResetPassword}>Reset Password</button>
//       {message && <p>{message}</p>}
//     </div>
//   );
// };

// export default ResetPassword;





import React, { useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import './ResetPassword.css'; // Import the custom CSS file

const ResetPassword = () => {
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [message, setMessage] = useState("");
    const apiURL =  process.env.REACT_APP_API_URL;

    const { token } = useParams(); // Retrieve the token from the URL

    const handleResetPassword = async () => {
        if (password !== confirmPassword) {
            setMessage("Passwords do not match.");
            return;
        }

        try {
            const response = await axios.post(
                `${apiURL}/api/reset-password/${token}`,
                { password }
            );
            setMessage(response.data.status +".! You will be redirecting to the homepage in 5 seconds.");
            setTimeout(() => {
                window.location='/'
            }, 5000);
        } catch (error) {
            setMessage("Error: " + (error.response?.data?.status || "Server error"));
        }
    };

    return (
        <div className="reset-password-container">
            <div className="reset-password-card">
                <h2>Reset Your Password</h2>
                <p>Enter a new password below to reset your account password.</p>

                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Enter new password"
                    className="input-field"
                />

                <input
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    placeholder="Confirm new password"
                    className="input-field"
                />

                <button onClick={handleResetPassword} className="reset-btn">
                    Reset Password
                </button>

                {message && <p className="message">{message}</p>}
            </div>
        </div>
    );
};

export default ResetPassword;