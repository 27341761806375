// import React, { Fragment, useContext, useState, useEffect } from "react";
// import { ProductContext } from "./index";
// import { editProduct, getAllProduct } from "./FetchApi";
// import { getAllCategory } from "../categories/FetchApi";
// const apiURL = process.env.REACT_APP_API_URL;

// const EditProductModal = (props) => {
//   const { data, dispatch } = useContext(ProductContext);
//   const [categories, setCategories] = useState([]);
//   const [editformData, setEditformdata] = useState({
//     pId: "",
//     pName: "",
//     pDescription: "",
//     pImages: null,
//     pEditImages: null,
//     pStatus: "",
//     pCategory: "",
//     pQuantity: "",
//     pPrice: "",
//     pOffer: "",
//     error: false,
//     success: false,
//   });

//   // Fetch categories when component mounts
//   useEffect(() => {
//     const fetchCategoryData = async () => {
//       try {
//         let responseData = await getAllCategory();
//         if (responseData.Categories) {
//           setCategories(responseData.Categories);
//         }
//       } catch (error) {
//         console.error("Failed to fetch categories:", error);
//       }
//     };

//     fetchCategoryData();
//   }, []);

//   // Update form data when editProductModal changes
//   useEffect(() => {
//     if (data.editProductModal) {
//       setEditformdata({
//         pId: data.editProductModal.pId || "",
//         pName: data.editProductModal.pName || "",
//         pDescription: data.editProductModal.pDescription || "",
//         pImages: data.editProductModal.pImages || null,
//         pStatus: data.editProductModal.pStatus || "",
//         pCategory: data.editProductModal.pCategory._id || "",
//         pQuantity: data.editProductModal.pQuantity || "",
//         pPrice: data.editProductModal.pPrice || "",
//         pOffer: data.editProductModal.pOffer || "",
//         error: false,
//         success: false,
//       });
//     }
//   }, [data.editProductModal]);

//   // Fetch products after editing
//   const fetchData = async () => {
//     try {
//       let responseData = await getAllProduct();
//       if (responseData && responseData.Products) {
//         dispatch({
//           type: "fetchProductsAndChangeState",
//           payload: responseData.Products,
//         });
//       }
//     } catch (error) {
//       console.error("Failed to fetch products:", error);
//     }
//   };

//   const submitForm = async (e) => {
//     e.preventDefault();
//     try {
//       let responseData = await editProduct(editformData);
//       if (responseData.success) {
//         await fetchData();
//         setEditformdata((prevData) => ({
//           ...prevData,
//           success: responseData.success,
//           error: false,  // Clear any previous errors
//         }));
//         setTimeout(() => {
//           setEditformdata((prevData) => ({
//             ...prevData,
//             success: false,  // Clear success message after timeout
//           }));
//         }, 2000);
//       } else if (responseData.error) {
//         setEditformdata((prevData) => ({
//           ...prevData,
//           error: responseData.error,
//           success: false,  // Clear any previous successes
//         }));
//         setTimeout(() => {
//           setEditformdata((prevData) => ({
//             ...prevData,
//             error: false,  // Clear error message after timeout
//           }));
//         }, 2000);
//       }
//     } catch (error) {
//       console.log(error);
//       setEditformdata((prevData) => ({
//         ...prevData,
//         error: "An unexpected error occurred.",
//         success: false,
//       }));
//       setTimeout(() => {
//         setEditformdata((prevData) => ({
//           ...prevData,
//           error: false,
//         }));
//       }, 2000);
//     }
//   };

//   // Alert function
//   const alert = (msg, type) => (
//     <div className={`bg-${type}-200 py-2 px-4 w-full`}>{msg}</div>
//   );

//   return (
//     <Fragment>
//       {/* Black Overlay */}
//       <div
//         onClick={() => dispatch({ type: "editProductModalClose", payload: false })}
//         className={`${data.editProductModal.modal ? "" : "hidden"} fixed top-0 left-0 z-30 w-full h-full bg-black opacity-50`}
//       />
//       {/* End Black Overlay */}

//       {/* Modal Start */}
//       <div
//         className={`${data.editProductModal.modal ? "" : "hidden"} fixed inset-0 flex items-center z-30 justify-center overflow-auto`}
//       >
//         <div className="mt-32 md:mt-0 relative bg-white w-11/12 md:w-3/6 shadow-lg flex flex-col items-center space-y-4 px-4 py-4 md:px-8">
//           <div className="flex items-center justify-between w-full pt-4">
//             <span className="text-left font-semibold text-2xl tracking-wider">Edit Product</span>
//             {/* Close Modal */}
//             <span
//               style={{ background: "#303031" }}
//               onClick={() => dispatch({ type: "editProductModalClose", payload: false })}
//               className="cursor-pointer text-gray-100 py-2 px-2 rounded-full"
//             >
//               <svg
//                 className="w-6 h-6"
//                 fill="none"
//                 stroke="currentColor"
//                 viewBox="0 0 24 24"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
//               </svg>
//             </span>
//           </div>

//           {/* Render alerts */}
//           {editformData.error && alert(editformData.error, "red")}
//           {editformData.success && alert(editformData.success, "green")}

//           <form className="w-full" onSubmit={submitForm}>
//             <div className="flex space-x-1 py-4">
//               <div className="w-1/2 flex flex-col space-y-1">
//                 <label htmlFor="name">Product Name *</label>
//                 <input
//                   value={editformData.pName}
//                   onChange={(e) =>
//                     setEditformdata({
//                       ...editformData,
//                       pName: e.target.value,
//                     })
//                   }
//                   className="px-4 py-2 border focus:outline-none"
//                   type="text"
//                 />
//               </div>
//               <div className="w-1/2 flex flex-col space-y-1">
//                 <label htmlFor="price">Product Price *</label>
//                 <input
//                   value={editformData.pPrice}
//                   onChange={(e) =>
//                     setEditformdata({
//                       ...editformData,
//                       pPrice: e.target.value,
//                     })
//                   }
//                   type="number"
//                   className="px-4 py-2 border focus:outline-none"
//                   id="price"
//                 />
//               </div>
//             </div>
//             <div className="flex flex-col space-y-2">
//               <label htmlFor="description">Product Description *</label>
//               <textarea
//                 value={editformData.pDescription}
//                 onChange={(e) =>
//                   setEditformdata({
//                     ...editformData,
//                     pDescription: e.target.value,
//                   })
//                 }
//                 className="px-4 py-2 border focus:outline-none"
//                 name="description"
//                 id="description"
//                 cols={5}
//                 rows={2}
//               />
//             </div>
//             <div className="flex flex-col mt-4">
//               <label htmlFor="image">Product Images *</label>
//               {editformData.pImages ? (
//                 <div className="flex space-x-1">
//                   {editformData.pImages.map((img, index) => (
//                     <img
//                       key={index}
//                       className="h-16 w-16 object-cover"
//                       src={`${apiURL}/uploads/products/${img}`}
//                       alt={`productImage${index}`}
//                     />
//                   ))}
//                 </div>
//               ) : null}
//               <span className="text-gray-600 text-xs">Must need 2 images</span>
//               <input
//                 onChange={(e) =>
//                   setEditformdata({
//                     ...editformData,
//                     pEditImages: [...e.target.files],
//                   })
//                 }
//                 type="file"
//                 accept=".jpg, .jpeg, .png"
//                 className="px-4 py-2 border focus:outline-none"
//                 id="image"
//                 multiple
//               />
//             </div>
//             <div className="flex flex-col mt-4">
//               <label htmlFor="status">Product Status *</label>
//               <select
//                 onChange={(e) =>
//                   setEditformdata({
//                     ...editformData,
//                     pStatus: e.target.value,
//                   })
//                 }
//                 className="px-4 py-2 border focus:outline-none"
//                 id="status"
//                 value={editformData.pStatus}
//               >
//                 <option value="Active">Active</option>
//                 <option value="Inactive">Inactive</option>
//               </select>
//             </div>
//             <div className="w-1/2 flex flex-col space-y-1">
//               <label htmlFor="category">Product Category *</label>
//               <select
//                 value={editformData.pCategory || ""}
//                 onChange={(e) =>
//                   setEditformdata({
//                     ...editformData,
//                     pCategory: e.target.value,
//                   })
//                 }
//                 name="category"
//                 className="px-4 py-2 border focus:outline-none"
//                 id="category"
//                 required
//               >
//                 <option value="">Select a category</option>
//                 {categories.map((elem) => (
//                   <option key={elem._id} value={elem._id}>
//                     {elem.cName}
//                   </option>
//                 ))}
//               </select>
//             </div>
//             <div className="flex flex-col mt-4">
//               <label htmlFor="quantity">Product Quantity *</label>
//               <input
//                 value={editformData.pQuantity}
//                 onChange={(e) =>
//                   setEditformdata({
//                     ...editformData,
//                     pQuantity: e.target.value,
//                   })
//                 }
//                 type="number"
//                 className="px-4 py-2 border focus:outline-none"
//                 id="quantity"
//               />
//             </div>
//             <div className="flex flex-col mt-4">
//               <label htmlFor="offer">Product Offer *</label>
//               <input
//                 value={editformData.pOffer}
//                 onChange={(e) =>
//                   setEditformdata({
//                     ...editformData,
//                     pOffer: e.target.value,
//                   })
//                 }
//                 type="number"
//                 className="px-4 py-2 border focus:outline-none"
//                 id="offer"
//               />
//             </div>
//             <button
//               type="submit"
//               style={{ background: "#303031" }}
//               className="text-gray-100 px-8 py-2 mt-6 rounded-md"
//             >
//               Save Changes
//             </button>
//           </form>
//         </div>
//       </div>
//       {/* End Modal */}
//     </Fragment>
//   );
// };

// export default EditProductModal;




// import React, { Fragment, useContext, useState, useEffect } from "react";
// import { ProductContext } from "./index";
// import { editProduct, getAllProduct } from "./FetchApi";
// import { getAllCategory } from "../categories/FetchApi";
// import { getAllSizes } from "../sizes/FetchApi";

// const apiURL = process.env.REACT_APP_API_URL;

// const EditProductModal = (props) => {
//   const { data, dispatch } = useContext(ProductContext);
//   const [categories, setCategories] = useState([]);
//   const [sizes, setSizes] = useState([]);
//   const [editformData, setEditformdata] = useState({
//     pId: "",
//     pName: "",
//     pDescription: "",
//     pImages: null,
//     pEditImages: null,
//     pStatus: "",
//     pCategory: "",
//     pSize: "",
//     pQuantity: "",
//     pPrice: "",
//     pOffer: "",
//     error: false,
//     success: false,
//   });

//   // Fetch categories when component mounts
//   useEffect(() => {
//     const fetchCategoryData = async () => {
//       try {
//         let responseData = await getAllCategory();
//         if (responseData.Categories) {
//           setCategories(responseData.Categories);
//         }
//       } catch (error) {
//         console.error("Failed to fetch categories:", error);
//       }
//     };

//     fetchCategoryData();
//   }, []);

//   // Fetch categories when component mounts
//   useEffect(() => {
//     const fetchSizeData = async () => {
//       try {
//         let responseData = await getAllSizes();
//         if (responseData.sizes) {
//           setSizes(responseData.sizes);
//         }
//       } catch (error) {
//         console.error("Failed to fetch sizes:", error);
//       }
//     };

//     fetchSizeData();
//   }, []);

//   // Update form data when editProductModal changes
//   useEffect(() => {
//     if (data.editProductModal) {

//       setEditformdata({
//         pId: data.editProductModal.pId || "",
//         pName: data.editProductModal.pName || "",
//         pDescription: data.editProductModal.pDescription || "",
//         pImages: data.editProductModal.pImages || null,
//         pStatus: data.editProductModal.pStatus || "",
//         pCategory: data.editProductModal.pCategory._id || "",
//         pSize: data.editProductModal.pSize || "",
//         pQuantity: data.editProductModal.pQuantity || 0,
//         pPrice: data.editProductModal.pPrice || "",
//         pOffer: data.editProductModal.pOffer || "",
//         error: false,
//         success: false,
//       });
//     }
//   }, [data.editProductModal]);

//   // Fetch products after editing
//   const fetchData = async () => {
//     try {
//       let responseData = await getAllProduct();
//       if (responseData && responseData.Products) {
//         dispatch({
//           type: "fetchProductsAndChangeState",
//           payload: responseData.Products,
//         });
//       }
//     } catch (error) {
//       console.error("Failed to fetch products:", error);
//     }
//   };

//   const submitForm = async (e) => {
//     e.preventDefault();
//     try {
//       let responseData = await editProduct(editformData);
//       if (responseData.success) {
//         await fetchData();
//         setEditformdata((prevData) => ({
//           ...prevData,
//           success: responseData.success,
//           error: false,
//         }));
//         setTimeout(() => {
//           setEditformdata((prevData) => ({
//             ...prevData,
//             success: false,
//           }));
//         }, 120000);
//         window.location.reload()
//       } else if (responseData.error) {
//         setEditformdata((prevData) => ({
//           ...prevData,
//           error: responseData.error,
//           success: false,
//         }));
//         setTimeout(() => {
//           setEditformdata((prevData) => ({
//             ...prevData,
//             error: false,
//           }));
//         }, 120000);
//       }
//     } catch (error) {
//       console.log(error);
//       setEditformdata((prevData) => ({
//         ...prevData,
//         error: "An unexpected error occurred.",
//         success: false,
//       }));
//       setTimeout(() => {
//         setEditformdata((prevData) => ({
//           ...prevData,
//           error: false,
//         }));
//       }, 120000);
//     }
//   };

//   // Alert function
//   const alert = (msg, type) => (
//     <div className={`bg-${type}-200 py-2 px-4 w-full`}>{msg}</div>
//   );

//   return (
//     <Fragment>
//       {/* Black Overlay */}
//       <div
//         onClick={() => dispatch({ type: "editProductModalClose", payload: false })}
//         className={`${data.editProductModal.modal ? "" : "hidden"} fixed inset-0 z-30 bg-black opacity-50`}
//       />
//       {/* End Black Overlay */}

//       {/* Modal Start */}
//       <div
//         className={`${data.editProductModal.modal ? "" : "hidden"} fixed inset-0 flex items-center justify-center z-30`}
//       >
//         <div style={{ height: "400px" }} className="relative bg-white w-11/12 md:w-3/4 lg:w-1/2 xl:w-1/3 max-h-[90vh] overflow-y-auto shadow-lg p-4 md:p-8">
//           <div className="flex items-center justify-between">
//             <span className="text-left font-semibold text-2xl tracking-wider">Edit Product</span>
//             {/* Close Modal */}
//             <span
//               style={{ background: "#303031" }}
//               onClick={() => dispatch({ type: "editProductModalClose", payload: false })}
//               className="cursor-pointer text-gray-100 py-2 px-2 rounded-full"
//             >
//               <svg
//                 className="w-6 h-6"
//                 fill="none"
//                 stroke="currentColor"
//                 viewBox="0 0 24 24"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
//               </svg>
//             </span>
//           </div>

//           {/* Render alerts */}
//           {editformData.error && alert(editformData.error, "red")}
//           {editformData.success && alert(editformData.success, "green")}

//           <form className="w-full" onSubmit={submitForm}>
//             <div className="flex flex-col md:flex-row md:space-x-4 py-4">
//               <div className="flex-1 flex flex-col space-y-1">
//                 <label htmlFor="name">Product Name *</label>
//                 <input
//                   value={editformData.pName}
//                   onChange={(e) =>
//                     setEditformdata({
//                       ...editformData,
//                       pName: e.target.value,
//                     })
//                   }
//                   className="px-4 py-2 border focus:outline-none"
//                   type="text"
//                 />
//               </div>
//               <div className="flex-1 flex flex-col space-y-1">
//                 <label htmlFor="price">Product Price *</label>
//                 <input
//                   value={editformData.pPrice}
//                   onChange={(e) =>
//                     setEditformdata({
//                       ...editformData,
//                       pPrice: e.target.value,
//                     })
//                   }
//                   type="number"
//                   className="px-4 py-2 border focus:outline-none"
//                   id="price"
//                 />
//               </div>
//             </div>
//             <div className="flex flex-col space-y-2">
//               <label htmlFor="description">Product Description *</label>
//               <textarea
//                 value={editformData.pDescription}
//                 onChange={(e) =>
//                   setEditformdata({
//                     ...editformData,
//                     pDescription: e.target.value,
//                   })
//                 }
//                 className="px-4 py-2 border focus:outline-none"
//                 name="description"
//                 id="description"
//                 cols={5}
//                 rows={2}
//               />
//             </div>
//             <div className="flex flex-col mt-4">
//               <label htmlFor="image">Product Images *</label>
//               {editformData.pImages ? (
//                 <div className="flex space-x-2 overflow-x-auto">
//                   {editformData.pImages.map((img, index) => (
//                     <img
//                       key={index}
//                       className="h-16 w-16 object-cover"
//                       src={`${apiURL}/uploads/products/${img}`}
//                       alt={`productImage${index}`}
//                     />
//                   ))}
//                 </div>
//               ) : null}
//               <span className="text-gray-600 text-xs">Must need 2 images</span>
//               <input
//                 onChange={(e) =>
//                   setEditformdata({
//                     ...editformData,
//                     pEditImages: [...e.target.files],
//                   })
//                 }
//                 type="file"
//                 accept=".jpg, .jpeg, .png"
//                 className="px-4 py-2 border focus:outline-none"
//                 id="image"
//                 multiple
//               />
//             </div>
//             <div className="flex flex-col mt-4">
//               <label htmlFor="status">Product Status *</label>
//               <select
//                 onChange={(e) =>
//                   setEditformdata({
//                     ...editformData,
//                     pStatus: e.target.value,
//                   })
//                 }
//                 className="px-4 py-2 border focus:outline-none"
//                 id="status"
//                 value={editformData.pStatus}
//               >
//                 <option value="Active">Active</option>
//                 <option value="Inactive">Inactive</option>
//               </select>
//             </div>
//             <div className="flex flex-col mt-4">
//               <label htmlFor="category">Product Category *</label>
//               <select
//                 value={editformData.pCategory || ""}
//                 onChange={(e) =>
//                   setEditformdata({
//                     ...editformData,
//                     pCategory: e.target.value,
//                   })
//                 }
//                 name="category"
//                 className="px-4 py-2 border focus:outline-none"
//                 id="category"
//               >
//                 <option value="">Select a Category</option>
//                 {categories.map((elem) => (
//                   <option key={elem._id} value={elem._id}>
//                     {elem.cName}
//                   </option>
//                 ))}
//               </select>
//             </div>
//             <div className="flex flex-col mt-4">
//               <label htmlFor="size">Product Size *</label>
//               <select
//                 value={editformData.pSize || ""}
//                 onChange={(e) =>
//                   setEditformdata({
//                     ...editformData,
//                     pSize: e.target.value,
//                   })
//                 }
//                 name="size"
//                 className="px-4 py-2 border focus:outline-none"
//                 id="size"
//               >
//                 <option value="">Select a Size</option>
//                 {sizes.map((elem, index) => (
//                   <option key={index} value={elem.sName}>
//                     {elem.sName}
//                   </option>
//                 ))}
//               </select>
//             </div>
//             <div className="flex flex-col mt-4">
//               <label htmlFor="quantity">Product Quantity *</label>
//               <input
//                 value={editformData.pQuantity}
//                 onChange={(e) =>
//                   setEditformdata({
//                     ...editformData,
//                     pQuantity: e.target.value,
//                   })
//                 }
//                 type="number"
//                 className="px-4 py-2 border focus:outline-none"
//                 id="quantity"
//               />
//             </div>
//             <div className="flex flex-col mt-4">
//               <label htmlFor="offer">Product Offer *</label>
//               <input
//                 value={editformData.pOffer}
//                 onChange={(e) =>
//                   setEditformdata({
//                     ...editformData,
//                     pOffer: e.target.value,
//                   })
//                 }
//                 type="number"
//                 className="px-4 py-2 border focus:outline-none"
//                 id="offer"
//               />
//             </div>
//             <button
//               type="submit"
//               style={{ background: "#303031" }}
//               className="text-gray-100 px-8 py-2 mt-6 rounded-md"
//             >
//               Save Changes
//             </button>
//           </form>
//         </div>
//       </div>
//       {/* End Modal */}
//     </Fragment>
//   );
// };

// export default EditProductModal;

import React, { Fragment, useContext, useState, useEffect } from "react";
import { ProductContext } from "./index";
import { editProduct, getAllProduct } from "./FetchApi";
import { getAllCategory } from "../categories/FetchApi";
import { getAllSizes } from "../sizes/FetchApi";
import { getAllColors } from "../colors/FetchApi";

const apiURL = process.env.REACT_APP_API_URL;

const EditProductModal = (props) => {
  const { data, dispatch } = useContext(ProductContext);
  const [categories, setCategories] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [colors, setColors] = useState([]);
  const [editformData, setEditformdata] = useState({
    pId: "",
    pName: "",
    pDescription: "",
    pImages: null,
    pEditImages: null,
    pStatus: "",
    pCategory: "",
    pSize: [],
    pColor: [],
    pQuantity: "",
    pPrice: "",
    pOffer: "",
    error: false,
    success: false,
  });

  useEffect(() => {
    const fetchCategoryData = async () => {
      try {
        let responseData = await getAllCategory();
        if (responseData.Categories) {
          setCategories(responseData.Categories);
        }
      } catch (error) {
        console.error("Failed to fetch categories:", error);
      }
    };

    fetchCategoryData();
  }, []);

  useEffect(() => {
    const fetchSizeData = async () => {
      try {
        let responseData = await getAllSizes();
        if (responseData.sizes) {
          setSizes(responseData.sizes);
        }
      } catch (error) {
        console.error("Failed to fetch sizes:", error);
      }
    };

    fetchSizeData();
  }, []);

  useEffect(() => {
    const fetchColorData = async () => {
      try {
        let responseData = await getAllColors();
        if (responseData.colors) {
          setColors(responseData.colors);
        }
      } catch (error) {
        console.error("Failed to fetch colors:", error);
      }
    };

    fetchColorData();
  }, []);

  useEffect(() => {
    if (data.editProductModal) {
      setEditformdata({
        pId: data.editProductModal.pId || "",
        pName: data.editProductModal.pName || "",
        pDescription: data.editProductModal.pDescription || "",
        pImages: data.editProductModal.pImages || null,
        pStatus: data.editProductModal.pStatus || "",
        pCategory: data.editProductModal.pCategory._id || "",
        pSize: data.editProductModal.pSize[0] ? data.editProductModal.pSize[0].split(',') : [],
        pColor: data.editProductModal.pColor[0] ? data.editProductModal.pColor[0].split(',') : [], // Set this to an array
        pQuantity: data.editProductModal.pQuantity || 0,
        pPrice: data.editProductModal.pPrice || "",
        pOffer: data.editProductModal.pOffer || "",
        error: false,
        success: false,
      });
    }
  }, [data.editProductModal]);

  const fetchData = async () => {
    try {
      let responseData = await getAllProduct();
      if (responseData && responseData.Products) {
        dispatch({
          type: "fetchProductsAndChangeState",
          payload: responseData.Products,
        });
      }
    } catch (error) {
      console.error("Failed to fetch products:", error);
    }
  };

  const submitForm = async (e) => {
    e.preventDefault();

    // Join sizes with commas
    const updatedFormData = {
      ...editformData,
      pSize: editformData.pSize.join(','),
      pColor: editformData.pColor.join(','),
    };

    try {
      let responseData = await editProduct(updatedFormData);
      if (responseData.success) {
        await fetchData();
        setEditformdata((prevData) => ({
          ...prevData,
          success: responseData.success,
          error: false,
        }));
        setTimeout(() => {
          setEditformdata((prevData) => ({
            ...prevData,
            success: false,
          }));
        }, 120000);
        window.location.reload();
      } else if (responseData.error) {
        setEditformdata((prevData) => ({
          ...prevData,
          error: responseData.error,
          success: false,
        }));
        setTimeout(() => {
          setEditformdata((prevData) => ({
            ...prevData,
            error: false,
          }));
        }, 120000);
      }
    } catch (error) {
      console.log(error);
      setEditformdata((prevData) => ({
        ...prevData,
        error: "An unexpected error occurred.",
        success: false,
      }));
      setTimeout(() => {
        setEditformdata((prevData) => ({
          ...prevData,
          error: false,
        }));
      }, 120000);
    }
  };

  const alert = (msg, type) => (
    <div className={`bg-${type}-200 py-2 px-4 w-full`}>{msg}</div>
  );

  return (
    <Fragment>
      {/* Black Overlay */}
      <div
        onClick={() => dispatch({ type: "editProductModalClose", payload: false })}
        className={`${data.editProductModal.modal ? "" : "hidden"} fixed inset-0 z-30 bg-black opacity-50`}
      />
      {/* End Black Overlay */}

      {/* Modal Start */}
      <div
        className={`${data.editProductModal.modal ? "" : "hidden"} fixed inset-0 flex items-center justify-center z-30`}
      >
        <div style={{ height: "400px" }} className="relative bg-white w-11/12 md:w-3/4 lg:w-1/2 xl:w-1/3 max-h-[90vh] overflow-y-auto shadow-lg p-4 md:p-8">
          <div className="flex items-center justify-between">
            <span className="text-left font-semibold text-2xl tracking-wider">Edit Product</span>
            {/* Close Modal */}
            <span
              style={{ background: "#303031" }}
              onClick={() => dispatch({ type: "editProductModalClose", payload: false })}
              className="cursor-pointer text-gray-100 py-2 px-2 rounded-full"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </span>
          </div>

          {/* Render alerts */}
          {editformData.error && alert(editformData.error, "red")}
          {editformData.success && alert(editformData.success, "green")}

          <form className="w-full" onSubmit={submitForm}>
            <div className="flex flex-col md:flex-row md:space-x-4 py-4">
              <div className="flex-1 flex flex-col space-y-1">
                <label htmlFor="name">Product Name *</label>
                <input
                  style={{borderRadius:'12px', color:'black'}}
                  value={editformData.pName}
                  onChange={(e) =>
                    setEditformdata({
                      ...editformData,
                      pName: e.target.value,
                    })
                  }
                  className="px-4 py-2 border focus:outline-none"
                  type="text"
                />
              </div>
              <div className="flex-1 flex flex-col space-y-1">
                <label htmlFor="price">Product Price *</label>
                <input
                  style={{borderRadius:'12px', color:'black'}}
                  value={editformData.pPrice}
                  onChange={(e) =>
                    setEditformdata({
                      ...editformData,
                      pPrice: e.target.value,
                    })
                  }
                  type="number"
                  className="px-4 py-2 border focus:outline-none"
                  id="price"
                />
              </div>
            </div>
            <div className="flex flex-col space-y-2">
              <label htmlFor="description">Product Description *</label>
              <textarea
                style={{borderRadius:'12px', color:'black'}}
                value={editformData.pDescription}
                onChange={(e) =>
                  setEditformdata({
                    ...editformData,
                    pDescription: e.target.value,
                  })
                }
                className="px-4 py-2 border focus:outline-none"
                name="description"
                id="description"
                cols={5}
                rows={2}
              />
            </div>
            <div className="flex flex-col mt-4">
              <label htmlFor="image">Product Images *</label>
              {editformData.pImages ? (
                <div className="flex space-x-2 overflow-x-auto">
                  {editformData.pImages.map((img, index) => (
                    <img
                      key={index}
                      className="h-16 w-16 object-cover"
                      src={`${apiURL}/uploads/products/${img}`}
                      alt={`productImage${index}`}
                    />
                  ))}
                </div>
              ) : null}
              <span className="text-gray-600 text-xs">Must need 2 images</span>
              <input
                onChange={(e) =>
                  setEditformdata({
                    ...editformData,
                    pEditImages: [...e.target.files],
                  })
                }
                type="file"
                accept=".jpg, .jpeg, .png"
                className="px-4 py-2 border focus:outline-none"
                id="image"
                multiple
              />
            </div>
            <div className="flex flex-col mt-4">
              <label htmlFor="status">Product Status *</label>
              <select
                style={{borderRadius:'12px', color:'black'}}
                onChange={(e) =>
                  setEditformdata({
                    ...editformData,
                    pStatus: e.target.value,
                  })
                }
                className="px-4 py-2 border focus:outline-none"
                id="status"
                value={editformData.pStatus}
              >
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
              </select>
            </div>
            <div className="flex flex-col mt-4">
              <label htmlFor="category">Product Category *</label>
              <select
                style={{borderRadius:'12px', color:'black'}}
                value={editformData.pCategory || ""}
                onChange={(e) =>
                  setEditformdata({
                    ...editformData,
                    pCategory: e.target.value,
                  })
                }
                name="category"
                className="px-4 py-2 border focus:outline-none"
                id="category"
              >
                <option value="">Select a Category</option>
                {categories.map((elem) => (
                  <option key={elem._id} value={elem._id}>
                    {elem.cName}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex flex-col mt-4">
              <label htmlFor="size">Product Size *</label>
              <div className="flex flex-col space-y-2">
                {sizes.map((elem, index) => (
                  <label key={index} className="flex items-center">
                    <input
                      type="checkbox"
                      checked={editformData.pSize.includes(elem.sName)}
                      onChange={(e) => {
                        const value = elem.sName;
                        setEditformdata((prevData) => {
                          const newSizes = prevData.pSize.includes(value)
                            ? prevData.pSize.filter(size => size !== value)
                            : [...prevData.pSize, value];
                          return { ...prevData, pSize: newSizes };
                        });
                      }}
                    />
                    <span className="ml-2">{elem.sName}</span>
                  </label>
                ))}
              </div>
            </div>
            <div className="flex flex-col mt-4">
              <label htmlFor="color">Product Color *</label>
              <div className="flex flex-col space-y-2">
                {colors.map((elem, index) => (
                  <label key={index} className="flex items-center">
                    <input
                      type="checkbox"
                      checked={editformData.pColor.includes(elem.cName)}
                      onChange={(e) => {
                        const value = elem.cName;
                        setEditformdata((prevData) => {
                          const newColors = prevData.pColor.includes(value)
                            ? prevData.pColor.filter(color => color !== value)
                            : [...prevData.pColor, value];
                          return { ...prevData, pColor: newColors };
                        });
                      }}
                    />
                    <span className="ml-2">{elem.cName}</span>
                  </label>
                ))}
              </div>
            </div>
            <div className="flex flex-col mt-4">
              <label htmlFor="quantity">Product Quantity *</label>
              <input
                style={{borderRadius:'12px', color:'black'}}
                value={editformData.pQuantity}
                onChange={(e) =>
                  setEditformdata({
                    ...editformData,
                    pQuantity: e.target.value,
                  })
                }
                type="number"
                className="px-4 py-2 border focus:outline-none"
                id="quantity"
              />
            </div>
            <div className="flex flex-col mt-4">
              <label htmlFor="offer">Product Offer *</label>
              <input
                style={{borderRadius:'12px', color:'black'}}
                value={editformData.pOffer}
                onChange={(e) =>
                  setEditformdata({
                    ...editformData,
                    pOffer: e.target.value,
                  })
                }
                type="number"
                className="px-4 py-2 border focus:outline-none"
                id="offer"
              />
            </div>
            <button
              type="submit"
              style={{ background: "#303031" }}
              className="text-gray-100 px-8 py-2 mt-6 rounded-md"
            >
              Save Changes
            </button>
          </form>
        </div>
      </div>
      {/* End Modal */}
    </Fragment>
  );
};

export default EditProductModal;