import React from 'react';
import Navber from './Navber';
import Footer from './Footer';

const About = () => {
    return (
        <>
        <Navber/>
            <div style={{ marginTop: '80px', textAlign: 'justify', textJustify: 'inter-word'}} className="min-h-screen bg-gradient-to-r from-blue-50 to-gray-100 flex flex-col items-center p-6">
                <div className="max-w-4xl bg-white shadow-2xl rounded-lg p-8 transition-transform transform hover:scale-105 duration-300 ease-in-out space-y-8">
                    <h1 className="text-4xl font-bold text-center text-gray-800 underline">About Us</h1>

                    <p className="text-gray-700 leading-relaxed">
                        <span style={{color:'red'}}>Zenith Lifestyle</span> is a premium one-stop solution for all your fashion needs. We only believe in providing the best-in-class unisex apparels. We craft garments that are not just the most stylish but also comfortable and durable. Our collection features a wide range of clothing options made from the finest materials, ensuring a luxurious feel and long-lasting wear.
                    </p>

                    <h2 className="text-3xl font-semibold text-gray-800 mt-6">What sets us apart?</h2>
                    <ul className="list-disc list-inside text-gray-600 space-y-4">
                        <li>
                            <strong><span role="img" aria-label="star">🌟</span> Print-on-Demand:</strong> Express your unique style with our custom print-on-demand service. Choose from our extensive design library or create your own personalized artwork. We'll print it directly onto your chosen garment, ensuring a one-of-a-kind piece.
                        </li>
                        <li>
                            <strong><span role="img" aria-label="star">🌟</span> Premium Quality:</strong> We source only the best fabrics and materials to deliver exceptional quality in every piece. Our attention to detail and craftsmanship guarantees garments that are both stylish and comfortable.
                        </li>
                        <li>
                            <strong><span role="img" aria-label="star">🌟</span> Unisex Designs:</strong> Our collection caters to all genders, offering a variety of unisex styles that are both fashionable and versatile. Whether you're looking for a casual tee or a sophisticated dress shirt, we have something for everyone.
                        </li>
                        <li>
                            <strong><span role="img" aria-label="star">🌟</span> India-Wide Delivery:</strong> We're proud to serve customers across India. Enjoy the convenience of doorstep delivery, wherever you may be.
                        </li>
                    </ul>

                    <h2 className="text-3xl font-semibold text-gray-800 mt-6">Why choose <span style={{color:'red'}}>Zenith Lifestyle</span>?</h2>
                    <ul className="list-disc list-inside text-gray-600 space-y-4">
                        <li>
                            <strong><span role="img" aria-label="star">🌟</span> Quality:</strong> Our commitment to quality is unwavering. We strive to provide garments that exceed your expectations.
                        </li>
                        <li>
                            <strong><span role="img" aria-label="star">🌟</span> Customization:</strong> Express your individuality with our custom print-on-demand service.
                        </li>
                        <li>
                            <strong><span role="img" aria-label="star">🌟</span> Convenience:</strong> Shop from the comfort of your home and enjoy hassle-free delivery.
                        </li>
                        <li>
                            <strong><span role="img" aria-label="star">🌟</span> Customer Satisfaction:</strong> Your satisfaction is our top priority. We're always here to assist you with any questions or concerns.
                        </li>
                    </ul>

                    <p className="text-gray-700 leading-relaxed mt-6">
                        Discover the <span style={{color:'red'}}>Zenith Lifestyle</span> difference and elevate your wardrobe today!
                    </p>

                    <div className="mt-8 text-gray-600">
                        <h3 className="font-semibold">Contact Us:</h3>
                        <p>
                            <strong>Support: </strong>
                            <a href="mailto:zenithlifestyle.csa@gmail.com" className="text-blue-500 hover:underline transition duration-200"> zenithlifestyle.csa@gmail.com</a>
                        </p>
                        <p><strong>Address:</strong> Kolkata 700084, West Bengal, India.</p>
                        <p><strong>Business Registration Number:</strong> 001784009583</p>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    );
};

export default About;