import React, { Fragment, useContext, useState } from "react";
import { ColorContext } from "./index";
import { createColor, getAllColors } from "./FetchApi";

const AddColorModal = (props) => {
  const { data, dispatch } = useContext(ColorContext);

  const alert = (msg, type) => (
    <div className={`bg-${type}-200 py-2 px-4 w-full`}>{msg}</div>
  );

  const [fData, setFdata] = useState({
    cName: "",
    success: false,
    error: false,
  });

  const fetchData = async () => {
    let responseData = await getAllColors();
    if (responseData.colors) {
      dispatch({
        type: "fetchColorsAndChangeState",
        payload: responseData.colors,
      });
    }
  };

  if (fData.error || fData.success) {
    setTimeout(() => {
      setFdata({ ...fData, success: false, error: false });
    }, 2000);
  }

  const submitForm = async (e) => {
    e.preventDefault();
    dispatch({ type: "loading", payload: true });

    try {
      let responseData = await createColor(fData);
      if (responseData.success) {
        window.location.reload();
        fetchData();
        setFdata({
          ...fData,
          cName: "",
          success: responseData.success,
          error: false,
        });
        dispatch({ type: "loading", payload: false });
        setTimeout(() => {
          setFdata({ ...fData, success: false, error: false });
        }, 2000);
      } else if (responseData.error) {
        setFdata({ ...fData, success: false, error: responseData.error });
        dispatch({ type: "loading", payload: false });
        setTimeout(() => {
          setFdata({ ...fData, error: false, success: false });
        }, 2000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Fragment>
      {/* Black Overlay */}
      <div
        onClick={() => dispatch({ type: "addColorModal", payload: false })}
        className={`${data.addColorModal ? "" : "hidden"} fixed top-0 left-0 z-30 w-full h-full bg-black opacity-50`}
      />
      {/* Modal Start */}
      <div className={`${data.addColorModal ? "" : "hidden"} fixed inset-0 m-2 sm:m-4 flex items-center z-30 justify-center`}>
        <div className="relative bg-white w-full sm:w-4/6 md:w-3/6 lg:w-2/5 shadow-lg flex flex-col items-center space-y-4 overflow-y-auto px-4 py-4">
          <div className="flex items-center justify-between w-full pt-4">
            <span className="text-left font-semibold text-xl sm:text-2xl tracking-wider">
              Add Color
            </span>
            {/* Close Modal */}
            <span
              style={{ background: "#ffd700", color:'black' }}
              onClick={() => dispatch({ type: "addColorModal", payload: false })}
              className="cursor-pointer text-gray-100 py-2 px-2 rounded-full"
            >
              <svg
                className="w-5 h-5 sm:w-6 sm:h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </span>
          </div>
          {fData.error ? alert(fData.error, "red") : ""}
          {fData.success ? alert(fData.success, "green") : ""}
          <form className="w-full" onSubmit={(e) => submitForm(e)}>
            <div className="flex flex-col space-y-2 py-4">
              <label htmlFor="name" className="text-sm sm:text-base">Color Name</label>
              <input
                onChange={(e) =>
                  setFdata({
                    ...fData,
                    success: false,
                    error: false,
                    cName: e.target.value,
                  })
                }
                style={{borderRadius:'12px',color:'black'}}
                value={fData.cName}
                className="px-4 py-2 border focus:outline-none"
                type="text"
                required
              />
            </div>
            <div className="flex flex-col space-y-2 pb-4 mt-4">
              <button
                style={{ background: "#303031" }}
                type="submit"
                className="bg-gray-800 text-gray-100 rounded-full text-base sm:text-lg font-medium py-2"
              >
                Create Color
              </button>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default AddColorModal;