// import React, { Fragment, useState, useEffect, useContext } from "react";
// import { useParams, useHistory } from "react-router-dom";
// import { ProductDetailsContext } from "./index";
// import { LayoutContext } from "../layout";
// import Submenu from "./Submenu";
// import ProductDetailsSectionTwo from "./ProductDetailsSectionTwo";

// import { getSingleProduct } from "./FetchApi";
// import { cartListProduct } from "../partials/FetchApi";

// import { isWishReq, unWishReq, isWish } from "../home/Mixins";
// import { updateQuantity, slideImage, addToCart, cartList } from "./Mixins";
// import { totalCost } from "../partials/Mixins";

// // Images
// import codOrOnline from "../../../images/product-page/cod.png";
// import courier from "../../../images/product-page/courier.png";

// const apiURL = process.env.REACT_APP_API_URL;

// const ProductDetailsSection = (props) => {
//   let { id } = useParams();

//   const { data, dispatch } = useContext(ProductDetailsContext);
//   const { data: layoutData, dispatch: layoutDispatch } =
//     useContext(LayoutContext); // Layout Context

//   const sProduct = layoutData.singleProductDetail;
//   const [pImages, setPimages] = useState(null);
//   const [count, setCount] = useState(0); // Slide change state

//   const [quantitiy, setQuantitiy] = useState(1); // Increse and decrese quantity state
//   const [, setAlertq] = useState(false); // Alert when quantity greater than stock
//   const history = useHistory();

//   const [wList, setWlist] = useState(
//     JSON.parse(localStorage.getItem("wishList"))
//   ); // Wishlist State Control

//   useEffect(() => {
//     fetchData();
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, []);

//   const fetchData = async () => {
//     dispatch({ type: "loading", payload: true });
//     try {
//       let responseData = await getSingleProduct(id);
//       setTimeout(() => {
//         if (responseData.Product) {
//           layoutDispatch({
//             type: "singleProductDetail",
//             payload: responseData.Product,
//           }); // Dispatch in layout context
//           setPimages(responseData.Product.pImages);
//           dispatch({ type: "loading", payload: false });
//           layoutDispatch({ type: "inCart", payload: cartList() }); // This function change cart in cart state
//         }
//         if (responseData.error) {
//           console.log(responseData.error);
//         }
//       }, 500);
//     } catch (error) {
//       console.log(error);
//     }
//     fetchCartProduct(); // Updating cart total
//   };

//   const fetchCartProduct = async () => {
//     try {
//       let responseData = await cartListProduct();
//       if (responseData && responseData.Products) {
//         layoutDispatch({ type: "cartProduct", payload: responseData.Products }); // Layout context Cartproduct fetch and dispatch
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   if (data.loading) {
//     return (
//       <div className="col-span-2 md:col-span-3 lg:col-span-4 flex items-center justify-center h-screen">
//         <svg
//           className="w-12 h-12 animate-spin text-gray-600"
//           fill="none"
//           stroke="currentColor"
//           viewBox="0 0 24 24"
//           xmlns="http://www.w3.org/2000/svg"
//         >
//           <path
//             strokeLinecap="round"
//             strokeLinejoin="round"
//             strokeWidth="2"
//             d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
//           ></path>
//         </svg>
//       </div>
//     );
//   } else if (!sProduct) {
//     return <div>No product</div>;
//   }

//   const reviews = sProduct.pRatingsReviews;

//   // Calculate the average rating
//   const averageRating = reviews.length
//     ? (
//       reviews.reduce((sum, review) => sum + parseFloat(review.rating), 0) / reviews.length
//     ).toFixed(1)
//     : '0'; // Show 'N/A' if there are no reviews
//   return (
//     <Fragment>
//       <Submenu
//         value={{
//           categoryId: sProduct.pCategory._id,
//           product: sProduct.pName,
//           category: sProduct.pCategory.cName,
//         }}
//       />
//       <section className="m-4 md:mx-12 md:my-6">
//         <div className="grid grid-cols-2 md:grid-cols-12">
//           <div className="hidden md:block md:col-span-1 md:flex md:flex-col md:space-y-4 md:mr-2">
//             <img
//               onClick={(e) =>
//                 slideImage("increase", 0, count, setCount, pImages)
//               }
//               className={`${count === 0 ? "" : "opacity-25"
//                 } cursor-pointer w-20 h-20 object-cover object-center`}
//               src={`${apiURL}/uploads/products/${sProduct.pImages[0]}`}
//               alt="pic"
//             />
//             <img
//               onClick={(e) =>
//                 slideImage("increase", 1, count, setCount, pImages)
//               }
//               className={`${count === 1 ? "" : "opacity-25"
//                 } cursor-pointer w-20 h-20 object-cover object-center`}
//               src={`${apiURL}/uploads/products/${sProduct.pImages[1]}`}
//               alt="pic"
//             />
//           </div>
//           <div className="col-span-2 md:col-span-4">
//             <div className="relative">
//               <img
//                 className="w-full"
//                 src={`${apiURL}/uploads/products/${sProduct.pImages[count]}`}
//                 alt="Pic"
//               />
//               <div className="absolute inset-0 flex justify-between items-center mb-4">
//                 <svg
//                   onClick={(e) =>
//                     slideImage("increase", null, count, setCount, pImages)
//                   }
//                   className="flex justify-center  w-12 h-12 text-gray-700 opacity-25 cursor-pointer hover:text-yellow-700 hover:opacity-100"
//                   fill="none"
//                   stroke="currentColor"
//                   viewBox="0 0 24 24"
//                   xmlns="http://www.w3.org/2000/svg"
//                 >
//                   <path
//                     strokeLinecap="round"
//                     strokeLinejoin="round"
//                     strokeWidth={2}
//                     d="M15 19l-7-7 7-7"
//                   />
//                 </svg>
//                 <svg
//                   onClick={(e) =>
//                     slideImage("increase", null, count, setCount, pImages)
//                   }
//                   className="flex justify-center  w-12 h-12 text-gray-700 opacity-25 cursor-pointer hover:text-yellow-700 hover:opacity-100"
//                   fill="none"
//                   stroke="currentColor"
//                   viewBox="0 0 24 24"
//                   xmlns="http://www.w3.org/2000/svg"
//                 >
//                   <path
//                     strokeLinecap="round"
//                     strokeLinejoin="round"
//                     strokeWidth={2}
//                     d="M9 5l7 7-7 7"
//                   />
//                 </svg>
//               </div>
//             </div>
//           </div>
//           <div className="col-span-2 mt-8 md:mt-0 md:col-span-7 md:ml-6 lg:ml-12">
//             <div className="flex flex-col leading-8">
//               <div className="text-2xl tracking-wider">{sProduct.pName}</div>
//               <div className="flex justify-between items-center">
//                 <span className="text-xl tracking-wider text-yellow-700">
//                   ₹{sProduct.pPrice}{sProduct.pOffer > 0 && (
//                     <span style={{ color: "red", marginLeft: "10px", fontSize: "15px", fontWeight: "500" }}>
//                       {sProduct.pOffer}% Off
//                     </span>
//                   )}
//                 </span>
//                 <span>
//                   <svg
//                     onClick={(e) => isWishReq(e, sProduct._id, setWlist)}
//                     className={`${isWish(sProduct._id, wList) && "hidden"
//                       } w-5 h-5 md:w-6 md:h-6 cursor-pointer text-yellow-700`}
//                     fill="none"
//                     stroke="currentColor"
//                     viewBox="0 0 24 24"
//                     xmlns="http://www.w3.org/2000/svg"
//                   >
//                     <path
//                       strokeLinecap="round"
//                       strokeLinejoin="round"
//                       strokeWidth={2}
//                       d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
//                     />
//                   </svg>
//                   <svg
//                     onClick={(e) => unWishReq(e, sProduct._id, setWlist)}
//                     className={`${!isWish(sProduct._id, wList) && "hidden"
//                       } w-5 h-5 md:w-6 md:h-6 cursor-pointer text-yellow-700`}
//                     fill="currentColor"
//                     viewBox="0 0 20 20"
//                     xmlns="http://www.w3.org/2000/svg"
//                   >
//                     <path
//                       fillRule="evenodd"
//                       d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
//                       clipRule="evenodd"
//                     />
//                   </svg>
//                 </span>
//               </div>
//             </div>
//             <div className="my-4 md:my-6 text-gray-600">
//               <div className="flex items-center space-x-2">
//                 <div className="flex items-center space-x-1">
//                   <svg
//                     className="w-4 h-4 fill-current text-yellow-700"
//                     fill="none"
//                     stroke="currentColor"
//                     viewBox="0 0 24 24"
//                     xmlns="http://www.w3.org/2000/svg"
//                   >
//                     <path
//                       strokeLinecap="round"
//                       strokeLinejoin="round"
//                       strokeWidth={2}
//                       d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
//                     />
//                   </svg>
//                   <span className="text-sm font-medium text-gray-700">
//                     {averageRating}
//                   </span>
//                   <span className="w-1 h-1 mx-1.5 bg-gray-500 rounded-full dark:bg-gray-400"></span>
//                 </div>
//                 <span className="text-sm font-medium text-gray-700">
//                   {sProduct.pRatingsReviews.length > 0 ? sProduct.pRatingsReviews.length : "0"}
//                 </span>
//               </div>
//               <div className="flex items-center space-x-2">
//                 <div className="text-lg tracking-wider text-gray-700 mt-2">
//                   <span>Country Of Origin: India</span>
//                 </div>
//               </div>
//             </div>
//             <hr className="my-2" />
//             <div className="my-4 md:my-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4">
//               {+quantitiy === +sProduct.pQuantity ? (
//                 <span className="text-xs text-red-500 col-span-full">Stock limited</span>
//               ) : null}
//               <div
//                 className={`flex flex-col md:flex-row lg:flex-col justify-between items-center px-4 py-2 border ${+quantitiy === +sProduct.pQuantity ? "border-red-500" : "border-gray-300"
//                   }`}
//               >
//                 <div
//                   className={`font-medium ${quantitiy === sProduct.pQuantity && "text-red-500"
//                     }`}
//                 >
//                   Quantity
//                 </div>
//                 {/* Quantity Button */}
//                 {sProduct.pQuantity !== 0 ? (
//                   <div className="flex items-center space-x-2 mt-2 md:mt-0">
//                     <span
//                       onClick={(e) =>
//                         updateQuantity(
//                           "decrease",
//                           sProduct.pQuantity,
//                           quantitiy,
//                           setQuantitiy,
//                           setAlertq
//                         )
//                       }
//                       className="cursor-pointer"
//                     >
//                       <svg
//                         className="w-5 h-5 fill-current"
//                         fill="currentColor"
//                         viewBox="0 0 20 20"
//                         xmlns="http://www.w3.org/2000/svg"
//                       >
//                         <path
//                           fillRule="evenodd"
//                           d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
//                           clipRule="evenodd"
//                         />
//                       </svg>
//                     </span>
//                     <span className="font-semibold">{quantitiy}</span>
//                     <span
//                       onClick={(e) =>
//                         updateQuantity(
//                           "increase",
//                           sProduct.pQuantity,
//                           quantitiy,
//                           setQuantitiy,
//                           setAlertq
//                         )
//                       }
//                       className="cursor-pointer"
//                     >
//                       <svg
//                         className="w-5 h-5 fill-current"
//                         fill="currentColor"
//                         viewBox="0 0 20 20"
//                         xmlns="http://www.w3.org/2000/svg"
//                       >
//                         <path
//                           fillRule="evenodd"
//                           d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
//                           clipRule="evenodd"
//                         />
//                       </svg>
//                     </span>
//                   </div>
//                 ) : (
//                   <div className="flex items-center space-x-2 mt-2 md:mt-0">
//                     <span>
//                       <svg
//                         className="w-5 h-5 fill-current cursor-not-allowed"
//                         fill="currentColor"
//                         viewBox="0 0 20 20"
//                         xmlns="http://www.w3.org/2000/svg"
//                       >
//                         <path
//                           fillRule="evenodd"
//                           d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
//                           clipRule="evenodd"
//                         />
//                       </svg>
//                     </span>
//                     <span className="font-semibold">{quantitiy}</span>
//                     <span>
//                       <svg
//                         className="w-5 h-5 fill-current cursor-not-allowed"
//                         fill="currentColor"
//                         viewBox="0 0 20 20"
//                         xmlns="http://www.w3.org/2000/svg"
//                       >
//                         <path
//                           fillRule="evenodd"
//                           d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
//                           clipRule="evenodd"
//                         />
//                       </svg>
//                     </span>
//                   </div>
//                 )}
//                 {/* Quantity Button End */}
//               </div>
//               {/* Incart and out of stock button */}
//               <div
//                 onClick={(e) =>
//                   sProduct.pQuantity !== 0 &&
//                   addToCart(
//                     sProduct._id,
//                     quantitiy,
//                     sProduct.pPrice,
//                     layoutDispatch,
//                     setQuantitiy,
//                     setAlertq,
//                     fetchData,
//                     totalCost
//                   )
//                 }
//                 style={{ background: "#303031" }}
//                 className={`px-4 py-2 text-white text-center cursor-pointer uppercase ${layoutData.inCart !== null && layoutData.inCart.includes(sProduct._id)
//                   ? "opacity-75 cursor-not-allowed"
//                   : ""
//                   }`}
//               >
//                 {sProduct.pQuantity !== 0 ? (
//                   layoutData.inCart !== null && layoutData.inCart.includes(sProduct._id)
//                     ? "In cart"
//                     : "Add to cart"
//                 ) : (
//                   layoutData.inCart !== null && layoutData.inCart.includes(sProduct._id)
//                     ? "In cart"
//                     : "Out of stock"
//                 )}
//               </div>
//               {/* Incart and out of stock button End */}
//             </div>

//             {/* Make Pay On Delivery or Online Payment, 48 Hours returnable, AgriCom Delivered, Shipping Through Courier */}
//             <hr className="my-2" />
//             <div className="flex flex-col space-y-4 p-4">
//               <div className="container grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4">
//                 <div className="flex flex-col items-center text-center p-2">
//                   <img src={codOrOnline} alt="Online Payment" className="w-16 h-16 mb-2" />
//                   <span className="text-sm md:text-base">Online Payment</span>
//                 </div>

//                 <div className="flex flex-col items-center text-center p-2">
//                   <img src={courier} alt="Shipping Through Courier" className="w-16 h-16 mb-2" />
//                   <span className="text-sm md:text-base">Shipping Through Courier</span>
//                 </div>

//                 <div className="flex flex-col items-center text-center p-2">
//                   <button
//                     onClick={(e) => history.push('/contact-us')}
//                     className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded text-sm md:text-base">
//                     Got Questions? Just ask!
//                   </button>
//                 </div>
//               </div>
//             </div>
//             <hr className="my-2" />
//           </div>
//         </div>
//       </section>
//       {/* Product Details Section two */}
//       <ProductDetailsSectionTwo />
//     </Fragment>
//   );
// };

// export default ProductDetailsSection;








import React, { Fragment, useState, useEffect, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import { ProductDetailsContext } from "./index";
import { LayoutContext } from "../layout";
import Submenu from "./Submenu";
import ProductDetailsSectionTwo from "./ProductDetailsSectionTwo";

import { getSingleProduct } from "./FetchApi";
import { cartListProduct } from "../partials/FetchApi";

import { isWishReq, unWishReq, isWish } from "../home/Mixins";
import { updateQuantity, slideImage, addToCart, cartList } from "./Mixins";
import { totalCost } from "../partials/Mixins";

// Images
import codOrOnline from "../../../images/product-page/cod.png";
import courier from "../../../images/product-page/courier.png";

const apiURL = process.env.REACT_APP_API_URL;

const ProductDetailsSection = (props) => {
  let { id } = useParams();

  const { data, dispatch } = useContext(ProductDetailsContext);
  const { data: layoutData, dispatch: layoutDispatch } =
    useContext(LayoutContext); // Layout Context

  const sProduct = layoutData.singleProductDetail;
  const [pImages, setPimages] = useState(null);
  const [count, setCount] = useState(0); // Slide change state

  const [quantitiy, setQuantitiy] = useState(1); // Increse and decrese quantity state
  const [, setAlertq] = useState(false); // Alert when quantity greater than stock
  const history = useHistory();
  const [selectedSize, setSelectedSize] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);
  const [sizes, setSizes] = useState([]);
  const [colors, setColors] = useState([]);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertType, setAlertType] = useState(null);
  const [wList, setWlist] = useState(
    JSON.parse(localStorage.getItem("wishList"))
  ); // Wishlist State Control

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    dispatch({ type: "loading", payload: true });
    try {
      let responseData = await getSingleProduct(id);
      setTimeout(() => {
        if (responseData.Product) {
          layoutDispatch({
            type: "singleProductDetail",
            payload: responseData.Product,
          }); // Dispatch in layout context
          setPimages(responseData.Product.pImages);
          dispatch({ type: "loading", payload: false });
          layoutDispatch({ type: "inCart", payload: cartList() }); // This function change cart in cart state
          const sizes = responseData.Product.pSize[0].split(','); // Split sizes
          const colors = responseData.Product.pColor[0].split(',');
          setSizes(sizes);
          setColors(colors);

        }
        if (responseData.error) {
          console.log(responseData.error);
        }
      }, 500);
    } catch (error) {
      console.log(error);
    }
    fetchCartProduct(); // Updating cart total
  };

  const fetchCartProduct = async () => {
    try {
      let responseData = await cartListProduct();
      if (responseData && responseData.Products) {
        layoutDispatch({ type: "cartProduct", payload: responseData.Products }); // Layout context Cartproduct fetch and dispatch
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Handle size selection
  const handleSizeSelect = (size) => {
    setSelectedSize(size);
  };

  // Handle color selection
  const handleColorSelect = (color) => {
    setSelectedColor(color);
  };

  const validateSelections = () => {
    if (!selectedSize) {
      setAlertMessage("Please select a size.");
      setAlertType("red");
      // Clear the alert after 3 seconds
      setTimeout(() => {
        setAlertMessage(null);
      }, 3000);
      return false;
    }
    if (!selectedColor) {
      setAlertMessage("Please select a color.");
      setAlertType("red");
      // Clear the alert after 3 seconds
      setTimeout(() => {
        setAlertMessage(null);
      }, 3000);
      return false;
    }
    return true;
  };

  // In your button's onClick for adding to cart
  const handleAddToCart = (e) => {
    if (validateSelections()) {
      setAlertMessage(null);
      setAlertType(null);
      addToCart(
        sProduct._id,
        quantitiy,
        sProduct.pPrice,
        selectedSize,
        selectedColor,
        layoutDispatch,
        setQuantitiy,
        setAlertq,
        fetchData,
        totalCost
      );
      setAlertMessage("Product added to cart successfully!");
      setAlertType("green");
      setTimeout(() => {
        setAlertMessage(null);
      }, 3000);
    }
  };

  if (data.loading) {
    return (
      <div className="col-span-2 md:col-span-3 lg:col-span-4 flex items-center justify-center h-screen">
        <svg
          className="w-12 h-12 animate-spin text-gray-600"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
          ></path>
        </svg>
      </div>
    );
  } else if (!sProduct) {
    return <div>No product</div>;
  }

  const reviews = sProduct.pRatingsReviews;

  // Calculate the average rating
  const averageRating = reviews.length
    ? (
      reviews.reduce((sum, review) => sum + parseFloat(review.rating), 0) / reviews.length
    ).toFixed(1)
    : '0'; // Show 'N/A' if there are no reviews
  return (
    <Fragment>
      <Submenu
        value={{
          categoryId: sProduct.pCategory._id,
          product: sProduct.pName,
          category: sProduct.pCategory.cName,
        }}
      />
      <section className="m-4 md:mx-12 md:my-6">
        <div className="grid grid-cols-2 md:grid-cols-12">
          <div className="hidden md:block md:col-span-1 md:flex md:flex-col md:space-y-4 md:mr-2">
            <img
              onClick={(e) =>
                slideImage("increase", 0, count, setCount, pImages)
              }
              className={`${count === 0 ? "" : "opacity-25"
                } cursor-pointer w-20 h-20 object-cover object-center`}
              src={`${apiURL}/uploads/products/${sProduct.pImages[0]}`}
              alt="pic"
            />
            <img
              onClick={(e) =>
                slideImage("increase", 1, count, setCount, pImages)
              }
              className={`${count === 1 ? "" : "opacity-25"
                } cursor-pointer w-20 h-20 object-cover object-center`}
              src={`${apiURL}/uploads/products/${sProduct.pImages[1]}`}
              alt="pic"
            />
          </div>
          <div className="col-span-2 md:col-span-4">
            <div className="relative">
              <img
                className="w-full"
                src={`${apiURL}/uploads/products/${sProduct.pImages[count]}`}
                alt="Pic"
              />
              <div className="absolute inset-0 flex justify-between items-center mb-4">
                {/* <svg
                  onClick={(e) =>
                    slideImage("increase", null, count, setCount, pImages)
                  }
                  className="flex justify-center  w-12 h-12 text-gray-700 opacity-25 cursor-pointer hover:text-yellow-700 hover:opacity-100"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
                <svg
                  onClick={(e) =>
                    slideImage("increase", null, count, setCount, pImages)
                  }
                  className="flex justify-center  w-12 h-12 text-gray-700 opacity-25 cursor-pointer hover:text-yellow-700 hover:opacity-100"
                  fill="none"
                  
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 5l7 7-7 7"
                  />
                </svg> */}
                <svg
                  onClick={(e) =>
                    slideImage("increase", null, count, setCount, pImages)
                  }
                  className="flex justify-center w-12 h-12 text-black opacity-25 cursor-pointer hover:text-yellow-700 hover:opacity-100"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
                <svg
                  onClick={(e) =>
                    slideImage("increase", null, count, setCount, pImages)
                  }
                  className="flex justify-center w-12 h-12 text-black opacity-25 cursor-pointer hover:text-yellow-700 hover:opacity-100"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div className="col-span-2 mt-8 md:mt-0 md:col-span-7 md:ml-6 lg:ml-12">
            <div className="flex flex-col leading-8">
              <div className="text-2xl tracking-wider">{sProduct.pName}</div>
              <div className="flex justify-between items-center">
                <span className="text-xl tracking-wider text-yellow-700">
                  ₹<span style={{ color: 'red' }}>{sProduct.pPrice}</span>{sProduct.pOffer > 0 && (
                    <span style={{ marginLeft: "10px", fontSize: "15px", fontWeight: "500" }}>
                      {sProduct.pOffer}% Off
                    </span>
                  )}
                </span>
                <span>
                  <svg
                    onClick={(e) => isWishReq(e, sProduct._id, setWlist)}
                    className={`${isWish(sProduct._id, wList) && "hidden"
                      } w-5 h-5 md:w-6 md:h-6 cursor-pointer text-red-700`}
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                    />
                  </svg>
                  <svg
                    onClick={(e) => unWishReq(e, sProduct._id, setWlist)}
                    className={`${!isWish(sProduct._id, wList) && "hidden"
                      } w-5 h-5 md:w-6 md:h-6 cursor-pointer text-red-700`}
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
              </div>
            </div>
            {/* Size Selection */}
            <div className="my-4">
              <h3 className="font-medium">Select Size:</h3>
              <div className="flex space-x-2">
                {sizes.map((size) => (
                  <button
                    key={size}
                    style={{ color: 'red' }}
                    onClick={() => handleSizeSelect(size)}
                    className={`border rounded px-2 py-1 ${selectedSize === size ? 'bg-yellow-500 text-black' : 'bg-black text-ffd700'}`}
                  >
                    {size}
                  </button>
                ))}
              </div>
            </div>

            {/* Color Selection */}
            <div className="my-4">
              <h3 className="font-medium">Select Color:</h3>
              <div className="flex space-x-2">
                {colors.map((color) => (
                  <button
                    key={color}
                    style={{ color: 'red' }}
                    onClick={() => handleColorSelect(color)}
                    className={`border rounded px-2 py-1 ${selectedColor === color ? 'bg-yellow-500 text-black' : 'bg-black text-ffd700'}`}>
                    {color}
                  </button>
                ))}
              </div>
            </div>
            <div className="my-4 md:my-6 text-gray-600">
              <div className="flex items-center space-x-2">
                <div className="flex items-center space-x-1">
                  <svg
                    className="w-4 h-4 fill-current text-yellow-700"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
                    />
                  </svg>
                  <span className="text-sm font-medium text-gray-700">
                    {averageRating}
                  </span>
                  <span className="w-1 h-1 mx-1.5 bg-gray-500 rounded-full dark:bg-gray-400"></span>
                </div>
                <span className="text-sm font-medium text-gray-700">
                  {sProduct.pRatingsReviews.length > 0 ? sProduct.pRatingsReviews.length : "0"}
                </span>
              </div>
              <div className="flex items-center space-x-2">
                <div className="text-lg tracking-wider text-gray-700 mt-2">
                  <span>Country Of Origin: India</span>
                </div>
              </div>
            </div>
            <div className="md:mx-16 lg:mx-20 xl:mx-24 flex flex-col">
              {alertMessage && (
                <div className={`p-4 rounded ${alertType === "red" ? "bg-black-500 text-white" : "bg-black-500 text-white"}`}>
                  {alertMessage}
                </div>
              )}
            </div>
            <hr className="my-2" />
            <div className="my-4 md:my-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4">
              {+quantitiy === +sProduct.pQuantity ? (
                <span className="text-xs text-red-500 col-span-full">Stock limited</span>
              ) : null}
              <div
                className={`flex flex-col md:flex-row lg:flex-col justify-between items-center px-4 py-2 border ${+quantitiy === +sProduct.pQuantity ? "border-red-500" : "border-gray-300"
                  }`}
              >
                <div
                  className={`font-medium ${quantitiy === sProduct.pQuantity && "text-red-500"
                    }`}
                >
                  Quantity
                </div>
                {/* Quantity Button */}
                {sProduct.pQuantity !== 0 ? (
                  <div className="flex items-center space-x-2 mt-2 md:mt-0">
                    <span
                      onClick={(e) =>
                        updateQuantity(
                          "decrease",
                          sProduct.pQuantity,
                          quantitiy,
                          setQuantitiy,
                          setAlertq
                        )
                      }
                      className="cursor-pointer"
                    >
                      <svg
                        className="w-5 h-5 fill-current"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                    <span className="font-semibold">{quantitiy}</span>
                    <span
                      onClick={(e) =>
                        updateQuantity(
                          "increase",
                          sProduct.pQuantity,
                          quantitiy,
                          setQuantitiy,
                          setAlertq
                        )
                      }
                      className="cursor-pointer"
                    >
                      <svg
                        className="w-5 h-5 fill-current"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                  </div>
                ) : (
                  <div className="flex items-center space-x-2 mt-2 md:mt-0">
                    <span>
                      <svg
                        className="w-5 h-5 fill-current cursor-not-allowed"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                    <span className="font-semibold">{quantitiy}</span>
                    <span>
                      <svg
                        className="w-5 h-5 fill-current cursor-not-allowed"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                  </div>
                )}
                {/* Quantity Button End */}
              </div>
              {/* Incart and out of stock button */}
              <div
                onClick={handleAddToCart}
                style={{ background: "#ffd700" }}
                className={`px-4 py-2 text-black text-center cursor-pointer uppercase ${layoutData.inCart !== null && layoutData.inCart.includes(sProduct._id)
                  ? "opacity-75 cursor-not-allowed"
                  : ""
                  }`}
              >
                {sProduct.pQuantity !== 0 ? (
                  layoutData.inCart !== null && layoutData.inCart.includes(sProduct._id)
                    ? "In cart"
                    : "Add to cart"
                ) : (
                  layoutData.inCart !== null && layoutData.inCart.includes(sProduct._id)
                    ? "In cart"
                    : "Out of stock"
                )}
              </div>
              {/* Incart and out of stock button End */}
            </div>
            <div>
              <ProductDetailsSectionTwo />
            </div>

            {/* Make Pay On Delivery or Online Payment, 48 Hours returnable, AgriCom Delivered, Shipping Through Courier */}
            <hr className="my-2" />
            <div className="flex flex-col space-y-4 p-4">
              <div className="container grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4">
                <div className="flex flex-col items-center text-center p-2">
                  <img style={{ backgroundColor: 'transparent' }} src={codOrOnline} alt="Online Payment" className="w-16 h-16 mb-2" />
                  <span className="text-sm md:text-base">Online Payment</span>
                </div>

                <div className="flex flex-col items-center text-center p-2">
                  <img style={{ backgroundColor: 'transparent' }} src={courier} alt="Shipping Through Courier" className="w-16 h-16 mb-2" />
                  <span className="text-sm md:text-base">Shipping Through Courier</span>
                </div>

                <div className="flex flex-col items-center text-center p-2">
                  <button
                    onClick={(e) => history.push('/contact-us')}
                    style={{ backgroundColor: '#ffd700' }}
                    className="bg-green-500 hover:bg-green-700 text-black font-bold py-2 px-4 rounded text-sm md:text-base">
                    Got Questions? Just ask!
                  </button>
                </div>
              </div>
            </div>
            <hr className="my-2" />
          </div>
        </div>
      </section>
      {/* Product Details Section two */}
      {/* <ProductDetailsSectionTwo /> */}
    </Fragment>
  );
};

export default ProductDetailsSection;