export const sizeState = {
    sizes: [],
    addSizeModal: false,
    editSizeModal: {
      modal: false,
      sId: null,
      name: "",
      status: "",
    },
    loading: false,
  };
  
  export const sizeReducer = (state, action) => {
    switch (action.type) {
      case "fetchSizeAndChangeState":
        return {
          ...state,
          sizes: action.payload,
        };
      case "addSizeModal":
        return {
          ...state,
          addSizeModal: action.payload,
        };
      case "editSizeModalOpen":
        return {
          ...state,
          editSizeModal: {
            modal: true,
            sId: action.sId,
            name: action.name,
            status: action.status,
          },
        };
      case "editSizeModalClose":
        return {
          ...state,
          editSizeModal: {
            modal: false,
            sId: null,
            name: "",
            status: "",
          },
        };
      case "loading":
        return {
          ...state,
          loading: action.payload,
        };
      default:
        return state;
    }
  };  