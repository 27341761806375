import React, { Fragment } from "react";
import { ContactUsProvider } from "./ContactUsQueriesContext";
import AllQueries from "./AllQueries";
import AdminLayout from "../layout";

const ContactUsQueriesPage = () => {
    return (
        <Fragment>
            <ContactUsProvider>
                <AdminLayout>
                    <AllQueries />
                </AdminLayout>
            </ContactUsProvider>
        </Fragment>
    );
};

export default ContactUsQueriesPage;