// import React, { Fragment } from "react";
// import { useHistory } from "react-router-dom";

// const AdminNavber = (props) => {
//   const history = useHistory();

//   const logout = () => {
//     localStorage.removeItem("jwt");
//     localStorage.removeItem("cart");
//     localStorage.removeItem("wishList");
//     window.location.href = "/";
//   };

//   return (
//     <Fragment>
//       <nav className="sticky z-10 flex items-center shadow-md justify-between px-4 py-4 md:px-8 top-0 w-full bg-white">
//         {/*  Large Screen Show  */}
//         <div className="hidden lg:block lg:flex lg:items-center lg:space-x-4 mr-32">
//           <span>
//             <svg
//               className="w-8 h-8 cursor-pointer text-gray-600"
//               fill="none"
//               stroke="currentColor"
//               viewBox="0 0 24 24"
//               xmlns="http://www.w3.org/2000/svg"
//             >
//               <path
//                 strokeLinecap="round"
//                 strokeLinejoin="round"
//                 strokeWidth={2}
//                 d="M4 6h16M4 12h16M4 18h16"
//               />
//             </svg>
//           </span>
//         </div>
//         {/*  Large Screen Show  */}
//         <div className="hidden lg:block">
//           <span
//             onClick={(e) => history.push("/admin/dashboard")}
//             style={{ letterSpacing: "0.70rem" }}
//             className="flex items-left text-center font-bold uppercase text-gray-800 text-2xl cursor-pointer px-2 text-center"
//           >
//             Vaid India
//           </span>
//         </div>
//         {/* Small Screen Show */}
//         <div className="lg:hidden flex items-center">
//           <svg
//             id="hamburgerBtn"
//             className="lg:hidden w-8 h-8 cursor-pointer text-gray-600"
//             fill="none"
//             stroke="currentColor"
//             viewBox="0 0 24 24"
//             xmlns="http://www.w3.org/2000/svg"
//           >
//             <path
//               strokeLinecap="round"
//               strokeLinejoin="round"
//               strokeWidth={2}
//               d="M4 6h16M4 12h16M4 18h16"
//             />
//           </svg>
//           <span
//             onClick={(e) => history.push("/admin/dashboard")}
//             style={{ letterSpacing: "0.10rem" }}
//             className="flex items-left text-center font-bold uppercase text-gray-800 text-2xl cursor-pointer px-2 text-center"
//           >
//             Vaid India
//           </span>
//         </div>
//         {/* Both Screen show */}
//         <div className="flex items-center">
//           {/* Logout Button Dropdown */}
//           <div
//             className="userDropdownBtn hover:bg-gray-200 px-2 py-2 rounded-lg relative"
//             title="Logout"
//           >
//             <svg
//               className="cursor-pointer w-8 h-8 text-gray-600 hover:text-gray-800"
//               fill="none"
//               stroke="currentColor"
//               viewBox="0 0 24 24"
//               xmlns="http://www.w3.org/2000/svg"
//             >
//               <path
//                 strokeLinecap="round"
//                 strokeLinejoin="round"
//                 strokeWidth={2}
//                 d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
//               />
//             </svg>
//             <div className="userDropdown absolute right-0 mt-1 bg-gray-200 rounded">
//               <li className="flex flex-col text-gray-700">
//                 <span
//                   onClick={(e) => history.push("/")}
//                   className="flex space-x-1 py-2 px-8 hover:bg-gray-400 cursor-pointer"
//                 >
//                   <span>
//                     <svg
//                       className="w-6 h-6"
//                       fill="none"
//                       stroke="currentColor"
//                       viewBox="0 0 24 24"
//                       xmlns="http://www.w3.org/2000/svg"
//                     >
//                       <path
//                         strokeLinecap="round"
//                         strokeLinejoin="round"
//                         strokeWidth={2}
//                         d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
//                       />
//                     </svg>
//                   </span>
//                   <span>Shop</span>
//                 </span>
//                 <span className="flex space-x-1 py-2 px-8 hover:bg-gray-400 cursor-pointer">
//                   <span>
//                     <svg
//                       className="w-6 h-6"
//                       fill="none"
//                       stroke="currentColor"
//                       viewBox="0 0 24 24"
//                       xmlns="http://www.w3.org/2000/svg"
//                     >
//                       <path
//                         strokeLinecap="round"
//                         strokeLinejoin="round"
//                         strokeWidth={2}
//                         d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
//                       />
//                       <path
//                         strokeLinecap="round"
//                         strokeLinejoin="round"
//                         strokeWidth={2}
//                         d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
//                       />
//                     </svg>
//                   </span>
//                   <span>Setting</span>
//                 </span>
//                 <span
//                   onClick={(e) => logout()}
//                   className="flex space-x-1 py-2 px-8 hover:bg-gray-400 cursor-pointer"
//                 >
//                   <span>
//                     <svg
//                       className="w-6 h-6"
//                       fill="none"
//                       stroke="currentColor"
//                       viewBox="0 0 24 24"
//                       xmlns="http://www.w3.org/2000/svg"
//                     >
//                       <path
//                         strokeLinecap="round"
//                         strokeLinejoin="round"
//                         strokeWidth={2}
//                         d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
//                       />
//                     </svg>
//                   </span>
//                   <span>Logout</span>
//                 </span>
//               </li>
//             </div>
//           </div>
//         </div>
//         {/* Mobile Navber */}
//         {/* End Mobile Navber */}
//       </nav>
//     </Fragment>
//   );
// };

// export default AdminNavber;





import React, { Fragment, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

const AdminNavber = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768); // Change to 768 for `md` breakpoint
  const history = useHistory();

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const logout = () => {
    localStorage.removeItem("jwt");
    localStorage.removeItem("cart");
    localStorage.removeItem("wishList");
    window.location.href = "/";
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Change to 768 for `md` breakpoint
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Fragment>
      <nav className="sticky z-10 flex items-center shadow-md justify-between px-4 py-4 md:px-8 top-0 w-full bg-white">
        {/* Large Screen Show */}
        <div className={`hidden md:flex md:items-center md:space-x-4 mr-32 ${isMobile ? 'hidden' : ''}`}>
          <span>
            <svg
              className="w-8 h-8 cursor-pointer text-black-600"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </span>
        </div>
        {/* Large Screen Show */}
        <div className={`hidden md:block ${isMobile ? 'hidden' : ''}`}>
          <span
            onClick={() => history.push("/admin/dashboard")}
            style={{ letterSpacing: "0.70rem" }}
            className="flex items-left text-center font-bold uppercase text-gray-800 text-2xl cursor-pointer px-2 text-center"
          >
            Zenith Lifestyle
          </span>
        </div>
        {/* Small Screen Show */}
        <div className={`md:hidden flex items-center ${isMobile ? '' : 'hidden'}`}>
          <svg
            onClick={toggleSidebar}
            className="w-8 h-8 cursor-pointer text-gray-600"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
          <span
            onClick={() => history.push("/admin/dashboard")}
            style={{ letterSpacing: "0.10rem" }}
            className="flex items-left text-center font-bold uppercase text-gray-800 text-2xl cursor-pointer px-2 text-center"
          >
            Zenith Lifestyle
          </span>
        </div>
        {/* Both Screen show */}
        <div className="flex items-center">
          {/* Logout Button Dropdown */}
          <div
            className="userDropdownBtn hover:bg-gray-200 px-2 py-2 rounded-lg relative"
            title="Logout"
          >
            <svg
              className="cursor-pointer w-8 h-8 text-gray-600 hover:text-gray-800"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <div style={{ background: "#ffd700", fontSize: "200" }} className="userDropdown absolute right-0 mt-1 bg-gray-200 rounded">
              <li className="flex flex-col text-gray-700">
                <span
                  onClick={() => history.push("/")}
                  className="flex space-x-1 py-2 px-8 hover:bg-gray-400 cursor-pointer"
                  style={{ backgroundColor: 'transparent', color: 'black', transition: 'background-color 0.3s, color 0.3s' }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.backgroundColor = 'black';
                    e.currentTarget.style.color = '#ffd700';
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.backgroundColor = 'transparent';
                    e.currentTarget.style.color = 'black';
                  }}
                >
                  <span>
                    <svg
                      className="w-6 h-6"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
                      />
                    </svg>
                  </span>
                  <span>Shop</span>
                </span>
                {/* <span className="flex space-x-1 py-2 px-8 hover:bg-gray-400 cursor-pointer">
                  <span>
                    <svg
                      className="w-6 h-6"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                    </svg>
                  </span>
                  <span>Setting</span>
                </span> */}
                <span
                  onClick={() => history.push("/admin/dashboard/contact-queries")}
                  className="flex space-x-1 py-2 px-8 hover:bg-gray-400 cursor-pointer"
                  style={{ backgroundColor: 'transparent', color: 'black', transition: 'background-color 0.3s, color 0.3s' }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.backgroundColor = 'black';
                    e.currentTarget.style.color = '#ffd700';
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.backgroundColor = 'transparent';
                    e.currentTarget.style.color = 'black';
                  }}
                >
                  <span>
                    <svg
                      className="w-6 h-6"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                      />
                    </svg>
                  </span>
                  <span>Queries</span>
                </span>

                <span
                  onClick={() => logout()}
                  className="flex space-x-1 py-2 px-8 hover:bg-gray-400 cursor-pointer"
                  style={{ backgroundColor: 'transparent', color: 'black', transition: 'background-color 0.3s, color 0.3s' }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.backgroundColor = 'black';
                    e.currentTarget.style.color = '#ffd700';
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.backgroundColor = 'transparent';
                    e.currentTarget.style.color = 'black';
                  }}
                >
                  <span>
                    <svg
                      className="w-6 h-6"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                      />
                    </svg>
                  </span>
                  <span>Logout</span>
                </span>
              </li>
            </div>
          </div>
        </div>
      </nav>
      {/* Mobile Sidebar */}
      {isMobile && (
        <div
          id="mobileSidebar"
          className={`overflow-y-auto fixed inset-0 top-0 left-0 bg-white shadow-lg transform ${isSidebarOpen ? "translate-x-0" : "-translate-x-full"
            } transition-transform duration-300 ease-in-out z-50 flex flex-col items-center`}
        >
          <div className="flex justify-end w-full p-4">
            <svg
              onClick={toggleSidebar}
              className="w-8 h-8 cursor-pointer text-gray-600"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </div>
          {/* Sidebar Content Here */}
          <div className="flex flex-col items-center w-full">
            <div
              style={{ marginTop: "40px" }}
              onClick={() => history.push("/admin/dashboard")}
              className="hover:bg-gray-200 cursor-pointer flex items-center p-4 w-full justify-center"
            >
              <span>Dashboard</span>
            </div>
            <div
              onClick={() => history.push("/admin/dashboard/categories")}
              className="hover:bg-gray-200 cursor-pointer flex items-center p-4 w-full justify-center"
            >
              <span>Categories</span>
            </div>
            <div
              onClick={() => history.push("/admin/dashboard/products")}
              className="hover:bg-gray-200 cursor-pointer flex items-center p-4 w-full justify-center"
            >
              <span>Products</span>
            </div>
            <div
              onClick={() => history.push("/admin/dashboard/orders")}
              className="hover:bg-gray-200 cursor-pointer flex items-center p-4 w-full justify-center"
            >
              <span>Orders</span>
            </div>
            <div
              onClick={() => history.push("/admin/dashboard/sizes")}
              className="hover:bg-gray-200 cursor-pointer flex items-center p-4 w-full justify-center"
            >
              <span>Sizes</span>
            </div>
            <div
              onClick={() => history.push("/admin/dashboard/colors")}
              className="hover:bg-gray-200 cursor-pointer flex items-center p-4 w-full justify-center"
            >
              <span>Colors</span>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default AdminNavber;