import React, { Fragment, createContext, useReducer } from "react";
import Layout from "../layout";
import Slider from "./Slider";
import ProductCategory from "./ProductCategory";
import { homeState, homeReducer } from "./HomeContext";
import SingleProduct from "./SingleProduct";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Testimonial from "./Testimonial";
import Hero from "./Hero";
import PrintOnDemand from "./PrintOnDemand";

export const HomeContext = createContext();

const HomeComponent = () => {
  return (
    <Fragment>
      <Hero />
      <Slider />
      {/* Category, Search & Filter Section */}
      <section className="m-4 md:mx-8 md:my-6">
        <ProductCategory />
      </section>
      {/* Product Section */}
      <section>
        <div className="text-center mb-4 md:mb-6 lg:mb-8">
          <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold text-gray-800">
            Our Latest Products
          </h2>
        </div>
      </section>
      <section className="m-4 md:mx-8 md:my-6 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5">
        <SingleProduct />
      </section>
      {/* All Product Section Button */}
      <section className="flex items-center justify-center m-10">
        <Link to="/all-products">
          <button className="px-4 py-2 text-black bg-blue-500 rounded-lg hover:bg-red-600">View All Products</button>
        </Link>
      </section>

      {/* Print On Demand */}
      <section className="py-8 md:py-12">
        <PrintOnDemand/>
      </section>

      <section>
        <Testimonial />
      </section>
    </Fragment>
  );
};

const Home = (props) => {
  const [data, dispatch] = useReducer(homeReducer, homeState);
  return (
    <Fragment>
      <HomeContext.Provider value={{ data, dispatch }}>
        <Layout children={<HomeComponent />} />
      </HomeContext.Provider>
    </Fragment>
  );
};

export default Home;