import React from 'react';
import testimonial_1 from './images/testimonial_1.webp';
import testimonial_2 from './images/testimonial_3.webp';
import testimonial_3 from './images/testimonial_2.webp';
import testimonial_4 from './images/testimonial_4.webp';

const testimonials = [
    {
        name: "Anika Roy",
        comment: "I absolutely love my new T-shirt! The fabric is so soft and comfortable, and the design is perfect. I've gotten so many compliments on it. Highly recommend!",
        image: testimonial_1,  // Imported image for background
    },
    {
        name: "Sourav Mukherjee",
        comment: "I was a bit sceptical about ordering from Zenith, but I'm so glad I did. The quality of the T-shirt is amazing. It's well-made and looks great. Definitely worth the price.",
        image: testimonial_2,  // Imported image for background
    },
    {
        name: "Priyanka Basu",
        comment: "I was worried about the fit, but the T-shirt from Zenith Lifestyle was true to size and fit me perfectly. It's so comfortable and flattering. I'll definitely be ordering more.",
        image: testimonial_3,  // Imported image for background
    },
    {
        name: "Arnab Chatterjee",
        comment: "I love the unique design of my T-shirt. It's different from anything I've seen before, and it's a great conversation starter. The quality is great too.",
        image: testimonial_4,  // Imported image for background
    },
];

const Testimonial = () => {
    return (
        <>
            <div className="bg-black-100 py-0">
                <div className="max-w-6xl mx-auto text-center">
                    <h2 className="text-4xl font-bold text-gray-800 mb-12">Customer Testimonials</h2>
                    
                    {/* Grid for testimonials */}
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8">
                        {testimonials.map((testimonial, index) => (
                            <div key={index} className="shadow-lg rounded-lg p-6 flex flex-col items-center transition-transform transform hover:scale-110 duration-1000 ease-in-out">
                                
                                {/* Use a div to handle background image cover */}
                                <div
                                    style={{
                                        backgroundImage: `url(${testimonial.image})`,  // Set the background image
                                        backgroundColor: '#ffd700',
                                        backgroundSize: 'contain',   // Ensures the background covers the div
                                        backgroundPosition: 'center',  // Centers the image inside the div
                                        backgroundRepeat: 'no-repeat',
                                    }}
                                    className="w-24 h-24 rounded-full border-4 border-red-700 mb-4"
                                >
                                    {/* Optional: Hidden img for accessibility (you can remove this if needed) */}
                                    <img
                                        src={testimonial.image} 
                                        alt={testimonial.name} 
                                        className="hidden" 
                                    />
                                </div>

                                <p className="text-gray-600 text-lg mb-4">"{testimonial.comment}"</p>
                                <em style={{ color: 'red' }} className="text-gray-900 font-semibold">{testimonial.name}</em>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Testimonial