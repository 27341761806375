// import React, { useState, useEffect, Fragment } from 'react';
// import { useHistory } from 'react-router-dom';
// import { getAllProducts } from "../../admin/products/FetchApi";
// import { Navber, Footer, CartModal, FilterCategory } from "../partials";
// import LoginSignup from "../auth/LoginSignup";

// // Helper functions for wishlist management
// const isWish = (productId, wList) => wList && wList.includes(productId);

// const isWishReq = async (e, productId, setWlist) => {
//     e.stopPropagation(); // Prevent event bubbling
//     let currentList = JSON.parse(localStorage.getItem("wishList")) || [];
//     if (!isWish(productId, currentList)) {
//         currentList.push(productId);
//         localStorage.setItem("wishList", JSON.stringify(currentList));
//         setWlist(currentList);
//     }
// };

// const unWishReq = async (e, productId, setWlist) => {
//     e.stopPropagation(); // Prevent event bubbling
//     let currentList = JSON.parse(localStorage.getItem("wishList")) || [];
//     currentList = currentList.filter(id => id !== productId);
//     localStorage.setItem("wishList", JSON.stringify(currentList));
//     setWlist(currentList);
// };

// const apiURL = process.env.REACT_APP_API_URL;

// const AllProductsPage = () => {
//     const [products, setProducts] = useState([]);
//     const [filteredProducts, setFilteredProducts] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);
//     const [wList, setWlist] = useState(JSON.parse(localStorage.getItem("wishList")) || []);
//     const [filterPrice, setFilterPrice] = useState("all");
//     const [searchQuery, setSearchQuery] = useState("");
//     const history = useHistory();

//     useEffect(() => {
//         const fetchProducts = async () => {
//             try {
//                 const { Products } = await getAllProducts();
//                 setProducts(Products);
//                 setFilteredProducts(Products);
//                 setLoading(false);
//             } catch (error) {
//                 setError(error.message);
//                 setLoading(false);
//             }
//         };

//         fetchProducts();
//     }, []);

//     useEffect(() => {
//         let result = products;

//         if (filterPrice !== "all") {
//             // Apply price filter
//             result = result.filter(product => product.pPrice <= filterPrice);
//         }

//         if (searchQuery) {
//             // Apply search filter
//             result = result.filter(product =>
//                 product.pName.toUpperCase().includes(searchQuery.toUpperCase())
//             );
//         }

//         setFilteredProducts(result);
//     }, [filterPrice, searchQuery, products]);

//     if (loading) {
//         return (
//             <div className="col-span-2 md:col-span-3 lg:col-span-4 flex items-center justify-center py-24">
//                 <svg
//                     className="w-12 h-12 animate-spin text-gray-600"
//                     fill="none"
//                     stroke="currentColor"
//                     viewBox="0 0 24 24"
//                     xmlns="http://www.w3.org/2000/svg"
//                 >
//                     <path
//                         strokeLinecap="round"
//                         strokeLinejoin="round"
//                         strokeWidth="2"
//                         d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
//                     ></path>
//                 </svg>
//             </div>
//         );
//     }

//     if (error) {
//         return (
//             <div className="col-span-2 md:col-span-3 lg:col-span-4 flex items-center justify-center py-24 text-red-600">
//                 Error: {error}
//             </div>
//         );
//     }

//     return (
//         <Fragment>
//             <Navber />
//             <LoginSignup />
//             <CartModal />
//             <div className="pt-24 p-4 text-center">
//                 <FilterCategory
//                     onFilterChange={setFilterPrice}
//                     onSearchChange={setSearchQuery}
//                 />
//                 <h1 className="text-xl font-bold mb-4">All Products</h1>
//                 {filteredProducts.length === 0 ? (
//                     <div className="col-span-2 md:col-span-3 lg:col-span-4 flex items-center justify-center py-24 text-2xl">
//                         No products available
//                     </div>
//                 ) : (
//                     <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4">
//                         {filteredProducts.map(product => (
//                             <div
//                                 key={product._id}
//                                 className="relative col-span-1 m-2"
//                                 onClick={() => history.push(`/products/${product._id}`)}
//                             >
//                                 <img
//                                     src={`${apiURL}/uploads/products/${product.pImages[0]}`}
//                                     alt={product.pName}
//                                     className="w-full object-cover object-center cursor-pointer hover:border-black border-2 border-opacity-0 hover:border-opacity-100 transition-all duration-200 ease-in"
//                                 />
//                                 <div className="flex items-center justify-between mt-2">
//                                     <h2 className="text-lg font-semibold truncate">{product.pName}</h2>
//                                     <p className="text-gray-600">₹ <span style={{color:'red'}}>{product.pPrice}</span></p>
//                                 </div>
//                                 <div className="absolute top-0 right-0 mx-2 my-2">
//                                     <svg
//                                         onClick={(e) => isWishReq(e, product._id, setWlist)}
//                                         className={`${isWish(product._id, wList) ? "hidden" : ""
//                                             } w-5 h-5 md:w-6 md:h-6 cursor-pointer text-red-700 transition-all duration-300 ease-in`}
//                                         fill="none"
//                                         stroke="currentColor"
//                                         viewBox="0 0 24 24"
//                                         xmlns="http://www.w3.org/2000/svg"
//                                     >
//                                         <path
//                                             strokeLinecap="round"
//                                             strokeLinejoin="round"
//                                             strokeWidth={2}
//                                             d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
//                                         />
//                                     </svg>
//                                     <svg
//                                         onClick={(e) => unWishReq(e, product._id, setWlist)}
//                                         className={`${!isWish(product._id, wList) ? "hidden" : ""
//                                             } w-5 h-5 md:w-6 md:h-6 cursor-pointer text-red-700 transition-all duration-300 ease-in`}
//                                         fill="currentColor"
//                                         viewBox="0 0 20 20"
//                                         xmlns="http://www.w3.org/2000/svg"
//                                     >
//                                         <path
//                                             fillRule="evenodd"
//                                             d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
//                                             clipRule="evenodd"
//                                         />
//                                     </svg>
//                                 </div>
//                             </div>
//                         ))}
//                     </div>
//                 )}
//             </div>
//             <Footer />
//         </Fragment>
//     );
// };

// export default AllProductsPage;


import React, { useState, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { Navber, Footer, CartModal, FilterCategory } from "../partials";
import LoginSignup from "../auth/LoginSignup";

const apiURL = process.env.REACT_APP_API_URL;

const AllProductsPage = () => {
    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [wList, setWlist] = useState(JSON.parse(localStorage.getItem("wishList")) || []);
    const [filterPrice, setFilterPrice] = useState("all");
    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(1); // Track current page
    const [totalPages, setTotalPages] = useState(1); // Track total pages
    const history = useHistory();

    // Helper functions for wishlist management
    const isWish = (productId, wList) => wList && wList.includes(productId);

    const isWishReq = async (e, productId, setWlist) => {
        e.stopPropagation(); // Prevent event bubbling
        let currentList = JSON.parse(localStorage.getItem("wishList")) || [];
        if (!isWish(productId, currentList)) {
            currentList.push(productId);
            localStorage.setItem("wishList", JSON.stringify(currentList));
            setWlist(currentList);
        }
    };
    const unWishReq = async (e, productId, setWlist) => {
        e.stopPropagation(); // Prevent event bubbling
        let currentList = JSON.parse(localStorage.getItem("wishList")) || [];
        currentList = currentList.filter(id => id !== productId);
        localStorage.setItem("wishList", JSON.stringify(currentList));
        setWlist(currentList);
    };

    // Function to fetch paginated products
    const fetchProducts = async (page = 1, limit = 16) => {
        try {
            const response = await fetch(`${apiURL}/api/product/all-products?page=${page}&limit=${limit}`);
            const { Products, totalPages } = await response.json();

            setProducts(Products);
            setTotalPages(totalPages); // Set total pages for pagination
            setLoading(false);
        } catch (error) {
            setError(error.message);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchProducts(currentPage); // Fetch products for the current page
    }, [currentPage]);

    // Handle filtering and searching
    useEffect(() => {
        let result = products;

        if (filterPrice !== "all") {
            // Apply price filter
            result = result.filter(product => product.pPrice <= filterPrice);
        }

        if (searchQuery) {
            // Apply search filter
            result = result.filter(product =>
                product.pName.toUpperCase().includes(searchQuery.toUpperCase())
            );
        }

        setFilteredProducts(result);
    }, [filterPrice, searchQuery, products]);

    const handlePageChange = (page) => {
        if (page > 0 && page <= totalPages) {
            setCurrentPage(page); // Set current page and fetch data
        }
    };

    if (loading) {
        return (
            <div className="col-span-2 md:col-span-3 lg:col-span-4 flex items-center justify-center py-24">
                <svg className="w-12 h-12 animate-spin text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"></path>
                </svg>
            </div>
        );
    }

    if (error) {
        return (
            <div className="col-span-2 md:col-span-3 lg:col-span-4 flex items-center justify-center py-24 text-red-600">
                Error: {error}
            </div>
        );
    }

    return (
        <Fragment>
            <Navber />
            <LoginSignup />
            <CartModal />
            <div className="pt-24 p-4 text-center">
                <FilterCategory onFilterChange={setFilterPrice} onSearchChange={setSearchQuery} />
                <h1 className="text-xl font-bold mb-4">All Products</h1>
                {filteredProducts.length === 0 ? (
                    <div className="col-span-2 md:col-span-3 lg:col-span-4 flex items-center justify-center py-24 text-2xl">
                        No products available
                    </div>
                ) : (
                    <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4">
                        {filteredProducts.map(product => (
                            <div key={product._id} className="relative col-span-1 m-2" onClick={() => history.push(`/products/${product._id}`)}>
                                <img
                                    src={`${apiURL}/uploads/products/${product.pImages[0]}`}
                                    alt={product.pName}
                                    className="w-full object-cover object-center cursor-pointer hover:border-black border-2 border-opacity-0 hover:border-opacity-100 transition-all duration-200 ease-in"
                                />
                                <div className="flex items-center justify-between mt-2">
                                    <h2 className="text-lg font-semibold truncate">{product.pName}</h2>
                                    <p className="text-gray-600">₹ <span style={{ color: 'red' }}>{product.pPrice}</span></p>
                                </div>
                                <div className="absolute top-0 right-0 mx-2 my-2">
                                    <svg onClick={(e) => isWishReq(e, product._id, setWlist)} className={`${isWish(product._id, wList) ? "hidden" : ""} w-5 h-5 md:w-6 md:h-6 cursor-pointer text-red-700 transition-all duration-300 ease-in`} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                                    </svg>
                                    <svg onClick={(e) => unWishReq(e, product._id, setWlist)} className={`${!isWish(product._id, wList) ? "hidden" : ""} w-5 h-5 md:w-6 md:h-6 cursor-pointer text-red-700 transition-all duration-300 ease-in`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z" clipRule="evenodd" />
                                    </svg>
                                </div>
                            </div>
                        ))}
                    </div>
                )}

                {/* Pagination */}
                <div className="mt-6">
                    <button
                        style={{backgroundColor:'#ffd700', color:'black'}}
                        className="px-4 py-2 rounded-lg mr-2"
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                    >
                        Previous
                    </button>
                    <span style={{color:'red'}} className="text-lg">Page {currentPage} of {totalPages}</span>
                    <button
                        style={{backgroundColor:'#ffd700', color:'black'}}
                        className="px-4 py-2 bg-gray-700 text-white rounded-lg ml-2"
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                    >
                        Next
                    </button>
                </div>
            </div>
            <Footer />
        </Fragment>
    );
};

export default AllProductsPage;