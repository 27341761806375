export const cartList = () => {
  let carts = localStorage.getItem("cart")
    ? JSON.parse(localStorage.getItem("cart"))
    : null;
  let list = [];
  if (carts !== null) {
    for (let cart of carts) {
      list.push(cart.id);
    }
    return list;
  } else {
    return (list = null);
  }
};

export const updateQuantity = (
  type,
  totalQuantitiy,
  quantitiy,
  setQuantitiy,
  setAlertq
) => {
  if (type === "increase") {
    if (quantitiy === totalQuantitiy) {
      setAlertq(true);
    } else {
      setQuantitiy(quantitiy + 1);
    }
  } else if (type === "decrease") {
    if (quantitiy === 1) {
      setQuantitiy(1);
      setAlertq(false);
    } else {
      setQuantitiy(quantitiy - 1);
    }
  }
};

export const slideImage = (type, active, count, setCount, pImages) => {
  if (active === count) {
    return true;
  }
  if (type === "increase") {
    if (count === pImages.length - 1) {
      setCount(0);
    } else if (count < pImages.length) {
      setCount(count + 1);
    }
  }
};

export const inCart = (id) => {
  if (localStorage.getItem("cart")) {
    let cartProducts = JSON.parse(localStorage.getItem("cart"));
    for (let product of cartProducts) {
      if (product.id === id) {
        return true;
      }
    }
  }
  return false;
};

// export const addToCart = (
//   id,
//   quantitiy,
//   price,
//   layoutDispatch,
//   setQuantitiy,
//   setAlertq,
//   fetchData,
//   totalCost
// ) => {
//   let isObj = false;
//   let cart = localStorage.getItem("cart")
//     ? JSON.parse(localStorage.getItem("cart"))
//     : [];
//   if (cart.length > 0) {
//     cart.forEach((item) => {
//       if (item.id === id) {
//         isObj = true;
//       }
//     });
//     if (!isObj) {
//       cart.push({ id, quantitiy, price });
//       localStorage.setItem("cart", JSON.stringify(cart));
//     }
//   } else {
//     cart.push({ id, quantitiy, price });
//     localStorage.setItem("cart", JSON.stringify(cart));
//   }
//   layoutDispatch({ type: "inCart", payload: cartList() });
//   layoutDispatch({ type: "cartTotalCost", payload: totalCost() });
//   setQuantitiy(1);
//   setAlertq(false);
//   fetchData();
// };

// export const addToCart = (
//   id,
//   quantity,
//   price,
//   selectedSize,
//   selectedColor,
//   layoutDispatch,
//   setQuantity,
//   setAlertq,
//   fetchData,
//   totalCost
// ) => {
//   console.log("Adding to cart:");
//   console.log("ID:", id);
//   console.log("Quantity:", quantity);
//   console.log("Price:", price);
//   console.log("Selected Size:", selectedSize);
//   console.log("Selected Color:", selectedColor);
//   let isObj = false;
//   let cart = localStorage.getItem("cart") ? JSON.parse(localStorage.getItem("cart")) : [];
  
//   cart.forEach((item) => {
//     if (item.id === id) {
//       isObj = true;
//       // Update existing item's size, color, and quantity
//       item.size = selectedSize;
//       item.color = selectedColor; 
//       item.quantity += quantity; // Update quantity if item already exists
//     }
//   });

//   // If the item doesn't exist in the cart, push it
//   if (!isObj) {
//     cart.push({ id, quantity, price, size: selectedSize, color: selectedColor });
//   }

//   // Update local storage
//   localStorage.setItem("cart", JSON.stringify(cart));

//   // Dispatch updates to the layout context
//   layoutDispatch({ type: "inCart", payload: cartList() });
//   layoutDispatch({ type: "cartTotalCost", payload: totalCost() });

//   console.log("Updated Cart:", cart);
  
//   // Reset quantity state and alert
//   setQuantity(1);
//   setAlertq(false);
  
//   // Fetch updated data
//   fetchData();
// };

export const addToCart = (
  id,
  quantitiy,
  price,
  selectedSize,
  selectedColor,
  layoutDispatch,
  setQuantitiy,
  setAlertq,
  fetchData,
  totalCost
) => {  
  let isObj = false;
  let cart = localStorage.getItem("cart")
    ? JSON.parse(localStorage.getItem("cart"))
    : [];
  if (cart.length > 0) {
    cart.forEach((item) => {
      if (item.id === id) {
        isObj = true;
      }
    });
    if (!isObj) {
      cart.push({ id, quantitiy, price, size: selectedSize, color: selectedColor });
      localStorage.setItem("cart", JSON.stringify(cart));
    }
  } else {
    cart.push({ id, quantitiy, price, size: selectedSize, color: selectedColor });
    localStorage.setItem("cart", JSON.stringify(cart));
  }
  layoutDispatch({ type: "inCart", payload: cartList() });
  layoutDispatch({ type: "cartTotalCost", payload: totalCost() });
  setQuantitiy(1);
  setAlertq(false);
  fetchData();
};