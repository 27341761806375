import React from 'react';
import heroImage from './images/hero.webp';
import './hero.css';

const Hero = () => {
    return (
        <div style={{ fontFamily: 'Comic Sans' }} className="mt-16 py-16 flex flex-col lg:flex-row items-center justify-between max-w-7xl mx-auto px-6">
            {/* Left Side (Text) */}
            <div className="lg:w-1/2 text-center lg:text-left mb-8 lg:mb-0">
                <h1 className="text-4xl md:text-5xl text-gray-800 leading-tight mb-4">
                    <span style={{ color: 'red', fontStyle: 'italic', fontSize: '80px', letterSpacing:'0.10rem', fontWeight:'bold' }}>Z</span><span style={{color:'red', fontSize:'40px', fontStyle: 'italic', letterSpacing:'0.40rem'}}>enith </span> <span style={{ color: 'red', fontStyle: 'italic', fontSize: '80px', letterSpacing:'0.10rem', fontWeight:'bold' }}>L</span><span style={{color:'red', fontSize:'40px', fontStyle: 'italic', letterSpacing:'0.40rem'}}>ifestyle </span> <br/> 
                    <span style={{ fontSize:'30px', fontStyle: 'italic' }}> Where Style Meets Comfort.</span>
                </h1>
                <p style={{ fontWeight: '500', fontStyle: 'italic' }} className="text-lg mb-6">
                    Discover premium, unisex apparel crafted for both style and durability.
                </p>
                <a
                    href="/all-products"
                    style={{ backgroundColor: '#ffd700', color: 'black' }}
                    className="inline-block text-white px-6 py-2 rounded-full text-lg font-semibold hover:bg-red-700 transition duration-300"
                >
                    Shop Now
                </a>
            </div>

            {/* Right Side (Image) */}
            <div className='box-img flex justify-center lg:justify-end'>
                <div className='content-img transition-transform transform hover:scale-110 duration-1000 ease-in-out'>
                    <img className='bg-black' src={heroImage} alt='zenith'/>
                </div>
            </div>
        </div>
    );
};

export default Hero;