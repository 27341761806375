import React from 'react';
import Navber from './Navber';
import Footer from './Footer';

const Terms = () => {
    return (
        <>
            <Navber />
            <div style={{ marginTop: '80px', textAlign: 'justify', textJustify: 'inter-word' }} className="min-h-screen bg-black-100 flex flex-col items-center p-6">
                <div className="max-w-4xl bg-white shadow-lg rounded-lg p-8 space-y-6">
                    <h1 className="text-3xl font-bold text-center text-gray-800">Terms and Conditions</h1>

                    <div className="text-gray-600 space-y-6">
                        <h2 className="text-xl font-semibold mt-4">1. Acceptance of Terms:</h2>
                        <p>
                            By accessing or using the <span style={{ color: 'red' }}>Zenith Lifestyle</span> website or placing an order, you agree to be bound by these Terms and Conditions. Please read them carefully before proceeding.
                        </p>

                        <h2 className="text-xl font-semibold mt-4">2 User Account:</h2>
                        <p>
                            To create an account on <span style={{ color: 'red' }}>Zenith Lifestyle</span>, you must provide accurate and complete information. You are responsible for maintaining the confidentiality of your account credentials and for any activity that occurs under your account.
                        </p>

                        <h2 className="text-xl font-semibold mt-4">3. Product Information:</h2>
                        <p>
                            The product descriptions and images on our website are for illustrative purposes only. Actual products may vary slightly in color, size, or other characteristics.
                        </p>

                        <h2 className="text-xl font-semibold mt-4">4. Orders and Payment:</h2>
                        <ul className="list-disc list-inside text-gray-600 space-y-4">
                            <li>
                                <strong><span role="img" aria-label="star">🌟</span> Order Placement: </strong> You can place orders online through our website.
                            </li>
                            <li>
                                <strong><span role="img" aria-label="star">🌟</span> Payment: </strong> We accept various payment methods, including credit cards, debit cards, and online payment gateways. All payments are processed securely.
                            </li>
                            <li>
                                <strong><span role="img" aria-label="star">🌟</span> Order Confirmation: </strong> Upon placing an order, you will receive an order confirmation email.
                            </li>
                        </ul>

                        <h2 className="text-xl font-semibold mt-4">5. Shipping and Delivery:</h2>
                        <ul className="list-disc list-inside text-gray-600 space-y-4">
                            <li>
                                <strong><span role="img" aria-label="star">🌟</span> Shipping Charges: </strong> Shipping charges will be calculated based on the shipping address and the weight of the products.
                            </li>
                            <li>
                                <strong><span role="img" aria-label="star">🌟</span> Delivery Time: </strong> Please allow a minimum of 7 business days for delivery.
                            </li>
                            <li>
                                <strong><span role="img" aria-label="star">🌟</span> Delivery Address: </strong> We will ship your order to the address provided during the checkout process. Please ensure that the address is accurate and complete.
                            </li>
                        </ul>
                        
                        <h2 className="text-xl font-semibold mt-4">6. Returns and Exchanges:</h2>
                        <ul className="list-disc list-inside text-gray-600 space-y-4">
                            <li>
                                <strong><span role="img" aria-label="star">🌟</span> Return Window: </strong> You may return or exchange eligible products within 5 days of receiving them.
                            </li>
                            <li>
                                <strong><span role="img" aria-label="star">🌟</span> Return Conditions: </strong> Products must be in their original condition, with all tags attached. Items with damaged or broken tags will not be accepted for return or replacement.
                            </li>
                            <li>
                                <strong><span role="img" aria-label="star">🌟</span> Print on Demand Products: </strong> Print on Demand products cannot be returned or exchanged.
                            </li>
                            <li>
                                <strong><span role="img" aria-label="star">🌟</span> Return Shipping: </strong> You are responsible for the cost of return shipping.
                            </li>
                            <li>
                                <strong><span role="img" aria-label="star">🌟</span> Refunds: </strong> Refunds will be processed to the original payment method within 7 business days of receiving the returned product.
                            </li>
                        </ul>

                        <h2 className="text-xl font-semibold mt-4">7. Damaged or Defective Products:</h2>
                        <p>
                            If you receive a damaged or defective product, please contact our customer support within 5 days of receiving it. We will arrange for a replacement or refund.
                        </p>

                        <h2 className="text-xl font-semibold mt-4">8. Product Availability:</h2>
                        <p>
                            Product availability is subject to change. We reserve the right to cancel or modify orders if the products are out of stock or unavailable.
                        </p>

                        <h2 className="text-xl font-semibold mt-4">9. Intellectual Property:</h2>
                        <p>
                            All content on the <span style={{ color: 'red' }}>Zenith Lifestyle</span> website, including text, images, logos, and trademarks, is the property of <span style={{ color: 'red' }}>Zenith Lifestyle</span> and is protected by copyright and other intellectual property laws.
                        </p>

                        <h2 className="text-xl font-semibold mt-4">10. Privacy Policy:</h2>
                        <p>
                            Your privacy is important to us. We do not reveal your data or breach any privacy policy as per law. You can check the Privacy Policy page for detailed info.
                        </p>

                        <h2 className="text-xl font-semibold mt-4">11. Limitation of Liability:</h2>
                        <p>
                            <span style={{ color: 'red' }}>Zenith Lifestyle</span> shall not be liable for any indirect, incidental, special, or consequential damages arising out of or in connection with your use of the website or the products.
                        </p>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Terms;