// import React, { Fragment, useContext, useEffect } from "react";
// import { ContactUsContext } from "./ContactUsQueriesContext";
// import { getAllQueries } from "./FetchApi";
// import moment from "moment";

// const AllQueries = () => {
//   const { state, dispatch } = useContext(ContactUsContext);
//   const { queries, loading } = state;

//   // Fetch data when the component mounts
//   useEffect(() => {
//     const fetchData = async () => {
//       dispatch({ type: "loading", payload: true });
//       try {
//         let responseData = await getAllQueries();
//         if (responseData && responseData.queries) {
//           dispatch({
//             type: "fetchQueries",
//             payload: responseData.queries,
//           });
//         }
//       } catch (error) {
//         console.error("Error fetching queries:", error);
//       } finally {
//         dispatch({ type: "loading", payload: false });
//       }
//     };

//     fetchData();
//   }, [dispatch]);

//   // Loading spinner
//   if (loading) {
//     return (
//       <div className="flex items-center justify-center p-8">
//         <svg
//           className="w-12 h-12 animate-spin text-gray-600"
//           fill="none"
//           stroke="currentColor"
//           viewBox="0 0 24 24"
//           xmlns="http://www.w3.org/2000/svg"
//         >
//           <path
//             strokeLinecap="round"
//             strokeLinejoin="round"
//             strokeWidth="2"
//             d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
//           />
//         </svg>
//       </div>
//     );
//   }

//   return (
//     <Fragment>
//       <div className="col-span-1 overflow-auto bg-white shadow-lg p-4">
//         <table className="table-auto border w-full my-2">
//           <thead>
//             <tr>
//               <th className="px-4 py-2 border">Name</th>
//               <th className="px-4 py-2 border">Email</th>
//               <th className="px-4 py-2 border">Query</th>
//               <th className="px-4 py-2 border">Created at</th>
//               <th className="px-4 py-2 border">Actions</th>
//             </tr>
//           </thead>
//           <tbody>
//             {queries && queries.length > 0 ? (
//               queries.map((query, key) => (
//                 <QueryRow query={query} key={key} />
//               ))
//             ) : (
//               <tr>
//                 <td colSpan="5" className="text-xl text-center font-semibold py-8">
//                   No Queries Found
//                 </td>
//               </tr>
//             )}
//           </tbody>
//         </table>
//         <div className="text-sm text-gray-600 mt-2">
//           Total {queries && queries.length} queries found
//         </div>
//       </div>
//     </Fragment>
//   );
// };

// // Row component to display each query
// const QueryRow = ({ query }) => {
//   const viewQueryDetails = () => {
//     // Open a new tab with the query details
//     const queryUrl = `/query/${query._id}`;
//     window.open(queryUrl, '_blank');
//   };

//   return (
//     <tr>
//       <td className="p-2 text-left">{query.name}</td>
//       <td className="p-2 text-center">{query.email}</td>
//       <td className="p-2 text-left">{query.message}</td>
//       <td className="p-2 text-center">
//         {moment(query.createdAt).format("lll")}
//       </td>
//       <td className="p-2 text-center">
//         {/* "View" button */}
//         <button
//           className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
//           onClick={viewQueryDetails}
//         >
//           View
//         </button>
//       </td>
//     </tr>
//   );
// };

// export default AllQueries;


// import React, { Fragment, useContext, useEffect } from "react";
// import { ContactUsContext } from "./ContactUsQueriesContext";
// import { getAllQueries, getDetailQueries } from "./FetchApi";
// import moment from "moment";

// const AllQueries = () => {
//   const { state, dispatch } = useContext(ContactUsContext);
//   const { queries, loading } = state;

//   // Fetch data when the component mounts
//   useEffect(() => {
//     const fetchData = async () => {
//       dispatch({ type: "loading", payload: true });
//       try {
//         let responseData = await getAllQueries();
//         if (responseData && responseData.queries) {
//           dispatch({
//             type: "fetchQueries",
//             payload: responseData.queries,
//           });
//         }
//       } catch (error) {
//         console.error("Error fetching queries:", error);
//       } finally {
//         dispatch({ type: "loading", payload: false });
//       }
//     };

//     fetchData();
//   }, [dispatch]);

//   // Loading spinner
//   if (loading) {
//     return (
//       <div className="flex items-center justify-center p-8">
//         <svg
//           className="w-12 h-12 animate-spin text-gray-600"
//           fill="none"
//           stroke="currentColor"
//           viewBox="0 0 24 24"
//           xmlns="http://www.w3.org/2000/svg"
//         >
//           <path
//             strokeLinecap="round"
//             strokeLinejoin="round"
//             strokeWidth="2"
//             d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
//           />
//         </svg>
//       </div>
//     );
//   }

//   return (
//     <Fragment>
//       <div className="col-span-1 overflow-auto bg-white shadow-lg p-4">
//         <table className="table-auto border w-full my-2">
//           <thead>
//             <tr>
//               <th className="px-4 py-2 border">Name</th>
//               <th className="px-4 py-2 border">Email</th>
//               <th className="px-4 py-2 border">Query</th>
//               <th className="px-4 py-2 border">Created at</th>
//               <th className="px-4 py-2 border">Actions</th>
//             </tr>
//           </thead>
//           <tbody>
//             {queries && queries.length > 0 ? (
//               queries.map((query, key) => (
//                 <QueryRow query={query} key={key} />
//               ))
//             ) : (
//               <tr>
//                 <td colSpan="5" className="text-xl text-center font-semibold py-8">
//                   No Queries Found
//                 </td>
//               </tr>
//             )}
//           </tbody>
//         </table>
//         <div className="text-sm text-gray-600 mt-2">
//           Total {queries && queries.length} queries found
//         </div>
//       </div>
//     </Fragment>
//   );
// };

// // Row component to display each query
// const QueryRow = ({ query }) => {
//     const viewQueryDetails = async () => {
//       try {
//         // Fetch query details using the correct queryId
//         const queryDetails = await getDetailQueries(query._id);

//         // If successful, open a new tab and display the query details
//         const queryUrl = `/query/${query._id}`;
//         const newTab = window.open(queryUrl, '_blank');

//         if (newTab) {
//           newTab.document.write(`<h1>Query Details</h1><p>${JSON.stringify(queryDetails)}</p>`);
//         }
//       } catch (error) {
//         console.error("Error fetching query details:", error);
//       }
//     };

//   return (
//     <tr>
//       <td className="p-2 text-left">{query.name}</td>
//       <td className="p-2 text-center">{query.email}</td>
//       <td className="p-2 text-left">{query.message}</td>
//       <td className="p-2 text-center">
//         {moment(query.createdAt).format("lll")}
//       </td>
//       <td className="p-2 text-center">
//         {/* "View" button */}
//         <button
//           className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
//           onClick={viewQueryDetails}
//         >
//           View
//         </button>
//       </td>
//     </tr>
//   );
// };

// export default AllQueries;






import React, { Fragment, useContext, useEffect } from "react";
import { ContactUsContext } from "./ContactUsQueriesContext";
import { getAllQueries, getDetailQueries } from "./FetchApi";
import moment from "moment";

const AllQueries = () => {
    const { state, dispatch } = useContext(ContactUsContext);
    const { queries, loading } = state;

    // Fetch data when the component mounts
    useEffect(() => {
        const fetchData = async () => {
            dispatch({ type: "loading", payload: true });
            try {
                let responseData = await getAllQueries();
                if (responseData && responseData.queries) {
                    dispatch({
                        type: "fetchQueries",
                        payload: responseData.queries,
                    });
                }
            } catch (error) {
                console.error("Error fetching queries:", error);
            } finally {
                dispatch({ type: "loading", payload: false });
            }
        };

        fetchData();
    }, [dispatch]);

    // Loading spinner
    if (loading) {
        return (
            <div className="flex items-center justify-center p-8">
                <svg
                    className="w-12 h-12 animate-spin text-gray-600"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                    />
                </svg>
            </div>
        );
    }

    return (
        <Fragment>
            <div className="col-span-1 overflow-auto bg-white shadow-lg p-4">
                <table className="table-auto border w-full my-2">
                    <thead>
                        <tr>
                            <th className="px-4 py-2 border">Name</th>
                            <th className="px-4 py-2 border">Created at</th>
                            <th className="px-4 py-2 border">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {queries && queries.length > 0 ? (
                            queries.map((query, key) => (
                                <QueryRow query={query} key={key} />
                            ))
                        ) : (
                            <tr>
                                <td colSpan="5" className="text-xl text-center font-semibold py-8">
                                    No Queries Found
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
                <div className="text-sm text-gray-600 mt-2">
                    Total {queries && queries.length} queries found
                </div>
            </div>
        </Fragment>
    );
};

// Row component to display each query
const QueryRow = ({ query }) => {
    const viewQueryDetails = async () => {
        try {
            // Fetch query details using the correct queryId
            const queryDetails = await getDetailQueries(query._id);

            // If successful, open a new tab and display the query details
            const queryUrl = `/query/${query._id}`;
            const newTab = window.open(queryUrl, '_blank');

            if (newTab) {
                // Display the query details in the new tab with enhanced responsive styling
                newTab.document.write(`
              <html>
                <head>
                  <title>Query Details</title>
                  <style>
                    body {
                      background-color: black;
                      font-family: 'Arial', sans-serif;
                      margin: 0;
                      padding: 0;
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                      justify-content: center;
                      height: 100vh;
                      overflow: auto;
                    }
                    h1 {
                      color: #ffd700;
                      font-size: 2.5rem;
                      margin-bottom: 30px;
                      text-align: center;
                    }
                    .query-details {
                      background-color: #ffd700;
                      border-radius: 10px;
                      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
                      padding: 20px;
                      max-width: 900px;
                      width: 90%;
                      text-align: left;
                      margin: 20px;
                    }
                    .query-details p {
                      font-size: 1.125rem;
                      margin: 12px 0;
                      line-height: 1.8;
                      color: black;
                    }
                    .query-details strong {
                      color: black;
                      font-weight: bold;
                    }
                    
                    /* Responsive Design */
                    /* Mobile (small devices) */
                    @media (max-width: 480px) {
                      h1 {
                        font-size: 1.8rem;
                      }
                      .query-details {
                        padding: 15px;
                      }
                      .query-details p {
                        font-size: 1rem;
                      }
                    }
      
                    /* Tablets (medium devices) */
                    @media (max-width: 768px) {
                      h1 {
                        font-size: 2rem;
                      }
                      .query-details {
                        padding: 20px;
                      }
                    }
      
                    /* Desktop (large devices) */
                    @media (min-width: 1024px) {
                      h1 {
                        font-size: 2.5rem;
                      }
                      .query-details {
                        padding: 25px;
                        max-width: 900px;
                      }
                    }
      
                    /* Wide Screen (extra large devices) */
                    @media (min-width: 1440px) {
                      h1 {
                        font-size: 3rem;
                      }
                      .query-details {
                        padding: 40px;
                        max-width: 1200px;
                      }
                    }
                  </style>
                </head>
                <body>
                  <h1>Query Details</h1>
                  <div class="query-details">
                    <p><strong>Name:</strong> ${queryDetails.query.name}</p>
                    <p><strong>Email:</strong> ${queryDetails.query.email}</p>
                    <p><strong>Message:</strong> ${queryDetails.query.message}</p>
                    <p><strong>Created At:</strong> ${moment(queryDetails.query.createdAt).format("lll")}</p>
                  </div>
                </body>
              </html>
            `);
            }
        } catch (error) {
            console.error("Error fetching query details:", error);
        }
    };

    return (
        <tr>
            <td className="p-2 text-left">{query.name}</td>
            <td className="p-2 text-center">
                {moment(query.createdAt).format("lll")}
            </td>
            <td className="p-2 text-center">
                {/* "View" button */}
                <button
                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
                    onClick={viewQueryDetails}
                >
                    View
                </button>
            </td>
        </tr>
    );
};

export default AllQueries;