import axios from "axios"; // Ensure you have axios imported
const apiURL = process.env.REACT_APP_API_URL;

const BearerToken = () =>
    localStorage.getItem("jwt")
        ? JSON.parse(localStorage.getItem("jwt")).token
        : false;
const Headers = () => {
    return {
        headers: {
            token: `Bearer ${BearerToken()}`,
        },
    };
};

export const getAllColors = async () => {
  try {
    let res = await axios.get(`${apiURL}/api/colors/all-colors`, Headers());
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const createColor = async ({ cName }) => {
  let data = { cName };
  try {
    let res = await axios.post(`${apiURL}/api/colors/add-color`, data, Headers());
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const editColor = async (cId, { cName, sStatus }) => {
    try {
        const response = await axios.post(`${apiURL}/api/colors/edit-color`, { cId, cName, sStatus }, Headers());
        return response.data; // Return the response data
    } catch (error) {
        console.error("Error in editColor:", error);
        throw error; // Rethrow the error to handle it in submitForm
    }
};

// New function for deleting a size
export const deleteColor = async (cId) => {
  try {
    let res = await axios.post(`${apiURL}/api/colors/delete-color`, { cId }, Headers());
    return res.data;
  } catch (error) {
    console.log(error);
    return { error: "Failed to delete color" }; // Optional: return an error message
  }
};