import React from 'react';
import pod from './images/pod.webp'
import './hero.css';

const PrintOnDemand = () => {
    return (
        <div style={{ fontFamily: 'helvetica' }} className="py-0 flex flex-col lg:flex-row items-center justify-between max-w-7xl mx-auto px-6">
            {/* Left Side (Text) */}
            <div className="lg:w-1/2 text-center lg:text-left mb-8 lg:mb-0">
                <h3 className="text-4xl md:text-5xl font-bold text-gray-800 leading-tight mb-4">
                    <span style={{ color: 'red' }}>Print on Demand </span>
                </h3>
                <p style={{ fontWeight: '500' }} className="text-lg mb-6">
                    Express your unique style with our custom print-on-demand service. Choose from our extensive design library or create your own personalized artwork. We’ll print it directly onto your chosen garment.
                </p>
                <p className="text-base text-gray-600">
                    Click <a href="mailto:zenithlifestyle.csa@gmail.com?subject=Print on Demand Design Inquiry&body=Please find attached my design and details on how I'd like it printed."
                        className="text-blue-600 underline hover:text-blue-800 transition-all duration-200 ease-in-out">
                        here
                    </a> to send us your design and tell us how you want to print your design.
                </p>
            </div>

            {/* Right Side (Image) */}
            <div className='box-img flex justify-center lg:justify-end'>
                <div className='content-img transition-transform transform hover:scale-110 duration-1000 ease-in-out'>
                    <img className='bg-black' src={pod} alt='zenith' />
                </div>
            </div>
        </div>
    )
}

export default PrintOnDemand