import axios from "axios";

const apiURL = process.env.REACT_APP_API_URL;
const BearerToken = () =>
    localStorage.getItem("jwt")
        ? JSON.parse(localStorage.getItem("jwt")).token
        : false;
const Headers = () => {
    return {
        headers: {
            token: `Bearer ${BearerToken()}`,
        },
    };
};

// Helper function to get all contact queries
export const getAllQueries = async () => {
    try {
        let res = await axios.get(`${apiURL}/api/contact-us/all-queries`, Headers());
        return res.data;
    } catch (error) {
        console.error("Error fetching queries:", error);
    }
};

export const getDetailQueries = async (queryId) => {
    try {
      // Ensure the queryId is passed correctly
      if (!queryId) {
        throw new Error("Query ID is required.");
      }
  
      let res = await axios.get(`${apiURL}/api/contact-us/query-detail/${queryId}`, Headers());
      return res.data;
    } catch (error) {
      console.error("Error fetching query:", error);
      throw error;  // Rethrow error to handle it in the component
    }
  };