export const colorState = {
    colors: [],
    addColorModal: false,
    editColorModal: {
      modal: false,
      cId: null,
      name: "",
      status: "",
    },
    loading: false,
  };
  
  export const colorReducer = (state, action) => {
    switch (action.type) {
      case "fetchColorAndChangeState":
        return {
          ...state,
          colors: action.payload,
        };
      case "addColorModal":
        return {
          ...state,
          addColorModal: action.payload,
        };
      case "editColorModalOpen":
        return {
          ...state,
          editColorModal: {
            modal: true,
            cId: action.cId,
            name: action.name,
            status: action.status,
          },
        };
      case "editColorModalClose":
        return {
          ...state,
          editColorModal: {
            modal: false,
            cId: null,
            name: "",
            status: "",
          },
        };
      case "loading":
        return {
          ...state,
          loading: action.payload,
        };
      default:
        return state;
    }
  };