// import React, { useState } from "react";

// const CategorySearch = ({ onFilterChange, onSearchChange }) => {
//     const [range, setRange] = useState(0);
//     const [search, setSearch] = useState("");
//     const [filterDropdown, setFilterDropdown] = useState(false);
//     const [searchDropdown, setSearchDropdown] = useState(false);

//     const handleFilterChange = (e) => {
//         const value = e.target.value;
//         setRange(value);
//         onFilterChange(value); // Pass the value to the parent component
//     };

//     const handleSearch = (e) => {
//         const value = e.target.value;
//         setSearch(value);
//         onSearchChange(value); // Pass the value to the parent component
//     };

//     const clearFilter = () => {
//         setRange(0);
//         onFilterChange(0); // Notify the parent component that the filter has been cleared
//         window.location.reload(); // Navigate to /all-products
//     };

//     const closeFilterDropdown = () => setFilterDropdown(false);
//     const closeSearchDropdown = () => setSearchDropdown(false);

//     return (
//         <div className="my-4">
//             <div className="flex justify-between font-medium">

//                 <div className="flex space-x-2">
//                     <div
//                         onClick={() => setFilterDropdown(!filterDropdown)}
//                         className="flex items-center space-x-1 cursor-pointer"
//                     >
//                         <span className="text-md md:text-lg">Filter</span>
//                         <svg
//                             className={`w-4 h-4 ${filterDropdown ? "text-yellow-700" : "text-gray-700"}`}
//                             fill="none"
//                             stroke="currentColor"
//                             viewBox="0 0 24 24"
//                             xmlns="http://www.w3.org/2000/svg"
//                         >
//                             <path
//                                 strokeLinecap="round"
//                                 strokeLinejoin="round"
//                                 strokeWidth="2"
//                                 d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
//                             ></path>
//                         </svg>
//                     </div>
//                     <span>/</span>
//                     <div
//                         onClick={() => setSearchDropdown(!searchDropdown)}
//                         className="flex items-center space-x-1 cursor-pointer"
//                     >
//                         <span className="text-md md:text-lg">Search</span>
//                         <svg
//                             className={`w-4 h-4 ${searchDropdown ? "text-yellow-700" : "text-gray-700"}`}
//                             fill="none"
//                             stroke="currentColor"
//                             viewBox="0 0 24 24"
//                             xmlns="http://www.w3.org/2000/svg"
//                         >
//                             <path
//                                 strokeLinecap="round"
//                                 strokeLinejoin="round"
//                                 strokeWidth="2"
//                                 d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
//                             ></path>
//                         </svg>
//                     </div>
//                 </div>
//             </div>

//             {filterDropdown && (
//                 <div className="my-4 relative flex flex-col items-end">
//                     <button
//                         onClick={closeFilterDropdown}
//                         className="absolute top-2 right-2 text-gray-700 hover:text-gray-900"
//                     >
//                         <svg
//                             className="w-4 h-4"
//                             fill="none"
//                             stroke="currentColor"
//                             viewBox="0 0 24 24"
//                             xmlns="http://www.w3.org/2000/svg"
//                         >
//                             <path
//                                 strokeLinecap="round"
//                                 strokeLinejoin="round"
//                                 strokeWidth="2"
//                                 d="M6 18L18 6M6 6l12 12"
//                             ></path>
//                         </svg>
//                     </button>
//                     <hr />
//                     <div className="w-full flex flex-col">
//                         <div className="font-medium py-2">Filter by price</div>
//                         <div className="flex justify-between items-center">
//                             <div className="flex flex-col space-y-2 w-2/3 lg:w-2/4">
//                                 <label htmlFor="priceRange" className="text-sm">
//                                     Price from 0 to{" "}
//                                     <span className="font-semibold text-yellow-700">{range}₹</span>{" "}
//                                 </label>
//                                 <input
//                                     id="priceRange"
//                                     type="range"
//                                     min="0"
//                                     max="10000"
//                                     step="10"
//                                     value={range}
//                                     onChange={handleFilterChange}
//                                     className="slider"
//                                 />
//                                 <button
//                                     onClick={clearFilter}
//                                     className="mt-2 px-4 py-2 bg-gradient-to-r from-red-500 to-red-600 text-red-400 rounded-lg shadow-lg transition-transform transform hover:scale-105 hover:shadow-xl focus:outline-none focus:ring-2 focus:ring-red-300"
//                                 >
//                                     Clear Filter
//                                 </button>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             )}

//             {searchDropdown && (
//                 <div className="my-4 relative flex items-center">
//                     <input
//                         type="text"
//                         value={search}
//                         onChange={handleSearch}
//                         className="px-4 text-xl py-4 focus:outline-none"
//                         placeholder="Search products..."
//                     />
//                     <button
//                         onClick={closeSearchDropdown}
//                         className="absolute top-2 right-2 text-gray-700 hover:text-gray-900"
//                     >
//                         <svg
//                             className="w-4 h-4"
//                             fill="none"
//                             stroke="currentColor"
//                             viewBox="0 0 24 24"
//                             xmlns="http://www.w3.org/2000/svg"
//                         >
//                             <path
//                                 strokeLinecap="round"
//                                 strokeLinejoin="round"
//                                 strokeWidth="2"
//                                 d="M6 18L18 6M6 6l12 12"
//                             ></path>
//                         </svg>
//                     </button>
//                 </div>
//             )}
//         </div>
//     );
// };

// export default CategorySearch;





import React, { useState } from "react";

const CategorySearch = ({ onFilterChange, onSearchChange }) => {
    const [range, setRange] = useState(0);
    const [search, setSearch] = useState("");
    const [filterDropdown, setFilterDropdown] = useState(false);
    const [searchDropdown, setSearchDropdown] = useState(false);

    const handleFilterChange = (e) => {
        const value = Number(e.target.value); // Ensure the value is a number
        setRange(value);
        onFilterChange(value); // Pass the value to the parent component
    };

    const handleSearch = (e) => {
        const value = e.target.value;
        setSearch(value);
        onSearchChange(value); // Pass the value to the parent component
    };

    const clearFilter = () => {
        setRange(0);
        onFilterChange(0); // Notify the parent component that the filter has been cleared
        window.location.reload()// No page reload needed here
    };

    const closeFilterDropdown = () => setFilterDropdown(false);
    const closeSearchDropdown = () => setSearchDropdown(false);

    return (
        <div className="my-4">
            <div className="flex justify-between font-medium">

                <div className="flex space-x-2">
                    <div
                        onClick={() => setFilterDropdown(!filterDropdown)}
                        className="flex items-center space-x-1 cursor-pointer"
                    >
                        <span className="text-md md:text-lg">Filter</span>
                        <svg
                            className={`w-4 h-4 ${filterDropdown ? "text-yellow-700" : "text-gray-700"}`}
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
                            ></path>
                        </svg>
                    </div>
                    <span>/</span>
                    <div
                        onClick={() => setSearchDropdown(!searchDropdown)}
                        className="flex items-center space-x-1 cursor-pointer"
                    >
                        <span className="text-md md:text-lg">Search</span>
                        <svg
                            className={`w-4 h-4 ${searchDropdown ? "text-yellow-700" : "text-gray-700"}`}
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                            ></path>
                        </svg>
                    </div>
                </div>
            </div>

            {filterDropdown && (
                <div className="my-4 relative flex flex-col items-end">
                    <button
                        onClick={closeFilterDropdown}
                        className="absolute top-2 right-2 text-gray-700 hover:text-gray-900"
                    >
                        <svg
                            className="w-4 h-4"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M6 18L18 6M6 6l12 12"
                            ></path>
                        </svg>
                    </button>
                    <hr />
                    <div className="w-full flex flex-col">
                        <div className="font-medium py-2">Filter by price</div>
                        <div className="flex justify-between items-center">
                            <div className="flex flex-col space-y-2 w-2/3 lg:w-2/4">
                                <label htmlFor="priceRange" className="text-sm">
                                    Price from 0 to{" "}
                                    <span className="font-semibold text-yellow-700">{range}₹</span>{" "}
                                </label>
                                <input
                                    id="priceRange"
                                    type="range"
                                    min="0"
                                    max="10000"
                                    step="10"
                                    value={range}
                                    onChange={handleFilterChange}
                                    className="slider"
                                />
                                <button
                                    onClick={clearFilter}
                                    className="mt-2 px-4 py-2 bg-gradient-to-r from-red-500 to-red-600 text-red-400 rounded-lg shadow-lg transition-transform transform hover:scale-105 hover:shadow-xl focus:outline-none focus:ring-2 focus:ring-red-300"
                                >
                                    Clear Filter
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {searchDropdown && (
                <div className="my-4 relative flex items-center">
                    <input
                        type="text"
                        value={search}
                        onChange={handleSearch}
                        className="px-4 text-xl py-4 focus:outline-none"
                        placeholder="Search products..."
                    />
                    <button
                        onClick={closeSearchDropdown}
                        className="absolute top-2 right-2 text-gray-700 hover:text-gray-900"
                    >
                        <svg
                            className="w-4 h-4"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M6 18L18 6M6 6l12 12"
                            ></path>
                        </svg>
                    </button>
                </div>
            )}
        </div>
    );
};

export default CategorySearch;