import React, { Fragment, createContext, useReducer } from "react";
import AdminLayout from "../layout";
import ColorMenu from "./ColorMenu";
import AllColors from "./AllColors";
import { colorState, colorReducer } from "./ColorContext";

export const ColorContext = createContext();

const ColorComponent = () => {
  return (
    <div className="grid grid-cols-1 space-y-4 p-4">
      <ColorMenu />
      <AllColors />
    </div>
  );
};

const Colors = () => {
  const [data, dispatch] = useReducer(colorReducer, colorState);
  return (
    <Fragment>
      <ColorContext.Provider value={{ data, dispatch }}>
        <AdminLayout children={<ColorComponent />} />
      </ColorContext.Provider>
    </Fragment>
  );
};

export default Colors;