import React, { createContext, useReducer } from "react";

// Initial state
export const contactUsState = {
  queries: [],
  loading: false,
};

// Reducer
export const contactUsReducer = (state, action) => {
  switch (action.type) {
    case "fetchQueries":
      return {
        ...state,
        queries: action.payload,
      };
    case "loading":
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};

export const ContactUsContext = createContext();

// Provider component
export const ContactUsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(contactUsReducer, contactUsState);

  return (
    <ContactUsContext.Provider value={{ state, dispatch }}>
      {children}
    </ContactUsContext.Provider>
  );
};