import React from "react";
import {
  Home,
  WishList,
  ProtectedRoute,
  AdminProtectedRoute,
  CartProtectedRoute,
  PageNotFound,
  ProductDetails,
  ProductByCategory,
  CheckoutPage,
  ContactUs,
} from "./shop";
import { DashboardAdmin, Categories, Products, Orders, Sizes, Colors, ContactQueries } from "./admin";
import { UserProfile, UserOrders, SettingUser } from "./shop/dashboardUser";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AllProductsPage from "./shop/home/AllProductsPage";
import Terms from "./shop/partials/Terms";
import Privacy from "./shop/partials/Privacy";
import About from "./shop/partials/About";
import Services from "./shop/partials/Services";
import Reset from "./shop/auth/Reset";
import ResetPassword from "./shop/auth/ResetPassword";
import BlogsHome from "./shop/partials/blogs/BlogsHome";

/* Routing All page will be here */
const Routes = (props) => {
  return (
    <Router>
      <Switch>
        {/* Shop & Public Routes */}
        <Route exact path="/" component={Home} />
        <Route exact path="/all-products" component={AllProductsPage} />
        <Route exact path="/contact-us" component={ContactUs} />
        <Route exact path="/about-us" component={About} />
        <Route exact path="/services" component={Services} />
        <Route exact path="/blogs" component={BlogsHome} />
        <Route exact path="/terms-and-conditions" component={Terms} />
        <Route exact path="/privacy-policy" component={Privacy} />
        <Route exact path="/wish-list" component={WishList} />
        <Route exact path="/products/:id" component={ProductDetails} />
        <Route exact path="/reset" component={Reset} />
        <Route exact path="/reset-password/:token" component={ResetPassword} />
        <Route
          exact
          path="/products/category/:catId"
          component={ProductByCategory}
        />
        <CartProtectedRoute
          exact={true}
          path="/checkout"
          component={CheckoutPage}
        />
        {/* Shop & Public Routes End */}

        {/* Admin Routes */}
        <AdminProtectedRoute
          exact={true}
          path="/admin/dashboard"
          component={DashboardAdmin}
        />
        <AdminProtectedRoute
          exact={true}
          path="/admin/dashboard/categories"
          component={Categories}
        />
        <AdminProtectedRoute
          exact={true}
          path="/admin/dashboard/products"
          component={Products}
        />
        <AdminProtectedRoute
          exact={true}
          path="/admin/dashboard/orders"
          component={Orders}
        />
        <AdminProtectedRoute
          exact={true}
          path="/admin/dashboard/sizes"
          component={Sizes}
        />
        <AdminProtectedRoute
          exact={true}
          path="/admin/dashboard/colors"
          component={Colors}
        />
        <AdminProtectedRoute
          exact={true}
          path="/admin/dashboard/contact-queries"
          component={ContactQueries}
        />
        {/* Admin Routes End */}

        {/* User Dashboard */}
        <ProtectedRoute
          exact={true}
          path="/user/profile"
          component={UserProfile}
        />
        <ProtectedRoute
          exact={true}
          path="/user/orders"
          component={UserOrders}
        />
        <ProtectedRoute
          exact={true}
          path="/user/setting"
          component={SettingUser}
        />
        {/* User Dashboard End */}

        {/* 404 Page */}
        <Route component={PageNotFound} />
      </Switch>
    </Router>
  );
};

export default Routes;
