import React, { Fragment, useContext, useEffect, useState } from "react";
import { ColorContext } from "./index";
import { editColor, getAllColors } from "./FetchApi";

const EditColorModal = () => {
    const { data, dispatch } = useContext(ColorContext);
    const [name, setName] = useState("");
    const [status, setStatus] = useState("");
    const [cId, setCid] = useState("");

    useEffect(() => {
        setName(data.editColorModal.name);
        setStatus(data.editColorModal.status);
        setCid(data.editColorModal.cId);
    }, [data.editColorModal]);

    const fetchData = async () => {
        let responseData = await getAllColors();
        if (responseData.Colors) {
            dispatch({
                type: "fetchColorAndChangeState",
                payload: responseData.Colors,
            });
        }
    };

    const submitForm = async () => {
        dispatch({ type: "loading", payload: true });
        try {
            let edit = await editColor(cId, { cName: name, sStatus: status });
            if (edit && edit.success) {
                dispatch({ type: "editColorModalClose" });
                await fetchData();
                window.location.reload();
            } else {
                console.error("Edit color failed:", edit);
            }
        } catch (error) {
            console.error("Error editing color:", error.response ? error.response.data : error.message);
        } finally {
            // Ensure loading state is reset
            dispatch({ type: "loading", payload: false });
        }
    };

    return (
        <Fragment>
            {/* Black Overlay */}
            <div
                onClick={() => dispatch({ type: "editColorModalClose" })}
                className={`${data.editColorModal.modal ? "" : "hidden"
                    } fixed top-0 left-0 z-30 w-full h-full bg-black opacity-50`}
            />
            {/* Modal Start */}
            <div
                className={`${data.editColorModal.modal ? "" : "hidden"
                    } fixed inset-0 m-4 flex items-center z-30 justify-center`}
            >
                <div className="relative bg-white w-11/12 md:w-3/6 shadow-lg flex flex-col items-center space-y-4 overflow-y-auto px-4 py-4 md:px-8">
                    <div className="flex items-center justify-between w-full pt-4">
                        <span className="text-left font-semibold text-2xl tracking-wider">Edit Color</span>
                        <span
                            style={{ background: "#ffd700", color:'black' }}
                            onClick={() => dispatch({ type: "editColorModalClose" })}
                            className="cursor-pointer text-gray-100 py-2 px-2 rounded-full"
                        >
                            {/* Close icon */}
                        </span>
                    </div>
                    <div className="flex flex-col space-y-1 w-full">
                        <label htmlFor="color">Color Name</label>
                        <input
                            style={{borderRadius:'12px',color:'black'}}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            className="px-4 py-2 border focus:outline-none"
                            name="color"
                            id="color"
                        />
                    </div>
                    <div className="flex flex-col space-y-1 w-full">
                        <label htmlFor="status">Color Status</label>
                        <select
                            style={{borderRadius:'12px',color:'black'}}
                            value={status}
                            name="status"
                            onChange={(e) => setStatus(e.target.value)}
                            className="px-4 py-2 border focus:outline-none"
                            id="status"
                        >
                            <option value="Active">Active</option>
                            <option value="Disabled">Disabled</option>
                        </select>
                    </div>
                    <div className="flex flex-col space-y-1 w-full pb-4 md:pb-6">
                        <button
                            style={{ background: "#303031" }}
                            onClick={submitForm}
                            className="rounded-full bg-gray-800 text-gray-100 text-lg font-medium py-2"
                        >
                            Update Color
                        </button>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default EditColorModal;